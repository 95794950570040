import React, { useEffect, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import Carlos from '../../Assets/SVG/Group 27013.png';
import logo from '../../Assets/SVG/Group 26784 1.svg';
import { InlineWidget } from 'react-calendly';
import { BsCameraVideo } from 'react-icons/bs';
import { AiOutlineClockCircle } from 'react-icons/ai';
import Loading from '../../Loading';
import LoginFullPagePaul from '../LoginMenu/LoginFullPagePaul';
const VirtualLobbyFunding = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [LoginMenuOpener, setLoginMenuOpener] = useState(false);

	useEffect(() => {
		// Simulate loading for 1 second
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 1000);

		// Clean up the timer on component unmount
		return () => clearTimeout(timer);
	}, []);
	return (
		<div
			id='home'
			className='h-full  w-full '>
			<Loading isLoading={isLoading} />
			<LoginFullPagePaul
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
			/>

			<div className='w-full h-full pt-24 pb-4 -mt-20  flex items-center justify-center'>
				<div className='w-[90%] mt-10 mb-10 flex 2xl:flex-row flex-col items-center gap-10  justify-center '>
					<img
						src={Carlos}
						className=' hover:scale-105 max-h-[550px] 2xl:max-h-[500px] transition-all ease-in-out duration-700'
						alt='Image of Carlos at Salesmrkt'
					/>
					<div className='border max-w-[721px]   flex rounded-2xl  '>
						<div className='md:flex hidden border-r min-w-[350px]  mr-2 w-fit flex-col items-center justify-start'>
							<div className='flex w-fit items-center justify-center  mt-6 gap-3'>
								<img
									src={logo}
									alt='Logo'
								/>
							</div>
							<div className='flex flex-col w-fit items-start p-6'>
								{/* <h5 className='text-start mb-3'>
									Revolutionize Your #SalesExperience
								</h5> */}
								<h3 className='text-xl max-w-[350px] text-black  font-bold'>
									Your All-in-One Corporate Financing Solution
								</h3>
								<div className='flex gap-4 items-center mt-10 justify-center'>
									<AiOutlineClockCircle className='text-2xl' />
									<h5>30 min</h5>
								</div>
								<div className='flex gap-4 mt-3  items-center justify-center'>
									<BsCameraVideo className='w-[20px]  h-[24px] ' />
									<div>
										<h5>Web conferencing details</h5>
										<h5>provided upon confirmation.</h5>
									</div>
								</div>
								<div>
									<h4 className='text-black font-normal  text-start mt-16'>
										Schedule an intro call to learn more
										about our complete suite of financial
										products and services for small and
										growing businesses.
									</h4>
								</div>
							</div>
						</div>
						<a
							href='https://calendly.com/salesbox-meet/salesmrkt?month=2023-08'
							target='_blank'
							className='pl-1 pr-1 max-h-[450px]'
							rel='noopener noreferrer'>
							<div className='max-h-[450px] pointer-events-none cursor-pointer  overflow-hidden w-full'>
								<InlineWidget
									url='https://calendly.com/salesbox-meet/salesmrkt'
									pageSettings={{
										backgroundColor: 'fff',
										hideEventTypeDetails: true,
										hideLandingPageDetails: true,
										hideGdprBanner: true,
										primaryColor: '70C242',
										textColor: '#4d5055',
									}}
								/>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VirtualLobbyFunding;
