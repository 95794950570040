import React, { useEffect, useRef, useState } from "react";
import Dom from "../../Assets/img/InvestorPageAssets/Dom.png";
import SecondSlide from "../../Assets/img/InvestorPageAssets/RedThreadBrand.png";
import ThirdSlide from "../../Assets/img/InvestorPageAssets/GoPoint.png";
import FourthSlide from "../../Assets/img/InvestorPageAssets/Serko.png";
const ImageSlider: React.FC = () => {
  const images = [SecondSlide, Dom, ThirdSlide, FourthSlide]; // Replace with your images
  const URLS = [
    "https://redthreadbrands.com/",
    "https://beneaththesurfacestudios.com/",
    "https://www.gopoint.com/",
    "https://www.serko.com/",
  ]; // Replace with your images
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 5000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [activeIndex]);

  return (
    <div className="w-full  h-[420px] 3xl:h-[500px] mt-16  relative  flex items-center justify-center">
      <button
        onClick={prevSlide}
        className="absolute left-[-40px] top-[60%] z-30 transform -translate-y-1/2  text-white rounded-full"
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 11 12"
          fill=""
          className="rotate-180 hover:scale-110 hover:fill-lime-500 fill-black transition-all"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
            fill=""
          />
        </svg>
      </button>

      <div className="w-full h-full  overflow-hidden flex items-center justify-center relative">
        <div className="w-[95%]  h-full flex items-center justify-center">
          {images.map((image, index) => (
            <a
              href={URLS[index]}
              target="_blank"
              className="flex items-center justify-center"
              rel="noopener noreferrer"
            >
              <img
                key={index}
                src={image}
                alt="demo"
                className={`transform  w-full  transition-transform duration-500 ease-in ${
                  index === activeIndex ? "translate-x-0" : "translate-x-full"
                } absolute z-0 object-contain   `}
              />
            </a>
          ))}
        </div>
      </div>

      <button
        onClick={nextSlide}
        className="absolute right-[-40px] top-[60%] transform -translate-y-1/2  text-white rounded-full"
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 11 12"
          fill=""
          className=" hover:scale-110 transition-all hover:fill-lime-500 fill-black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
            fill=""
          />
        </svg>
      </button>
    </div>
  );
};

export default ImageSlider;
