import React, {
	useState,
	SyntheticEvent,
	ChangeEvent,
	useEffect,
	useRef,
} from 'react';
import emailjs from '@emailjs/browser';
import { AiFillCloseCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { BarLoader } from 'react-spinners';
import { motion } from 'framer-motion';
import Logo from '../../Assets/img/InvestorPageAssets/Green Check.svg';
import { IoCloseOutline } from 'react-icons/io5';
type InvestorDataProps = {
	isOpen: boolean;
	showInvestorPopUpAgain: boolean;
	hasAlreadySubmitted: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<any>>;
	setHasAlreadySubmitted: React.Dispatch<React.SetStateAction<any>>;
	setShowInvestorPopUpAgain: React.Dispatch<React.SetStateAction<any>>;
	setShowInvestorPopUpOnTimer: React.Dispatch<React.SetStateAction<any>>;
};

const InvestorData: React.FC<InvestorDataProps> = ({
	isOpen,
	showInvestorPopUpAgain,
	setShowInvestorPopUpAgain,
	setIsOpen,
	hasAlreadySubmitted,
	setHasAlreadySubmitted,
	setShowInvestorPopUpOnTimer,
}) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [failure, setFailure] = useState(false);
	const [cooldown, setCooldown] = useState(false);
	const [cooldownTime, setCooldownTime] = useState(60);
	const [city, setCity] = useState('Unkown');
	const isSubmittingRef = useRef(false);
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		investorType: '',
		ip: '',
		country: '',
		city: '',
		org: '',
		postal: '',
		timezone: '',
	});
	async function fetchLocationData() {
		try {
			const ipinfoApiToken = process.env.REACT_APP_IPINFO_API_TOKEN;
			if (!ipinfoApiToken) {
				console.error('IPinfo API token is missing.');
				return null;
			}

			const response = await fetch(
				`https://ipinfo.io?token=${ipinfoApiToken}`
			);
			const data = await response.json();

			return data;
		} catch (error) {
			console.error('Error fetching location data:', error);
			return null;
		}
	}

	useEffect(() => {
		const debounceTimeout = setTimeout(() => {
			fetchLocationData().then((locationData) => {
				if (locationData) {
					// Update formData state with locationData values
					setFormData((prevFormData) => ({
						...prevFormData,
						ip: locationData.ip || 'Null',
						country: locationData.country || 'Null',
						city: locationData.city || 'Null',
						org: locationData.org || 'Null',
						postal: locationData.postal || 'Null',
						timezone: locationData.timezone || 'Null',
					}));
				} else {
					console.log('Unable to fetch location data.');
				}
			});
		}, 1000); // Adjust the debounce delay as needed

		return () => clearTimeout(debounceTimeout);
	}, []);

	const handleCheckboxChange = (option: string) => {
		setFormData((prevData) => ({ ...prevData, investorType: option }));
	};

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = e.target;

		if (type === 'checkbox') {
			// Handle checkbox separately
			handleCheckboxChange(value);
		} else {
			setFormData((prevData) => ({ ...prevData, [name]: value }));
		}
	};

	const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (isSubmittingRef.current || cooldown) {
			return;
		}
		isSubmittingRef.current = true;
		setLoading(true);

		// First service
		emailjs
			.send(
				'service_9sbma8h',
				'template_nsf1daf',
				formData,
				'YX6Ix4FNwXw3hRdaD'
			)
			.then(
				(result) => {
					setCooldown(true);
					setTimeout(() => {
						setLoading(false);
						setSuccess(true);
					}, 750);
					setTimeout(() => {
						setHasAlreadySubmitted(true);
						setIsOpen(false);
						setCooldown(false);
						isSubmittingRef.current = false;
					}, 1450);
					// Handle success if needed
				},
				(error) => {
					// If the first service fails, try the second one
					emailjs
						.send(
							'service_eqeh5lk',
							'template_nsf1daf',
							formData,
							'YX6Ix4FNwXw3hRdaD'
						)
						.then(
							(result) => {
								setTimeout(() => {
									setLoading(false);
									setSuccess(true);
								}, 750);

								// Handle success if needed
							},
							(error) => {
								setFailure(true);
								setIsOpen(false);
								// Handle error if needed
							}
						)
						.finally(() => {
							// Close the modal or perform other actions after sending
							setTimeout(() => {
								setLoading(false);
								setIsOpen(false);
								setSuccess(false);
								setFailure(false);
								isSubmittingRef.current = false;
							}, 1500);
						});
				}
			);
	};

	return (
		<div className='w-full z-[8888] h-[100vh] flex items-center justify-center bg-black/90 fixed'>
			<form
				className='flex items-center justify-center'
				onSubmit={handleSubmit}>
				<div className='w-[95%] h-full -mt-10 lg:w-[30vw] lg:min-w-[500px] relative bg-white p-6 md:p-12 rounded-md shadow-lg flex flex-col items-center justify-center gap-6'>
					<IoCloseOutline
						onClick={() => {
							setIsOpen(false);
							setShowInvestorPopUpAgain(false);
							setShowInvestorPopUpOnTimer(false);
						}}
						className='text-3xl absolute cursor-pointer right-[5px] top-[5px] hover:text-lime-500 transition-all'
					/>
					<div className='flex items-center justify-center gap-3 text-black '>
						<img
							src={Logo}
							alt=''
						/>
						<h3 className='text-lime-500'>Investor Relations</h3>
					</div>
					<div className='flex gap-6 w-full'>
						<input
							required
							type='text'
							name='firstName'
							placeholder='First Name'
							id='nameId'
							onChange={handleInputChange}
							className='outline outline-black/20 rounded-md outline-1 w-full p-2'
						/>
						<input
							required
							type='text'
							name='lastName'
							placeholder='Last Name'
							id='nameId2'
							onChange={handleInputChange}
							className='outline outline-black/20 rounded-md outline-1 w-full p-2'
						/>
					</div>
					<input
						required
						type='email'
						name='email'
						placeholder='Email'
						id='nameId'
						onChange={handleInputChange}
						className='outline outline-black/20 rounded-md outline-1 w-full p-2'
					/>
					<input
						required
						type='number'
						name='phone'
						placeholder='Phone'
						id='nameId'
						onChange={handleInputChange}
						className='outline outline-black/20 rounded-md outline-1 w-full p-2'
					/>
					<h3 className='text-black'>
						What type of investor are you?
					</h3>
					<div className='flex items-center justify-center gap-6 w-full'>
						<label
							htmlFor='#Remember'
							className={`flex items-center cursor-pointer gap-2 ${
								formData.investorType === 'Regular'
									? 'text-lime-500'
									: ''
							}`}>
							<input
								className='w-5 h-5 cursor-pointer'
								type='checkbox'
								name='Remember'
								id='#Remember'
								value='Regular'
								checked={formData.investorType === 'Regular'}
								onChange={handleInputChange}
							/>
							<h4 className='text-sm font-normal text-black hover:text-lime-500 transition-all duration-75 ease-in'>
								Regular
							</h4>
						</label>
						<label
							htmlFor='#Remember2'
							className={`flex items-center cursor-pointer gap-2 ${
								formData.investorType === 'Accredited'
									? 'text-lime-500'
									: ''
							}`}>
							<input
								className='w-5 h-5 cursor-pointer'
								type='checkbox'
								name='Remember'
								id='#Remember2'
								value='Accredited'
								checked={formData.investorType === 'Accredited'}
								onChange={handleInputChange}
							/>
							<h4 className='text-sm font-normal text-black hover:text-lime-500 transition-all duration-75 ease-in'>
								Accredited
							</h4>
						</label>
					</div>
					{!success && (
						<button
							className='p-2 cursor-pointer flex items-center justify-center  rounded w-full text-center  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300'
							type='submit'>
							{!loading && !success && (
								<p className='text-black'>Submit {'>'}</p>
							)}
							{loading && (
								<div className='flex items-center relative justify-center gap-3'>
									<p className='text-black'>Sending</p>
									<BarLoader
										color='#99CC00'
										height={4}
										width={80}
										className=''
									/>
								</div>
							)}
						</button>
					)}
					{success && (
						<button
							className='p-2 cursor-pointer flex items-center justify-center gap-3  rounded w-full text-center  font-semibold border-lime-500 border   overflow-hidden group  relative bg-gradient-to-r from-lime-500 to-lime-400 text-black ring-2 ring-offset-2 ring-lime-400 transition-all ease-out duration-300'
							type='submit'>
							Thank you !{' '}
							<svg
								className='w-8 h-8 stroke-white'
								xmlns='http://www.w3.org/2000/svg'
								fill='none'
								viewBox='0 0 24 24'
								strokeWidth={2.5}
								stroke='currentColor'>
								<motion.path
									strokeLinecap='round'
									strokeLinejoin='round'
									initial={{ pathLength: 0 }}
									animate={{ pathLength: 1 }}
									transition={{ duration: 0.6 }}
									d='M4.5 12.75l6 6 9-13.5'
								/>
							</svg>
						</button>
					)}
				</div>
			</form>
		</div>
	);
};

export default InvestorData;
