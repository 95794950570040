const UnderBanner = () => {
  return (
    <div
      id="Bundles"
      className="h-fit w-full  bg-gradient-to-l px-4 py-4 md:px-8 md:py-8   from-[#70C242] to-[#B0D238] group   overflow-hidden  flex  items-center justify-center relative mt-6"
    >
      <div id="Experience" className="flex  items-center gap-6  justify-center">
        <h1
          id="Membership"
          className="font-semibold relative w-fit   text-zinc-950 text-lg md:text-2xl  lg:text-5xl"
        >
          Revolutionize Your
          {""} #SalesExperience
          <a
            href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
            rel="noopener noreferrer"
            target="_blank"
            className="text-sm lg:text-xl lg:opacity-0 lg:group-hover:opacity-100 cursor-pointer transition-all duration-300  font-bold lg:absolute bottom-[-30%] md:bottom-[-50%] left-0    text-black   ease-in-out flex items-center gap-2"
          >
            <span className=" flex tracking-normal mt-2    text-white    items-center justify-center gap-2">
              Start Your Journey
              <svg
                width="11"
                height="12"
                viewBox="0 0 11 12"
                fill="white"
                className=""
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
                  fill=""
                />
              </svg>
            </span>
          </a>
        </h1>
      </div>
    </div>
  );
};

export default UnderBanner;
