import React from "react";
import CardMock from "../../Assets/img/Rectangle 6363.png";
import CardMock2 from "../../Assets/img/Rectangle 6362.png";
const CardTester = () => {
  return (
    <div className="w-full h-full min-h-[100vh] flex  items-center gap-5 justify-center">
      <div className="w-full flex items-center justify-between p-10  bg-red-500 h-[80vh]">
        <img className="w-4/5 object-cover" src={CardMock} alt="card" />
        <img className="w-1/5 max-h-[80vh]" src={CardMock2} alt="card" />
      </div>

      {/* <div className='w-[424px] mt-[200px] cursor-pointer group h-[544px] relative bg-white hover:border-[#874B69] border-[8px] border-white   shadow-lg rounded-lg   transition-all duration-300 ease-in flex items-center justify-center'>
				<div className='flex items-center justify-center gap-3 absolute top-[20px]'>
					<h2 className='text-black   group-hover:text-black transition-all duration-300 ease-in text-center'>
						Video Production
					</h2>
					<div>
						<svg
							className='fill-[#874B69] group-hover:fill-black transition-all duration-300 ease-in'
							width='45'
							height='45'
							viewBox='0 0 64 64'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M32 64C14.3377 64 -0.0129782 49.6393 8.80776e-06 31.9806C0.0129958 14.3218 14.4026 -0.038874 32.039 7.90576e-05C49.6883 0.0390321 63.987 14.3478 64 31.9935C64.013 49.6653 49.6623 64.013 32 64ZM31.987 58.6634C46.6623 58.7024 58.6104 46.7697 58.6493 32.0455C58.6883 17.3732 46.7532 5.40157 32.052 5.36262C17.3766 5.32367 5.42859 17.2173 5.35067 31.9416C5.27274 46.6269 17.2468 58.6245 31.987 58.6634Z'
								fill=''
							/>
							<path
								d='M26.4844 33.1209C26.4844 30.0867 26.4965 27.0526 26.4844 24.0185C26.4844 23.2664 26.6181 22.6162 27.3111 22.2465C28.0041 21.8641 28.5999 22.1573 29.1713 22.5907C33.1348 25.6121 37.0983 28.6334 41.0496 31.6548C42.2411 32.5599 42.2168 33.6818 41.0375 34.5869C37.0618 37.621 33.074 40.6424 29.0983 43.6765C28.5512 44.0972 27.9798 44.3012 27.3476 43.9825C26.6789 43.651 26.4965 43.0391 26.4965 42.2997C26.4965 39.2273 26.4844 36.1805 26.4844 33.1209Z'
								fill=''
							/>
						</svg>
					</div>
				</div>
				<img
					src={CardMock}
					alt=''
				/>
				<p className='absolute bottom-0 pl-10 pr-10 pb-5 text-center group-hover:text-black transition-all duration-300 ease-in'>
					Leverage the most engaging content available to tell your
					story, promote your business & drive sales.
				</p>
			</div>
			<div className='w-[424px] mt-[200px] cursor-pointer group h-[544px] relative bg-white hover:border-[#7B60DD] border-[8px] border-white   shadow-lg rounded-lg   transition-all duration-300 ease-in flex items-center justify-center'>
				<div className='flex items-center justify-center gap-3 absolute top-[20px]'>
					<h2 className='text-black   group-hover:text-black transition-all duration-300 ease-in text-center'>
						Video Production
					</h2>
					<div>
						<svg
							className='fill-[#874B69] group-hover:fill-black transition-all duration-300 ease-in'
							width='45'
							height='45'
							viewBox='0 0 64 64'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M32 64C14.3377 64 -0.0129782 49.6393 8.80776e-06 31.9806C0.0129958 14.3218 14.4026 -0.038874 32.039 7.90576e-05C49.6883 0.0390321 63.987 14.3478 64 31.9935C64.013 49.6653 49.6623 64.013 32 64ZM31.987 58.6634C46.6623 58.7024 58.6104 46.7697 58.6493 32.0455C58.6883 17.3732 46.7532 5.40157 32.052 5.36262C17.3766 5.32367 5.42859 17.2173 5.35067 31.9416C5.27274 46.6269 17.2468 58.6245 31.987 58.6634Z'
								fill=''
							/>
							<path
								d='M26.4844 33.1209C26.4844 30.0867 26.4965 27.0526 26.4844 24.0185C26.4844 23.2664 26.6181 22.6162 27.3111 22.2465C28.0041 21.8641 28.5999 22.1573 29.1713 22.5907C33.1348 25.6121 37.0983 28.6334 41.0496 31.6548C42.2411 32.5599 42.2168 33.6818 41.0375 34.5869C37.0618 37.621 33.074 40.6424 29.0983 43.6765C28.5512 44.0972 27.9798 44.3012 27.3476 43.9825C26.6789 43.651 26.4965 43.0391 26.4965 42.2997C26.4965 39.2273 26.4844 36.1805 26.4844 33.1209Z'
								fill=''
							/>
						</svg>
					</div>
				</div>
				<img
					src={CardMock}
					alt=''
				/>
				<p className='absolute bottom-0 pl-10 pr-10 pb-5 text-center group-hover:text-black transition-all duration-300 ease-in'>
					Leverage the most engaging content available to tell your
					story, promote your business & drive sales.
				</p>
			</div>
			<div className='w-[424px] mt-[200px] cursor-pointer group h-[544px] relative bg-white hover:border-[#97CC3C] border-[8px] border-white   shadow-lg rounded-lg   transition-all duration-300 ease-in flex items-center justify-center'>
				<div className='flex items-center justify-center gap-3 absolute top-[20px]'>
					<h2 className='text-black   group-hover:text-black transition-all duration-300 ease-in text-center'>
						Video Production
					</h2>
					<div>
						<svg
							className='fill-[#874B69] group-hover:fill-black transition-all duration-300 ease-in'
							width='45'
							height='45'
							viewBox='0 0 64 64'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M32 64C14.3377 64 -0.0129782 49.6393 8.80776e-06 31.9806C0.0129958 14.3218 14.4026 -0.038874 32.039 7.90576e-05C49.6883 0.0390321 63.987 14.3478 64 31.9935C64.013 49.6653 49.6623 64.013 32 64ZM31.987 58.6634C46.6623 58.7024 58.6104 46.7697 58.6493 32.0455C58.6883 17.3732 46.7532 5.40157 32.052 5.36262C17.3766 5.32367 5.42859 17.2173 5.35067 31.9416C5.27274 46.6269 17.2468 58.6245 31.987 58.6634Z'
								fill=''
							/>
							<path
								d='M26.4844 33.1209C26.4844 30.0867 26.4965 27.0526 26.4844 24.0185C26.4844 23.2664 26.6181 22.6162 27.3111 22.2465C28.0041 21.8641 28.5999 22.1573 29.1713 22.5907C33.1348 25.6121 37.0983 28.6334 41.0496 31.6548C42.2411 32.5599 42.2168 33.6818 41.0375 34.5869C37.0618 37.621 33.074 40.6424 29.0983 43.6765C28.5512 44.0972 27.9798 44.3012 27.3476 43.9825C26.6789 43.651 26.4965 43.0391 26.4965 42.2997C26.4965 39.2273 26.4844 36.1805 26.4844 33.1209Z'
								fill=''
							/>
						</svg>
					</div>
				</div>
				<img
					src={CardMock}
					alt=''
				/>
				<p className='absolute bottom-0 pl-10 pr-10 pb-5 text-center group-hover:text-black transition-all duration-300 ease-in'>
					Leverage the most engaging content available to tell your
					story, promote your business & drive sales.
				</p>
			</div> */}
    </div>
  );
};

export default CardTester;
