import React, { useState, useEffect, useRef } from 'react';
import { MdOutlineReplay } from 'react-icons/md';
import VideoTN from '../../Assets/img/Video Production 3.0.00_00_00_00.Still001.jpg';
interface DivCompProps {
	text: string;
	textHidden: string;
	textHiddenSecond: string;
	image: string;
	icon: string;
	myindex: number;
	scrolled: boolean;
	index: number;
	setScrolled: (value: boolean) => void;
}
const VideProductionPlusIndex: React.FC<DivCompProps> = ({
	text,
	textHidden,
	image,
	icon,
	index,
	textHiddenSecond,
	myindex,
	scrolled,
	setScrolled,
}) => {
	const [clicked, setClicked] = useState(false);
	const [inView, setInView] = useState(false);
	const [videoHasFinished, setVideoHasFinished] = useState(false);
	const [videoIsPlaying, setVideoIsPlaying] = useState(false);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const handleReplay = () => {
		if (videoRef.current) {
			videoRef.current.currentTime = 0; // Reset the video to 0
			videoRef.current.play(); // Start playing the video
			setVideoHasFinished(false);
			setClicked(false); // Reset the videoHasFinished state
		}
	};
	const handleClick = () => {
		setClicked(true);
		setScrolled(false);
		setTimeout(() => {
			setClicked(false);
		}, 10200);
	};
	// useEffect(() => {
	// 	const options = {
	// 		root: null,
	// 		rootMargin: '0px',
	// 		threshold: 0.3,
	// 	};

	// 	const callback: IntersectionObserverCallback = (entries) => {
	// 		const entry = entries[0];
	// 		setInView(entry.isIntersecting);
	// 	};

	// 	const observer = new IntersectionObserver(callback, options);

	// 	if (videoRef.current) {
	// 		observer.observe(videoRef.current);
	// 	}

	// 	return () => {
	// 		if (videoRef.current) {
	// 			observer.unobserve(videoRef.current);
	// 		}
	// 	};
	// }, []);
	useEffect(() => {
		if (
			index === 5 ||
			index === 15 ||
			index === 25 ||
			index === 35 ||
			index === 45
		) {
			if (videoRef.current) {
				if (!videoIsPlaying) {
					setVideoHasFinished(false);
					videoRef.current.play();
				}
			}
		} else {
			if (videoRef.current) {
				videoRef.current.currentTime = 0;
				if (videoIsPlaying) {
					videoRef.current.pause();
				}
				setClicked(false);
			}
		}
	}, [index]);

	useEffect(() => {
		// Listen for the 'ended' event to detect when the video has finished
		if (videoRef.current) {
			videoRef.current.addEventListener('ended', () => {
				setVideoHasFinished(true);
			});
		}

		return () => {
			if (videoRef.current) {
				// Clean up event listener when component unmounts
				videoRef.current.removeEventListener('ended', () => {
					setVideoHasFinished(true);
				});
			}
		};
	}, [videoHasFinished]);
	// useEffect(() => {
	// 	// Delay autoplay of the video by 2300 milliseconds
	// 	const delayTimeout = setTimeout(() => {
	// 		if (videoRef.current) {
	// 			videoRef.current.play(); // Start playing the video after the delay
	// 		}
	// 	}, 2000); // delay of 2.8 seconds --> change accordingly

	// 	return () => {
	// 		clearTimeout(delayTimeout);
	// 	};
	// }, []);

	return (
		<span className='w-full h-full cursor-pointer   relative group  flex items-center  justify-center'>
			{videoHasFinished && !clicked && (
				<MdOutlineReplay
					onClick={handleReplay}
					className='text-lime-500 z-[9999999] animate-pulse text-6xl absolute top-[20px] right-[15px]'
				/>
			)}

			<div
				className={`${
					clicked
						? ' h-full  flex flex-col cursor-pointer  lg:p-1 overflow-visible items-center  justify-center  opacity-100 absolute transition-all duration-500 ease-in -z-10 rounded-md lg:rounded-[20px] '
						: ' h-full flex flex-col cursor-pointer  lg:p-1 overflow-visible items-center  justify-center  opacity-0 absolute transition-all duration-500 ease-in -z-10 rounded-md lg:rounded-[20px]'
				}`}>
				<img
					src={icon}
					className=' h-full     z-20   '
					alt='Salesmrkt Back Card'
				/>
			</div>
			<div
				onClick={handleClick}
				className={`${
					clicked
						? 'front  h-full w-full  - opacity-0 rounded-md lg:rounded-[20px]  transition-all duration-500 ease-in '
						: 'front  w-full h-full rounded-md lg:rounded-[20px] - opacity-100 transition-all duration-500 ease-in'
				}`}>
				<video
					onPlay={() => setVideoIsPlaying(true)}
					onPause={() => setVideoIsPlaying(false)}
					ref={videoRef}
					muted
					poster={VideoTN}
					playsInline
					disablePictureInPicture
					preload={'auto'}
					className='w-full h-full object-cover  rounded-md lg:rounded-[20px]    z-10 '
					src={image}></video>
				<h2 className='z-30 textshadow  text-white absolute flex items-center justify-center gap-1 group lg:left-[52px] top-[78%] md:top-[85%] text-base md:text-lg left-[10px]   lg:top-[62%]    lg:text-5xl  font-semibold'>
					{text}
				</h2>
				<h5 className='z-30 hidden textshadow text-white  absolute lg:flex items-center justify-center gap-1 group left-[52px] cursor-pointer  top-[74%]    text-base  font-medium'>
					{textHidden}
				</h5>
				<h5 className='z-30 hidden textshadow text-white  absolute lg:flex items-center justify-center gap-1 group left-[52px] cursor-pointer  top-[78%]    text-base  font-medium'>
					{textHiddenSecond}
				</h5>
				<h3
					onClick={handleClick}
					className='z-30 absolute hidden lg:flex textshadow group-hover:text-lime-400 hover:scale-105    items-center justify-center gap-1 group left-[52px]  cursor-pointer transition-all duration-500 ease-in-out  top-[86%] text-white text-xl font-medium'>
					Learn More {'>'}
				</h3>
				<div className='w-full h-full bg-gradient-to-t from-black/80 via-transparent to-transparent absolute top-0 z-10 rounded-[20px]'></div>
			</div>
		</span>
	);
};
export default VideProductionPlusIndex;
