import { motion } from "framer-motion";
import Apple from "../../Assets/img/Coustmers/Apple_logo_black.svg";
import Taiwan from "../../Assets/img/Coustmers/Group 26973.svg";
import cydPerson from "../../Assets/img/Coustmers/cydPerson.png";
import TaiwanPerson from "../../Assets/img/Coustmers/taiwaniPerson.png";
import forvis from "../../Assets/img/Coustmers/Icon_Text_Dark+Brown_Stack_adobe_express 1.svg";
import worldvisionOther from "../../Assets/img/Coustmers/Group 26974.svg";
import superFoil from "../../Assets/img/Coustmers/Rectangle.png";
import person2 from "../../Assets/img/Coustmers/9.png";
import edifces from "../../Assets/img/Coustmers/13.png";
import edifces2 from "../../Assets/img/Coustmers/Group 26976.svg";
import person4 from "../../Assets/img/Coustmers/15.png";
import amazon from "../../Assets/img/Coustmers/Amazon_icon.svg";
import robloxOther from "../../Assets/img/Coustmers/Clip path group.svg";
import person5 from "../../Assets/img/Coustmers/21.png";
import gm from "../../Assets/img/Coustmers/613b648030e8530004ba39ff.png";
import microsoft from "../../Assets/img/Coustmers/Microsoft_logo.svg";
import recharge from "../../Assets/img/Coustmers/recharge.svg";
import pace from "../../Assets/img/Coustmers/Pace_University_Logo.svg";
import oxpedia from "../../Assets/img/Coustmers/axelis.svg";
import oxpedia2 from "../../Assets/img/Coustmers/postman 1.svg";
import person6 from "../../Assets/img/Coustmers/32.png";
import groovePerson from "../../Assets/img/Coustmers/groovePerson.png";
import calixPerson from "../../Assets/img/Coustmers/calixPerson.png";
import person7 from "../../Assets/img/Coustmers/37.png";
import ey from "../../Assets/img/Coustmers/Group.svg";
import eyOther from "../../Assets/img/Coustmers/Group (1).svg";
import personSmall1 from "../../Assets/img/Coustmers/Vector (1).png";
import personSmall2 from "../../Assets/img/Coustmers/Vector (2).png";
import ups from "../../Assets/img/Coustmers/ups.svg";
import ibm from "../../Assets/img/Coustmers/IBM_logo.svg";
import drata from "../../Assets/img/Coustmers/drata.svg";
import groove from "../../Assets/img/Coustmers/groove.svg";
import calix from "../../Assets/img/Coustmers/calix.svg";
import enterprise from "../../Assets/SVG/enterprise.svg";
import midmarket from "../../Assets/SVG/midmarket.svg";
import sme from "../../Assets/SVG/sme.svg";
import LeadingBrands from "./LeadingBrands";
import VideoEmbed from "../YoutubeEmbed/VideoEmbed";
import IntroVideo from "../../Assets/Video/Hear From Our Clients 2022-2023 (1).mp4";
import { useState } from "react";
import Marquee from "../Marquee";
import useScreen from "../../hooks/useScreen";

const LeadingBrandsone = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const screen = useScreen();

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  const textAnimate = {
    offscreen: { x: -40 },
    onscreen: {
      x: 0,
      transition: {
        ease: "easeIn",
        duration: 1,
      },
    },
  };

  if ((screen?.width ?? 1024) < 1024) {
    return (
      <div className=" py-6  ">
        <h1 className="font-semibold relative w-fit lg:mb-14  mb-4  lg:mt-0  text-center mx-auto text-zinc-950 text-xl md:text-4xl lg:text-5xl">
          Connect with Leading Brands
        </h1>
        <MarqueeScrollTemplate
          title1="Small"
          title2="Business"
          speed={7}
          items={[
            <img src={Taiwan} width="100%" height="100%" alt="Copmany Logo" />,
            <img
              src={worldvisionOther}
              width="100%"
              height="100%"
              alt="lead generation"
            />,
            <img src={forvis} width="100%" height="100%" alt="Copmany Logo" />,
            <img
              src={robloxOther}
              width="100%"
              height="100%"
              alt="Copmany Logo"
            />,
          ]}
        />
        <MarqueeScrollTemplate
          title1="Mid"
          title2="Market"
          speed={8}
          items={[
            <img
              src={recharge}
              width="100%"
              height="100%"
              alt="Copmany Logo"
            />,
            <img
              src={drata}
              width="100%"
              height="100%"
              alt="lead generation"
            />,
            <img src={pace} width="100%" height="100%" alt="Copmany Logo" />,
            <img src={oxpedia} width="100%" height="100%" alt="Copmany Logo" />,
            <img src={calix} width="100%" height="100%" alt="Copmany Logo" />,
          ]}
        />
        <MarqueeScrollTemplate
          title1="Large"
          title2="Enterprise"
          speed={9}
          items={[
            <img src={Apple} width="100%" height="100%" alt="Copmany Logo" />,
            <img src={ups} width="100%" height="100%" alt="lead generation" />,
            <img src={amazon} width="100%" height="100%" alt="Copmany Logo" />,
            <img
              src={microsoft}
              width="100%"
              height="100%"
              alt="Copmany Logo"
            />,
            <img src={ibm} width="100%" height="100%" alt="Copmany Logo" />,
          ]}
        />
      </div>
    );
  }

  return (
    <motion.div
      initial={"offscreen"}
      whileInView={"onscreen"}
      id="LeadingBrands"
      viewport={{ once: true, amount: 0.5 }}
      transition={{ staggerChildren: 0.6 }}
      className="w-full  h-full flex flex-col  items-center justify-center"
    >
      <div className="relative w-full h-full">
        <h1 className="font-semibold  w-full text-center lg:mb-14 group  mb-6  lg:mt-0 flex items-center justify-center      text-zinc-950 text-xl md:text-4xl lg:text-5xl">
          Connect with Leading Brands <span className="hidden lg:flex">|</span>{" "}
          <span
            onClick={() => setShowOverlay(!showOverlay)}
            className="text-lime-500 cursor-pointer hidden lg:flex transition-all duration-300 ease-in  items-center justify-center gap-3 ml-2"
          >
            Customer Feedback
            <svg
              width="50"
              height="40"
              className="fill-lime-500 group-hover:fill-black transition-all duration-300 ease-in"
              viewBox="0 0 417 405"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M355.594 333.5C375.338 333.5 391.344 317.494 391.344 297.75C391.344 278.006 375.338 262 355.594 262C335.85 262 319.844 278.006 319.844 297.75C319.844 317.494 335.85 333.5 355.594 333.5Z"
                fill=""
              />
              <path
                d="M370.919 343.714H340.277C328.09 343.727 316.407 348.574 307.79 357.191C299.173 365.808 294.326 377.492 294.313 389.678V399.892C294.312 400.563 294.444 401.227 294.7 401.847C294.957 402.467 295.333 403.03 295.807 403.504C296.282 403.979 296.845 404.355 297.465 404.611C298.085 404.868 298.749 405 299.42 404.999H411.776C412.447 405 413.111 404.868 413.731 404.611C414.351 404.355 414.914 403.979 415.389 403.504C415.863 403.03 416.239 402.467 416.496 401.847C416.752 401.227 416.884 400.563 416.884 399.892V389.678C416.87 377.492 412.023 365.808 403.406 357.191C394.789 348.574 383.106 343.727 370.919 343.714Z"
                fill=""
              />
              <path
                d="M140.047 280.063C161.444 280.063 179.755 272.446 194.99 257.217C210.222 241.988 217.839 223.668 217.839 202.273V77.7994C217.839 56.404 210.228 38.0912 194.99 22.8549C179.755 7.62198 161.444 0 140.047 0C118.652 0 100.339 7.62198 85.1032 22.8549C69.8669 38.0886 62.25 56.404 62.25 77.7994V202.273C62.25 223.667 69.8703 241.988 85.1032 257.217C100.336 272.446 118.652 280.063 140.047 280.063Z"
                fill=""
              />
              <path
                d="M275.447 160.213C272.377 157.133 268.722 155.593 264.508 155.593C260.298 155.593 256.652 157.133 253.568 160.213C250.492 163.292 248.952 166.938 248.952 171.151V202.272C248.952 232.257 238.293 257.904 216.979 279.217C195.672 300.531 170.022 311.187 140.036 311.187C110.05 311.187 84.4021 300.531 63.0877 279.217C41.7757 257.91 31.1206 232.258 31.1206 202.272V171.151C31.1206 166.938 29.5802 163.292 26.502 160.213C23.422 157.133 19.7792 155.593 15.5624 155.593C11.3457 155.593 7.69862 157.133 4.62037 160.213C1.53956 163.292 0 166.938 0 171.151V202.272C0 238.091 11.9554 269.252 35.8593 295.748C59.7642 322.246 89.3026 337.44 124.476 341.327V373.422H62.2387C58.0245 373.422 54.3783 374.964 51.2992 378.044C48.2192 381.121 46.6788 384.769 46.6788 388.983C46.6788 393.191 48.2192 396.846 51.2992 399.923C54.3783 403 58.0245 404.545 62.2387 404.545H217.828C222.042 404.545 225.693 403.001 228.768 399.923C231.851 396.846 233.392 393.192 233.392 388.983C233.392 384.77 231.851 381.122 228.768 378.044C225.694 374.964 222.042 373.422 217.828 373.422H155.599V341.327C190.766 337.44 220.302 322.246 244.209 295.748C268.117 269.252 280.075 238.091 280.075 202.272V171.151C280.075 166.939 278.532 163.296 275.447 160.213Z"
                fill=""
              />
            </svg>
          </span>
        </h1>
        {showOverlay && (
          <div>
            {/* <YouTubeEmbed
												videoId='l8pl0dujUbU'
												onClose={handleCloseOverlay}
											/> */}
            <VideoEmbed videoPath={IntroVideo} onClose={handleCloseOverlay} />
          </div>
        )}
      </div>
      <motion.div className="xl:grid  hidden   grid-flow-row grid-rows-3 p-1 mb-6  gap-10  w-[85%] h-full">
        <motion.div
          variants={textAnimate}
          className="flex items-center justify-between bg-gradient-to-r min-h-[135px]  from-black/50 via-black/10  to-black/0  w-full rounded-l-full h-full gap-6"
        >
          <motion.div className="flex flex-col boxBG  relative  w-[220px] h-[160px] items-center justify-center  rounded-[49px] border-[8px] border-[#686868]  ">
            <motion.div className="flex items-center justify-center"></motion.div>
            <div className="  w-full flex flex-col items-center justify-center -mt-8  h-full  ">
              <div className="relative ">
                <div>
                  <h4 className="textOne mb-1">Large</h4>
                  <h4 className="textTwo ">Enterprise</h4>
                </div>
                <div className="border-b-[2px] mt-2 mb-1 min-w-[160px] border-gray-400 "></div>
                <img
                  src={enterprise}
                  alt="lead generation"
                  className="absolute w-[115px] right-[-0px]"
                />
              </div>
            </div>
          </motion.div>
          <motion.div className="flex w-4/5   gap-8">
            {" "}
            <motion.div
              whileHover={{ scale: 1.03 }}
              className="w-[110px]  shadow-xl relative shadow-black/20   h-[110px]  flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 3,
                  repeatType: "reverse",
                  repeatDelay: 7,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={person7}
                className="absolute h-[113px] mb-[2px] w-[114px] rounded-xl object-cover "
                alt="lead generation"
              />
              <motion.img
                src={gm}
                className="w-[84px]  "
                alt="lead generation"
              />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[110px] shadow-xl shadow-black/20   ease-in  h-[110px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <img src={Apple} className="w-[44px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[110px]  shadow-lg relative shadow-black/30  ease-in h-[110px] flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  repeatType: "reverse",
                  repeatDelay: 7,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={person2}
                className=" object-cover absolute  h-full  rounded-lg "
                alt="lead generation"
              />
              <img
                src={microsoft}
                className="  w-[64px]  "
                alt="lead generation"
              />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[110px] group shadow-xl shadow-black/20 relative  ease-in h-[110px]  flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 16,
                  repeatType: "reverse",
                  repeatDelay: 20,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={person5}
                className="absolute w-full h-full object-cover "
                alt="lead generation"
              />
              <img src={ups} className=" w-[54px]  " alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[110px] shadow-xl shadow-black/20  ease-in h-[110px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <img src={amazon} className="w-[54px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[110px]  relative shadow-xl shadow-black/20  ease-in h-[110px]  hidden 0xl:flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 9.5,
                  repeatType: "reverse",
                  repeatDelay: 11,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={person4}
                className=" absolute h-[112px] w-[112px] object-cover rounded-[10px] "
                alt="lead generation"
              />
              <img src={ibm} className="w-[84px] " alt="lead generation" />
            </motion.div>
          </motion.div>
        </motion.div>
        <motion.div
          variants={textAnimate}
          className="flex items-center justify-between bg-gradient-to-r from-black/50 via-black/20  to-black/0 w-full rounded-l-full h-full gap-6"
        >
          <motion.div className="flex flex-col boxBG  relative  w-[220px] h-[160px] items-center justify-center  rounded-[49px] border-[8px] border-[#686868]  ">
            <motion.div className="flex items-center justify-center"></motion.div>
            <div className=" mr-8 w-full flex flex-col items-center justify-center -mt-8  h-full ">
              <div className="relative pl-8 ">
                <div>
                  <h4 className="textOne">Mid</h4>
                  <h4 className="textTwo ">Market</h4>
                </div>
                <div className="border-b-[2px] min-w-[160px] mt-1 mb-1 border-gray-400 "></div>
                <img
                  src={midmarket}
                  alt="lead generation"
                  className="absolute w-[147px] right-[-0px]"
                />
              </div>
            </div>
          </motion.div>
          <motion.div className="  flex w-4/5   gap-8">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[100px] shadow-xl shadow-black/20  ease-in h-[100px] p-2 md:flex sm:hidden flex items-center justify-center bg-white rounded-[10px]"
            >
              <img src={recharge} className="w-[64px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[100px] shadow-xl shadow-black/20  ease-in h-[100px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <img src={pace} className="w-[64px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[100px] group shadow-xl shadow-black/20 relative  ease-in h-[100px] flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 12,
                  repeatType: "reverse",
                  repeatDelay: 24,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={calixPerson}
                className="absolute w-full rounded-[10px] "
                alt="lead generation"
              />
              <img src={calix} className="w-[84px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[100px] shadow-xl shadow-black/20  ease-in h-[100px]  flex items-center justify-center bg-white rounded-[10px]"
            >
              <img src={oxpedia} className=" w-[64px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className=" flex items-center  relative  w-[100px] shadow-xl shadow-black/20  ease-in h-[100px]  justify-center bg-white rounded-[10px]"
            >
              <img
                src={drata}
                className="  w-[110px] p-2 "
                alt="lead generation"
              />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[100px] group shadow-lg relative shadow-black/30  ease-in h-[100px]  flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 7,
                  repeatType: "reverse",
                  repeatDelay: 10.5,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={groovePerson}
                className=" absolute h-[102px] w-[102px] rounded-[10px] object-cover "
                alt="lead generation"
              />
              <img src={groove} className="w-[64px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[100px] shadow-xl shadow-black/20 hidden 0xl:flex  ease-in h-[100px]   items-center justify-center bg-white rounded-[10px]"
            >
              <img src={oxpedia2} className=" w-[64px]" alt="lead generation" />
            </motion.div>
          </motion.div>
        </motion.div>

        <motion.div
          variants={textAnimate}
          className="flex items-center justify-between bg-gradient-to-r from-black/50 via-black/40  to-black/0  w-full rounded-l-full h-full gap-4"
        >
          <motion.div className="flex flex-col boxBG  relative  w-[220px] h-[160px] items-center justify-center  rounded-[49px] border-[8px] border-[#686868]  ">
            <div className="w-full flex flex-col items-center justify-center -mt-8  h-full ">
              <div className="relative">
                <div className="">
                  <h4 className="textOne">Small</h4>
                  <h4 className="textTwo">Business</h4>
                </div>
                <div className="border-b-[2px] min-w-[160px] mt-1 mb-1 border-gray-400 "></div>
                <img
                  src={sme}
                  alt="lead generation"
                  className="absolute w-[115px] right-[-0px]"
                />
              </div>
              <motion.div className="flex items-start justify-start bg-white"></motion.div>
            </div>
          </motion.div>
          <motion.div className="flex w-4/5   gap-8">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20  ease-in h-[90px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <img src={superFoil} className="w-[84px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className=" flex items-center  relative  w-[90px] shadow-xl shadow-black/20  ease-in h-[90px]  justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 2,
                  repeatType: "reverse",
                  repeatDelay: 12,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={cydPerson}
                className=" absolute rounded-[10px] h-full w-full "
                alt="lead generation"
              />
              <img src={forvis} className="  w-[70%] " alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20  ease-in h-[90px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <img
                src={robloxOther}
                className="w-[84px]"
                alt="lead generation"
              />
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20  ease-in h-[90px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 7,
                  repeatType: "reverse",
                  repeatDelay: 10.5,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={TaiwanPerson}
                className=" absolute h-[90px] rounded-[10px] w-[90px] object-cover "
                alt="lead generation"
              />
              <img src={Taiwan} className="w-[54px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20   md:flex sm:hidden flex  h-[90px] p-2  items-center justify-center bg-white rounded-[10px]"
            >
              <img
                src={worldvisionOther}
                className="w-[84px]"
                alt="lead generation"
              />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20  ease-in h-[90px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 17,
                  repeatType: "reverse",
                  repeatDelay: 8.5,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={personSmall1}
                className=" absolute h-[90px] rounded-[10px] w-[90px] object-cover "
                alt="lead generation"
              />
              <img src={eyOther} className="w-[54px]" alt="lead generation" />
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20  ease-in h-[90px] p-2 flex items-center justify-center bg-white rounded-[10px]"
            >
              <img src={edifces} className="w-[84px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20 hidden 0xl:flex   ease-in h-[90px] p-2  items-center justify-center bg-white rounded-[10px]"
            >
              <img src={edifces2} className="w-[84px]" alt="lead generation" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="w-[90px] shadow-xl shadow-black/20 hidden 2xl:flex  ease-in h-[90px] p-2  items-center justify-center bg-white rounded-[10px]"
            >
              <motion.img
                animate={{ opacity: 0 }}
                transition={{
                  repeat: Infinity,
                  delay: 11,
                  repeatType: "reverse",
                  repeatDelay: 10.5,
                  duration: 4,
                  ease: "easeInOut",
                }}
                src={personSmall2}
                className=" absolute h-[90px] rounded-[10px] w-[90px] object-cover "
                alt="lead generation"
              />
              <img src={ey} className="w-[54px]" alt="lead generation" />
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
      <div className="xl:hidden flex w-full p-6">
        <LeadingBrands />
      </div>
    </motion.div>
  );
};

interface MarqueeScrollTemplateProps {
  title1: string;
  title2: string;
  items: any[];
  speed: number;
}

const MarqueeScrollTemplate: React.FC<MarqueeScrollTemplateProps> = ({
  items = [],
  title1,
  title2,
  speed,
}) => {
  return (
    <div className="  w-full h-full relative ">
      <div className="text-center bg-gray-100 flex items-center justify-center gap-2 p-1 ">
        <h3 className="text-black text-lg md:text-2xl font-bold">{title1}</h3>
        <h3 className=" text-lg  md:text-2xl font-semibold  text-black ">
          {title2}
        </h3>
      </div>
      <div className=" pt-3 pb-3">
        <Marquee items={items} speed={speed} />
      </div>
    </div>
  );
};

export default LeadingBrandsone;
