import zIndex from "@mui/material/styles/zIndex";
import React, { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import useScreen from "../../hooks/useScreen";
interface VideoEmbedProps {
  videoPath: string;
  onClose: () => void;
}

const VideoEmbed: React.FC<VideoEmbedProps> = ({ videoPath, onClose }) => {
  const screen = useScreen();

  const handleVideoEnd = () => {
    onClose();
  };
  return (
    <div
      style={{ zIndex: "999999999" }}
      className="fixed flex w-full  items-center  justify-center  bg-black/70  top-0 left-0 h-full"
    >
      <div className=" w-full h-full   z-[9999999] flex items-center justify-center">
        {(screen?.width ?? 768) < 768 ? (
          <>
            <div className="w-max relative">
              <button
                className="absolute  text-2xl  z-[99999] hover:text-gray-500 hover:scale-90 transition-all duration-300 ease-in right-0 -top-8"
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
              >
                <IoCloseOutline className="text-white hover:text-lime-500 transition-all duration-300" />
              </button>
              <video
                width="368"
                height="207"
                className=""
                controls
                onEnded={handleVideoEnd}
                autoPlay
              >
                <source src={videoPath} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </>
        ) : (
          <div className="relative">
            <button
              className="absolute  text-2xl  z-[99999] hover:text-gray-500 hover:scale-90 transition-all duration-300 ease-in right-[0px] top-[-30px]"
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
            >
              <IoCloseOutline className="text-white hover:text-lime-500 transition-all duration-300" />
            </button>
            <video
              className=" max-h-[580px]"
              controls
              onEnded={handleVideoEnd}
              autoPlay
            >
              <source src={videoPath} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoEmbed;
