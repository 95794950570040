import React, { useState, useEffect } from "react";

interface DivCompProps {
  id: string;
  text: string;
  textHidden: string;
  textHiddenSecondLine: string;
  image: string;
  icon: string;
  scrolled: boolean;
  setScrolled: (value: boolean) => void;
  isSwiper?: boolean;
}
const DivComponent: React.FC<DivCompProps> = ({
  text,
  textHidden,
  textHiddenSecondLine,
  image,
  icon,
  scrolled,
  id,
  setScrolled,
  isSwiper,
}) => {
  const [clicked, setClicked] = useState(false);
  const [wideScreen, setWideScreen] = useState(true);
  const handleClick = () => {
    setClicked(true);
    setScrolled(false);
    setTimeout(() => {
      setClicked(false);
    }, 10200);
  };

  return (
    <div className="w-full h-full  relative group  flex items-center  justify-center">
      <div
        className={`${
          clicked
            ? " h-full  flex flex-col   lg:p-1 overflow-visible items-center  justify-center  opacity-100 absolute transition-all duration-500 ease-in -z-10 rounded-md lg:rounded-[20px] "
            : " h-full flex flex-col   lg:p-1 overflow-visible items-center  justify-center  opacity-0 absolute transition-all duration-500 ease-in -z-10 rounded-md lg:rounded-[20px]"
        }`}
      >
        <img src={icon} className=" h-full     " alt="Salesmrkt Back Card" />
      </div>
      <div
        className={`${
          clicked
            ? "front  h-full w-full  z-10 opacity-0 rounded-md lg:rounded-[20px]  transition-all duration-500 ease-in "
            : "front  w-full h-full rounded-md lg:rounded-[20px] z-10 opacity-100 transition-all duration-500 ease-in"
        }`}
      >
        {/* <img
					src={FlipIcon}
					alt=''
					className='absolute w-12 top-[15px] right-[35px] z-50'
				/> */}
        {/* <svg
					onClick={handleClick}
					className='absolute fill-lime-500 hidden md:flex  top-[15px] right-[35px] hover:scale-110 transition-all duration-150 ease-in z-50'
					width='40'
					height='40'
					viewBox='0 0 30 28'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M29.2 3.9V24.1C29.2 26.2 27.5 27.9 25.4 27.9H12.4C10.3 27.9 8.6 26.2 8.6 24.1V20.8L11.4 21.4V24C11.4 24.6 11.9 25.1 12.5 25.1H25.3C25.9 25.1 26.4 24.6 26.4 24V4.1C26.4 3.5 25.9 3 25.3 3H12.5C11.9 3 11.4 3.5 11.4 4.1V11.6L8.6 11.4V8.9C3 11.5 4.3 12.8 7.4 13.5C7.8 13.6 8.2 13.7 8.6 13.7C9.5 13.8 10.5 13.9 11.4 14C12 14.1 12.6 14.1 13.2 14.1C15.2 14.2 16.7 14.2 16.7 14.2V11.1C16.7 10.6 17.3 10.7 17.5 10.9L23.3 14.9L25.6 16.5C25.8 16.7 25.8 17 25.6 17.3L23.3 18.9L20.5 20.9L17.6 22.9C17.5 23 17.3 23.1 17.2 23.1C17.1 23.1 16.8 23.1 16.8 22.8V20.3C15.3 20 13.9 19.8 12.6 19.5C12.2 19.4 11.9 19.3 11.5 19.3C10.5 19.1 9.5 18.8 8.7 18.6C8 18.4 7.4 18.2 6.8 18C-7.6 12.9 8.7 6.9 8.7 6.9V3.9C8.7 1.8 10.4 0.0999985 12.5 0.0999985H25.5C27.5 0.0999985 29.2 1.8 29.2 3.9Z'
						fill=''
					/>
				</svg> */}

        <img
          className="w-full h-full object-cover hidden sm:flex  rounded-md lg:rounded-[20px]    z-10 "
          src={image}
          alt="product image"
        />
        <img
          onClick={handleClick}
          className="w-full h-full object-cover flex sm:hidden  rounded-md lg:rounded-[20px]    z-10 "
          src={image}
          alt="product image"
        />
        <h2 className="z-30 textshadow  text-white absolute flex items-center justify-center gap-1 group lg:left-[52px] top-[84%] md:top-[85%] text-base md:text-lg left-[10px]   lg:top-[62%]    lg:text-5xl  font-semibold">
          {text}
        </h2>
        <h5 className="z-30 hidden textshadow text-white  absolute lg:flex items-center justify-center gap-1 group left-[52px]   top-[74%]    text-base  font-medium">
          {textHidden}
        </h5>
        <h5 className="z-30 hidden textshadow text-white  absolute lg:flex items-center justify-center gap-1 group left-[52px]   top-[78%]    text-base  font-medium">
          {textHiddenSecondLine}
        </h5>
        <h3
          onClick={handleClick}
          className="z-30 absolute hidden lg:flex textshadow group-hover:text-lime-400 hover:scale-105    items-center justify-center gap-1 group left-[52px]  cursor-pointer transition-all duration-500 ease-in-out  top-[86%] text-white text-xl font-medium"
        >
          Learn More {">"}
        </h3>
      </div>
    </div>
  );
};
export default DivComponent;
