import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import Loading from '../../Loading';
import LoginFullPagePaul from '../LoginMenu/LoginFullPagePaul';
import NavBar from '../NavBar/NavBar';
import Footer from './Footer';
import SalesmrktLogo from '../../Assets/Vision_Assets/Salesmrkt_logo.svg'
import SalesboxtLogo from '../../Assets/Vision_Assets/Salesbox_Logo.svg'
import MissionImage from '../../Assets/Vision_Assets/MissionImage.png'
import VisionImage from '../../Assets/Vision_Assets/VisionImage.png'
import CheckIcon from '../../Assets/Vision_Assets/CheckIcon.svg'
import DiversityImage from '../../Assets/Vision_Assets/DiversityImage.png'
import LandingImage from '../../Assets/Vision_Assets/LandingImage.svg'
import OurValues from '../../Assets/Vision_Assets/OurValues.svg'
import GlobalDiversity from '../../Assets/Vision_Assets/GlobalDiversity.png'
import SalesExperienceBoxes from '../../Assets/Vision_Assets/SalesExperienceBoxes.svg'

const Vision_V2 = () => {
    const [bundles, setBundles] = useState(false);
	const [navOne, setNavOne] = useState(false);
	const [navTwo, setNavTwo] = useState(false);
	const [navThree, setNavThree] = useState(false);
	const [navFour, setNavFour] = useState(true);
	const [exploreButton, setExploreButton] = useState(false);
	const [resources, setresources] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isIdle, setIsIdle] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [popUpHasBeenShown, setPopUpHasBeenShown] = useState(false);
	const [LoginMenuOpener, setLoginMenuOpener] = useState(false);
	const [LetsTalkOpener, setLetsTalkOpener] = useState(false);
	let idleTimer: NodeJS.Timeout;
	const [FooterSelected, setFooterSelected] = useState(18);
    useEffect(() => {
		// Simulate loading for 1 second
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 1900);

		// Clean up the timer on component unmount
		return () => clearTimeout(timer);
	}, []);
  return (
    <div>
        	<Helmet>
				<title>Salesmrkt Vision</title>
			</Helmet>
			<Loading isLoading={isLoading} />

			<LoginFullPagePaul
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
			/>
			<NavBar
				LetsTalkOpener={LetsTalkOpener}
				setLetsTalkOpener={setLetsTalkOpener}
				Four={navFour}
				setNumber={setFooterSelected}
				setFour={setNavFour}
				budnles={bundles}
				setBundles={setBundles}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
				One={navOne}
				Two={navTwo}
				Three={navThree}
				setExploreButton={setExploreButton}
				recources={resources}
				setRecources={setresources}
				isOpen={isOpen}
				setOpen={setOpen}
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
			/>
            <div className='w-full h-full flex items-center justify-center'>
            <div className='w-full h-full mt-[85px] '>
                <div className='w-full h-full'>
                    <img src={LandingImage} className='w-full' alt="" />

                </div>
                <div className='flex flex-col items-center justify-center'>
                <div className='flex flex-col lg:flex-row items-start justify-center gap-16 lg:pl-16 lg:pr-24 lg:pt-16 lg:pb-6 p-6'>
                    <img src={VisionImage} className='' alt="" />
                   
                    <div className='flex flex-col gap-14 2xl:w-[65%]    '>
                    <div className='flex flex-col  gap-3 items-start justify-center'>
                        <h2 className='text-black text-3xl lg:text-4xl xl:text-6xl  font-semibold'>
                        Our <span className='text-lime-500'>Mission</span>
                        </h2>
                        <p className='text-2xl 2xl:text-4xl '>
                        To revolutionize the B2B sales process by empowering our clients with enterprise sales solutions to accelerate revenue and build best-in-class sales experiences.
                        </p>
                    </div>
                    <div className='flex flex-col  gap-3 items-start justify-center  '>
                        <h2 className='text-black text-3xl lg:text-4xl xl:text-6xl  font-semibold'>
                        Our <span className='text-lime-500'>Vision</span>
                        </h2>
                        <p className='text-[25px]'>
                        Salesmrkt is setting the benchmark for B2B sales, providing businesses an all-in-one marketplace giving them the <b>Ad<img className='inline-flex mb-[5px] mr-[-3px]' src={CheckIcon} alt="" />antage</b> in today's hyper-competitive market.
                        </p>
                        <p className='text-[25px]'>
                        We’re transforming the service supply chain with customized, scalable, and value-driven solutions that help our clients and vendors secure customers and growth opportunities. 
                        </p>
                        <p className='text-[25px]'>
                        We are the trusted partner for B2B buyers and sellers, providing them with the tools to elevate their sales and drive revenue, building a legacy of success and loyalty.
                        </p>
                    </div>
                </div>
                </div>
                <img src={MissionImage} className='w-[45%] ' alt="" />
                

                </div>
                   
                   
                {/* <div className='w-full h-full  mb-6 flex flex-col items-center justify-center bg-black p-6'>
                <h2 className='text-white text-3xl lg:text-4xl xl:text-6xl 2xl:text-7xl font-semibold mb-6'>
                        Our <span className='text-lime-500'>Values</span>
                        </h2>
                <div className='grid grid-cols-2  w-full md:w-[90%] lg:w-[85%]  p-6'>
                    <div className='flex flex-col items-start justify-center gap-2'>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Customer obsession 
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Results realized through action
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Diversity unlocks emerging solutions
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Collaboration driven by personal accountability
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Simplicity is the key to clarity*
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Challenge the status quo, consistently innovate
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Value first approach
                            </h4>
                        </div>

                    </div>
                    <div className='flex flex-col items-end justify-start gap-2'>
                        <div className='flex items-start flex-col gap-2'>

                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white  2xl:text-2xl'>
                            You can only change the world if you aim big*
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Build trust & create wins in all directions
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Unity allows the momentum to deliver real impact
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Equalizing the playing field for SMBs 
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Allow each other space for grace
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                            Be earnest and operate with transparency
                            </h4>
                        </div>
                        <div className='flex items-center justify-center gap-4'>
                            <img src={CheckIconGreen} alt="" />
                            <h4 className='text-white 2xl:text-2xl'>
                                 Catalyst for growth
                            </h4>
                        </div>
                        </div>
                    </div>
                </div>
                </div> */}
                <div className='flex items-center justify-center'>

                <img src={OurValues} className='w-full' alt="" />
                </div>

                {/* <div className='w-full h-full mt-6 mb-6 grid grid-cols-1 md:grid-cols-5 gap-2 '>
                    <div className='flex items-center justify-center col-span-2'>
                        <img src={DiversityImage} className='max-h-[500px]' alt="" />
                    </div>
                    <div className='flex flex-col col-span-3 gap-6 items-start justify-center xl:pr-20'>
                        <h2 className='text-black text-3xl lg:text-4xl xl:text-6xl font-semibold'>
                        Global  <span className='text-lime-500'>Diversity & Inclusion</span>
                        </h2>
                        <p className='text-3xl'>
                        We are fortunate to live in a unique time where building a truly global team is possible. By celebrating and uniting the diverse strengths of each team member, we are able to create a dynamic environment that breeds innovative solutions and opens the door to an emerging sales model that has never existed before.
                        </p>
                    </div>
                </div> */}
                <div className='w-full h-full flex items-center justify-center'>
                <img src={GlobalDiversity} className='w-full ' alt="" />
                </div>
                <div className='w-full h-full flex items-center justify-center'>
                <img src={SalesExperienceBoxes} className='w-full  ' alt="" />
                </div>

              
            </div>
            </div>
            <Footer
				LetsTalkOpener={LetsTalkOpener}
				setLetsTalkOpener={setLetsTalkOpener}
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
				Four={navFour}
				setFour={setNavFour}
				numberId={FooterSelected}
				setNumber={setFooterSelected}
				recources={resources}
				budnles={bundles}
				setBundles={setBundles}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
				One={navOne}
				Two={navTwo}
				Three={navThree}
				setExploreButton={setExploreButton}
				setRecources={setresources}
			/>
    </div>
  )
}

export default Vision_V2