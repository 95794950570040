import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../Loading';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image1 from '../../Assets/img/PitchDeckImages/1.png';
import Image2 from '../../Assets/img/PitchDeckImages/2.jpg';
import Image3 from '../../Assets/img/PitchDeckImages/3.jpg';
import Image4 from '../../Assets/img/PitchDeckImages/PitchDeckImage_3.png';
import Image5 from '../../Assets/img/PitchDeckImages/5.jpg';
import Image6 from '../../Assets/img/PitchDeckImages/6.jpg';
import Image7 from '../../Assets/img/PitchDeckImages/7.jpg';
import Image8 from '../../Assets/img/PitchDeckImages/8.jpg';
import Image9 from '../../Assets/img/PitchDeckImages/9.jpg';
import Image10 from '../../Assets/img/PitchDeckImages/10.jpg';
import Image11 from '../../Assets/img/PitchDeckImages/11.jpg';
import Image12 from '../../Assets/img/PitchDeckImages/12.jpg';
import NewSlide from '../../Assets/img/PitchDeckImages/NewSlide.jpg';
import TN_Video from '../../Assets/img/PitchDeckImages/Image.png';
import BundleOpen from '../../Assets/img/PitchDeckImages/BundleOpen.mp4';
import InvestorVideo from '../../Assets/img/OverviewAssets/Invest in the Amazon of B2B Services _ Salesmrkt.mp4';
import DemoVideo from '../../Assets/img/OverviewAssets/Salesmrkt Demo 3.0 with VO.mp4';
import ProblemVideo from '../../Assets/video/Solving the Top 3 Problems in B2B Industry #business #b2b #marketing.mp4';
// import Image13 from '../../Assets/img/PitchDeckImages/13.jpg';
import Logo from '../../Assets/SVG/SalesmrktLogo.svg';
import './PitchDeck.css';
import ReactPlayer from 'react-player';
import VideoEmbed from '../YoutubeEmbed/VideoEmbed';
const PitchDeck = () => {
	const videoRef = useRef<ReactPlayer | null>(null);
	const [selectedFirst, setIsselectedFirst] = useState(false);
	const handlePlayButtonClick = () => {
		setIsselectedFirst(true);
	};
	const [videoOpener, setVideoOpener] = useState(false);
	const [videoOpenerMrketplace, setvideoOpenerMrketplace] = useState(false);
	const handleCloseOverlay = () => {
		setVideoOpener(false);
	};
	const handleCloseOverlayMarketplace = () => {
		setvideoOpenerMrketplace(false);
	};
	const [isLoading, setIsLoading] = useState(true);
	const sliderRef = useRef<Slider>(null);
	useEffect(() => {
		window.scrollTo(0, 0);
		// Simulate loading for 1 second
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2200);

		// Clean up the timer on component unmount
		return () => clearTimeout(timer);
	}, []);

	// handle keyboard input here //
	const handleKeyDown = (e: React.KeyboardEvent) => {
		const keyboardEvent = e as any;

		if (keyboardEvent.key === 'ArrowLeft') {
			sliderRef.current?.slickPrev();
		} else if (keyboardEvent.key === 'ArrowRight') {
			sliderRef.current?.slickNext();
		}
	};

	useEffect(() => {
		document.addEventListener('keydown', handleKeyDown as any);

		return () => {
			document.removeEventListener('keydown', handleKeyDown as any);
		};
	}, []);

	const CustomPrevArrow = (props: any) => (
		<div
			{...props}
			className=' w-3 absolute cursor-pointer z-50  left-[-25px] top-[50%] '>
			<svg
				className='hidden lg:flex'
				width='18'
				height='18'
				viewBox='0 0 22 23'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M21.3754 22.55L10.6254 22.55L0.47539 11.5L10.6254 0.449999L21.3754 0.45L11.1754 11.5L21.3754 22.55Z'
					fill='black'
				/>
			</svg>
		</div>
	);

	const CustomNextArrow = (props: any) => (
		<div
			{...props}
			className=' w-3 absolute cursor-pointer z-50 right-[-25px] top-[50%] '>
			{/* Your custom next arrow content, e.g., an SVG or an icon */}
			<svg
				className='hidden lg:flex'
				width='18'
				height='18'
				viewBox='0 0 22 23'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M0.624609 0.449998H11.3746L21.5246 11.5L11.3746 22.55H0.624609L10.8246 11.5L0.624609 0.449998Z'
					fill='black'
				/>
			</svg>
		</div>
	);
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
	};

	return (
		<div className='w-full h-screen outline-none  flex items-center   justify-center'>
			{videoOpener && (
				<div>
					<VideoEmbed
						videoPath={InvestorVideo}
						onClose={handleCloseOverlay}
					/>
					{/* <
						videoId='9vKzzf3QKvE'
						onClose={handleCloseOverlay}
					/> */}
				</div>
			)}
			{videoOpenerMrketplace && (
				<div>
					<VideoEmbed
						videoPath={DemoVideo}
						onClose={handleCloseOverlayMarketplace}
					/>
					{/* <
						videoId='9vKzzf3QKvE'
						onClose={handleCloseOverlay}
					/> */}
				</div>
			)}
			<div
				tabIndex={0}
				className='w-full h-full  '>
				<Loading isLoading={isLoading} />

				<div className='w-full flex pt-5 pl-5 pr-5  items-center justify-center '>
					<div className='w-[93%] h-fit'>
						<Slider
							ref={sliderRef}
							{...settings}
							className='your-slider-class  border-none outline-none '>
							<img
								src={Image1}
								className='h-[95vh] w-full border-none outline-none  aspect-video  object-contain 2xl:object-cover    '
								alt=''
							/>
							<div className='w-full h-full relative'>
								<img
									src={Image2}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='absolute left-[0%] bottom-[15%] flex items-center justify-center  w-full'>
									<a className='relative flex items-start justify-start' href="https://www.youtube.com/shorts/aaj9fZoInEI" target="_blank" rel="noopener noreferrer">
										<div className='mt-2'>
										<svg  width="24" height="22" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.8243 9.6449L22.6456 10.7307L23.3695 9.57763C23.6046 9.20309 23.93 8.89394 24.3371 8.68751C25.5693 8.06266 27.1665 8.57949 27.8656 9.95811L27.8657 9.95837L31.9348 17.976C31.9348 17.9761 31.9349 17.9762 31.9349 17.9763C33.4362 20.9374 32.1163 24.7423 28.8081 26.4198L22.0788 29.8321C21.244 30.2554 20.1439 30.4616 18.9685 30.4653C17.8123 30.469 16.6522 30.2763 15.7172 29.9633L4.84057 25.4057L4.8404 25.4057C3.46666 24.8303 2.76915 23.2265 3.27837 21.8459C3.7769 20.4963 5.25014 19.8547 6.59328 20.4183L6.59391 20.4186L8.36468 21.1601L10.7209 22.1467L9.56561 19.8685L2.08016 5.10656C1.38087 3.72751 1.9068 2.13519 3.14046 1.50962C4.37286 0.884701 5.96952 1.40101 6.66868 2.77981L10.5492 10.4324L11.3123 11.9374L12.1897 10.4961C12.4255 10.1089 12.7561 9.79004 13.1742 9.57805C14.1223 9.09726 15.276 9.28996 16.0879 10.0384L16.962 10.8442L17.544 9.80753C17.78 9.38723 18.1269 9.04033 18.5724 8.81442C19.665 8.26038 21.0343 8.60038 21.8243 9.6449Z" fill="white" stroke="#6EAB12" stroke-width="1.87919"/>
</svg>
										</div>
									<h2 className='text-[#84CC16] text-xl text-center xl:text-2xl 2xl:text-3xl w-full '>The enterprise service supply chain has not <br /> been optimized in the last 100 years</h2>
									</a>
								</div>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Problem
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<div onClick={() => setVideoOpener(true)} className='absolute bottom-[5%] left-[47%] group flex items-center justify-center gap-2 cursor-pointer'>
								<svg
												width='58'
												height='58'
												viewBox='0 0 58 58'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M22.8555 28.2538C22.8555 31.0532 22.8555 33.8527 22.8555 36.6522C22.8555 38.2074 23.7219 38.674 25.1002 37.8963C28.3295 36.0689 31.5587 34.2415 34.7879 32.3752C36.5206 31.4031 38.2534 30.4311 39.9468 29.4202C41.0889 28.7593 41.0889 27.7483 39.9468 27.0485C39.868 27.0096 39.7499 26.9318 39.6712 26.8929C34.8273 24.1324 29.944 21.3718 25.1002 18.6112C23.7219 17.8336 22.8948 18.3002 22.8555 19.8554C22.8555 22.6938 22.8555 25.4932 22.8555 28.2538Z'
													stroke='#84CC16'
													stroke-width='2.52277'
													stroke-miterlimit='10'
												/>
												<path
													d='M29.2714 56.0238C44.3653 56.0238 56.6014 43.7877 56.6014 28.6938C56.6014 13.5998 44.3653 1.36377 29.2714 1.36377C14.1775 1.36377 1.94141 13.5998 1.94141 28.6938C1.94141 43.7877 14.1775 56.0238 29.2714 56.0238Z'
													stroke='#84CC16'
													stroke-width='2.52277'
												/>
											</svg>
											<h3 className='text-black text-xl group-hover:text-lime-500 transition-all duration-300 ease-in '>
												Play
											</h3>
								</div>
								<img
									src={Image3}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Solutions
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-[95vh]   relative'>
								<div className='flex items-center justify-center h-[95vh] overflow-visible '>
									<div className='w-full h-full  flex flex-col items-start justify-evenly pb-10'>
										<div className='mt-16'>
											<h1 className='text-black'>
												Inside the Box
											</h1>
											<h2 className='text-black mt-1'>
												Turnkey multi-vendor full stack
												solutions
											</h2>
										</div>
										<img
											src={Image4}
											className=' object-cover    '
											alt=''
										/>
										<h4 onClick={() => setvideoOpenerMrketplace(true)} className='font-medium text-lime-500 text-2xl cursor-pointer 2xl:text-3xl'>
											Easy to Buy {'>'}
										</h4>
										
									</div>
									<div className='relative w-[65%]'>
										<div
											onClick={() => {
												handlePlayButtonClick();
											}}
											className='absolute  bottom-[25%] right-[30%] hover:scale-[1.01] transition-all cursor-pointer z-[999]'>
											{!selectedFirst && (
											<div className='flex items-center justify-center gap-2'>
											<svg
												width='58'
												height='58'
												viewBox='0 0 58 58'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'>
												<path
													d='M22.8555 28.2538C22.8555 31.0532 22.8555 33.8527 22.8555 36.6522C22.8555 38.2074 23.7219 38.674 25.1002 37.8963C28.3295 36.0689 31.5587 34.2415 34.7879 32.3752C36.5206 31.4031 38.2534 30.4311 39.9468 29.4202C41.0889 28.7593 41.0889 27.7483 39.9468 27.0485C39.868 27.0096 39.7499 26.9318 39.6712 26.8929C34.8273 24.1324 29.944 21.3718 25.1002 18.6112C23.7219 17.8336 22.8948 18.3002 22.8555 19.8554C22.8555 22.6938 22.8555 25.4932 22.8555 28.2538Z'
													stroke='#84CC16'
													stroke-width='2.52277'
													stroke-miterlimit='10'
												/>
												<path
													d='M29.2714 56.0238C44.3653 56.0238 56.6014 43.7877 56.6014 28.6938C56.6014 13.5998 44.3653 1.36377 29.2714 1.36377C14.1775 1.36377 1.94141 13.5998 1.94141 28.6938C1.94141 43.7877 14.1775 56.0238 29.2714 56.0238Z'
													stroke='#84CC16'
													stroke-width='2.52277'
												/>
											</svg>
											<h3 className='text-lime-500 text-xs xl:text-sm 2xl:text-lg'>
												Click to open the box {'>'}
											</h3>
											</div>
											)}
										</div>
											<ReactPlayer
												ref={videoRef}
												url={BundleOpen}
												playing={selectedFirst}
												loop={false}
												width={'100%'}
												height={'100%'}
												controls={false}
											/>
									</div>
								</div>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Solutions
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={Image5}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Market Potential
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={Image6}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Market Analysis
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={Image7}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Traction
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={NewSlide}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
											Business Model
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={Image8}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Forecast
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							
							<div className='w-full h-full relative'>
								<div className='flex flex-col gap-2 items-start justify-start absolute top-[4%] left-[7%]'>
									<h2 className='text-black font-bold text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl'>Team for Success</h2>
									<div>
									<a href="https://salesmrkt.com/vision" target="_blank" rel="noopener noreferrer">

									<div className='flex items-start justify-start gap-1 '>
									<svg className='mt-2'  width="24" height="22" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.8243 9.6449L22.6456 10.7307L23.3695 9.57763C23.6046 9.20309 23.93 8.89394 24.3371 8.68751C25.5693 8.06266 27.1665 8.57949 27.8656 9.95811L27.8657 9.95837L31.9348 17.976C31.9348 17.9761 31.9349 17.9762 31.9349 17.9763C33.4362 20.9374 32.1163 24.7423 28.8081 26.4198L22.0788 29.8321C21.244 30.2554 20.1439 30.4616 18.9685 30.4653C17.8123 30.469 16.6522 30.2763 15.7172 29.9633L4.84057 25.4057L4.8404 25.4057C3.46666 24.8303 2.76915 23.2265 3.27837 21.8459C3.7769 20.4963 5.25014 19.8547 6.59328 20.4183L6.59391 20.4186L8.36468 21.1601L10.7209 22.1467L9.56561 19.8685L2.08016 5.10656C1.38087 3.72751 1.9068 2.13519 3.14046 1.50962C4.37286 0.884701 5.96952 1.40101 6.66868 2.77981L10.5492 10.4324L11.3123 11.9374L12.1897 10.4961C12.4255 10.1089 12.7561 9.79004 13.1742 9.57805C14.1223 9.09726 15.276 9.28996 16.0879 10.0384L16.962 10.8442L17.544 9.80753C17.78 9.38723 18.1269 9.04033 18.5724 8.81442C19.665 8.26038 21.0343 8.60038 21.8243 9.6449Z" fill="white" stroke="#6EAB12" stroke-width="1.87919"/>
</svg>

									<h3 className='text-[#84CC16]  text-xl md:text-2xl xl:text-3xl 2xl:text-2xl'>United in <span className='underline'>Vision</span>, Passion & Results</h3>
									</div>
									</a>
									</div>
								</div>
								<img
									src={Image9}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Team
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={Image10}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Roadmap
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={Image11}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Opportunity
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
							<div className='w-full h-full relative'>
								<img
									src={Image12}
									className='h-[95vh] w-full border-none outline-none   aspect-video  object-contain 2xl:object-cover  '
									alt=''
								/>
								<div className='w-full overflow-visible  items-center  pt-5 pb-5 absolute bottom-[-25px] justify-between hidden lg:flex'>
									<div>
										<div>
											<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
												Opportunity
											</div>
										</div>
									</div>
									<div>
										<img
											src={Logo}
											className='max-w-[150px]'
											alt=''
										/>
									</div>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PitchDeck;
