import { motion } from "framer-motion";
import Apple from "../../Assets/img/Coustmers/Apple_logo_black.svg";
import Taiwan from "../../Assets/img/Coustmers/Group 26973.svg";
import cydPerson from "../../Assets/img/Coustmers/cydPerson.png";
import TaiwanPerson from "../../Assets/img/Coustmers/taiwaniPerson.png";
import forvis from "../../Assets/img/Coustmers/Icon_Text_Dark+Brown_Stack_adobe_express 1.svg";
import person2 from "../../Assets/img/Coustmers/9.png";
import person4 from "../../Assets/img/Coustmers/15.png";
import robloxOther from "../../Assets/img/Coustmers/Clip path group.svg";
import person5 from "../../Assets/img/Coustmers/21.png";
import microsoft from "../../Assets/img/Coustmers/Microsoft_logo.svg";
import recharge from "../../Assets/img/Coustmers/recharge.svg";
import pace from "../../Assets/img/Coustmers/Pace_University_Logo.svg";
import oxpedia from "../../Assets/img/Coustmers/axelis.svg";
import calixPerson from "../../Assets/img/Coustmers/calixPerson.png";
import ups from "../../Assets/img/Coustmers/ups.svg";
import ibm from "../../Assets/img/Coustmers/IBM_logo.svg";
import worldvisionOther from "../../Assets/img/Coustmers/Group 26974.svg";
import calix from "../../Assets/img/Coustmers/calix.svg";
import amazon from "../../Assets/img/Coustmers/Amazon_icon.svg";
import drata from "../../Assets/img/Coustmers/drata.svg";

const LeadingBrands = () => {
  return (
    <motion.div
      initial={"offscreen"}
      whileInView={"onscreen"}
      viewport={{ once: false, amount: 0.4 }}
      transition={{ staggerChildren: 1 }}
      className="w-full h-full"
    >
      <motion.div className="grid    grid-cols-3 md:gap-10 gap-4 items-start  justify-between ">
        <div className="flex flex-col items-center justify-center   gap-6 bg-gradient-to-b rounded-md from-zinc-600/50 to-transparent">
          <motion.div
            whileHover={{ scale: 1.03 }}
            className="w-full border text-2xl font-semibold rounded-b-[10px]  shadow-xl flex flex-col rounded-md p-2 relative shadow-black/20   h-[90px] sm:h-[135px]   items-center justify-center bg-gradient-to-b from-zinc-900 to-zinc-700 "
          >
            <div>
              <h3 className="text-lime-500 text-lg md:text-2xl font-bold">
                Small
              </h3>
              <h3 className=" text-md  md:text-2xl font-semibold">Business</h3>
            </div>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[75px] sm:w-[110px] sm:h-[110px]  shadow-xl shadow-black/20  ease-in  h-[75px] p-2 flex items-center justify-center bg-white rounded-[10px]"
          >
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                delay: 7,
                repeatType: "reverse",
                repeatDelay: 10.5,
                duration: 4,
                ease: "easeInOut",
              }}
              src={TaiwanPerson}
              className=" absolute h-[75px] rounded-[10px] w-[75px] sm:w-[110px] sm:h-[110px]  object-cover "
              alt="Copmany Logo"
            />
            <img src={Taiwan} className="w-[54px]" alt="Copmany Logo" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[75px] shadow-xl shadow-black/20   md:flex sm:hidden flex  h-[75px] p-2  items-center justify-center bg-white rounded-[10px]"
          >
            <img
              src={worldvisionOther}
              className="w-[84px]"
              alt="lead generation"
            />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className=" flex items-center  relative  w-[75px] sm:w-[110px] sm:h-[110px] shadow-xl shadow-black/20  ease-in h-[75px]  justify-center bg-white rounded-[10px]"
          >
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                delay: 2,
                repeatType: "reverse",
                repeatDelay: 12,
                duration: 4,
                ease: "easeInOut",
              }}
              src={cydPerson}
              className=" absolute rounded-[10px] h-full w-full "
              alt="Copmany Logo"
            />
            <img src={forvis} className="  w-[70%] " alt="Copmany Logo" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[75px] sm:w-[110px] sm:h-[110px] shadow-xl shadow-black/20  ease-in h-[75px] p-2 flex items-center justify-center bg-white rounded-[10px]"
          >
            <img src={robloxOther} className="w-[84px]" alt="Copmany Logo" />
          </motion.div>
        </div>
        <div className="flex flex-col items-center justify-center   gap-6 bg-gradient-to-b rounded-md from-zinc-900/70  to-transparent">
          <motion.div
            whileHover={{ scale: 1.03 }}
            className="w-full border text-2xl font-semibold rounded-b-[10px]   shadow-xl flex flex-col rounded-md p-2 relative shadow-black/20   h-[90px] sm:h-[135px]   items-center justify-center bg-gradient-to-b from-zinc-900 to-zinc-700 "
          >
            <div>
              <h3 className="text-lime-500 text-lg md:text-3xl font-bold">
                Mid
              </h3>
              <h3 className=" text-md  md:text-2xl font-semibold">Market</h3>
            </div>
          </motion.div>

          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[80px] sm:w-[120px] shadow-xl shadow-black/20  ease-in h-[80px] sm:h-[120px] p-2 md:flex sm:hidden flex items-center justify-center bg-white rounded-[10px]"
          >
            <img src={recharge} className="w-[54px]" alt="Copmany Logo" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className=" flex items-center  relative  w-[80px] shadow-xl shadow-black/20  ease-in h-[80px]  justify-center bg-white rounded-[10px]"
          >
            <img
              src={drata}
              className="  w-[110px] p-2 "
              alt="lead generation"
            />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[80px] sm:w-[120px] shadow-xl shadow-black/20  ease-in h-[80px] sm:h-[120px] p-2 flex items-center justify-center bg-white rounded-[10px]"
          >
            <img src={pace} className="w-[54px]" alt="Copmany Logo" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[80px] sm:w-[120px] shadow-xl shadow-black/20  ease-in h-[80px] sm:h-[120px]  flex items-center justify-center bg-white rounded-[10px]"
          >
            <img src={oxpedia} className=" w-[64px]" alt="Copmany Logo" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[80px] sm:w-[120px] group shadow-xl shadow-black/20 relative  ease-in h-[80px] sm:h-[120px] flex items-center justify-center bg-white rounded-[10px]"
          >
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                delay: 12,
                repeatType: "reverse",
                repeatDelay: 24,
                duration: 4,
                ease: "easeInOut",
              }}
              src={calixPerson}
              className="absolute w-full rounded-[10px] "
              alt="Copmany Logo"
            />
            <img src={calix} className="w-[54px]" alt="Copmany Logo" />
          </motion.div>
        </div>
        <div className="flex flex-col items-center justify-center  gap-6 bg-gradient-to-b rounded-md from-zinc-900 via-black/50 to-transparent">
          <motion.div
            whileHover={{ scale: 1.03 }}
            className="w-full border text-2xl font-semibold rounded-b-[10px]  shadow-xl flex flex-col rounded-md p-2 relative shadow-black/20   h-[90px] sm:h-[135px]   items-center justify-center bg-gradient-to-b from-zinc-900 to-zinc-700 "
          >
            <div>
              <h3 className="text-lime-500 text-lg md:text-4xl font-bold">
                Large
              </h3>
              <h3 className=" text-md  md:text-2xl font-semibold">
                Enterprise
              </h3>
            </div>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[90px] sm:w-[135px] shadow-xl shadow-black/20  ease-in h-[90px] sm:h-[135px]  flex items-center justify-center bg-white rounded-[10px]"
          >
            <img src={Apple} className="w-[48px]" alt="apple" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[90px] sm:w-[135px] group shadow-xl shadow-black/20 relative  ease-in h-[90px] sm:h-[135px]  flex items-center justify-center bg-white rounded-[15px]"
          >
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                delay: 16,
                repeatType: "reverse",
                repeatDelay: 20,
                duration: 4,
                ease: "easeInOut",
              }}
              src={person5}
              className="absolute w-full h-full object-cover "
              alt="person"
            />
            <img src={ups} className=" w-[54px]  " alt="ups" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="h-[90px] shadow-xl shadow-black/20  ease-in w-[90px] p-2 flex items-center justify-center bg-white rounded-[10px]"
          >
            <img src={amazon} className="w-[54px]" alt="lead generation" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[90px] sm:w-[135px]  shadow-lg relative shadow-black/30  ease-in h-[90px] sm:h-[135px] flex items-center justify-center bg-white rounded-[10px]"
          >
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                repeatDelay: 7,
                duration: 4,
                ease: "easeInOut",
              }}
              src={person2}
              className=" object-cover absolute  h-full  rounded-lg "
              alt="Copmany Logo"
            />
            <img src={microsoft} className="  w-[54px]  " alt="Copmany Logo" />
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="w-[90px] sm:w-[135px]  relative shadow-xl shadow-black/20  ease-in h-[90px] sm:h-[135px]  flex items-center justify-center bg-white rounded-[10px]"
          >
            <motion.img
              animate={{ opacity: 0 }}
              transition={{
                repeat: Infinity,
                delay: 9.5,
                repeatType: "reverse",
                repeatDelay: 11,
                duration: 4,
                ease: "easeInOut",
              }}
              src={person4}
              className=" absolute h-[90px] sm:h-[135px] w-[90px] sm:w-[135px] object-cover rounded-[10px] "
              alt="Copmany Logo"
            />
            <img src={ibm} className="w-[54px] " alt="Copmany Logo" />
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};
export default LeadingBrands;
