import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBQKNN0uVFkJUg9K7Gj8KuYTNQUuPPub2c",
  authDomain: "salesmrkt-suryaversion.firebaseapp.com",
  projectId: "salesmrkt-suryaversion",
  storageBucket: "salesmrkt-suryaversion.appspot.com",
  messagingSenderId: "467944021985",
  appId: "1:467944021985:web:d69fc8cd0adbfe30ff7ca0"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
