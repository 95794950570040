import React, { useEffect, useState } from "react";
import logo from "../../Assets/SVG/Group 26709.png";
import logo_Black from "../../Assets/SVG/Group 27133.svg";
import certified from "../../Assets/img/jh 1.svg";
import BankingInfo from "../../Assets/img/Banking.svg";
import LinkedInLogo from "../../Assets/Icons/LinkedIn.svg";
import { HashLink } from "react-router-hash-link";
import EmailInput from "../Communication/EmailInput";
import { FaCalendar } from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
interface Navprops {
  budnles: boolean;
  recources: boolean;
  LoginMenuOpener: boolean;
  LetsTalkOpener: boolean;
  setLetsTalkOpener: React.Dispatch<React.SetStateAction<any>>;
  setLoginMenuOpener: React.Dispatch<React.SetStateAction<any>>;
  setBundles: React.Dispatch<React.SetStateAction<any>>;
  setRecources: React.Dispatch<React.SetStateAction<any>>;
  One: boolean;
  Two: boolean;
  Three: boolean;
  Four: boolean;
  numberId: number;
  setNumber: React.Dispatch<React.SetStateAction<any>>;
  setOne: React.Dispatch<React.SetStateAction<any>>;
  setTwo: React.Dispatch<React.SetStateAction<any>>;
  setThree: React.Dispatch<React.SetStateAction<any>>;
  setFour: React.Dispatch<React.SetStateAction<any>>;
  setExploreButton: React.Dispatch<React.SetStateAction<any>>;
}
const Footer: React.FC<Navprops> = ({
  budnles,
  LoginMenuOpener,
  setLoginMenuOpener,
  setBundles,
  recources,
  setRecources,
  LetsTalkOpener,
  setLetsTalkOpener,
  One,
  Two,
  Three,
  Four,
  setFour,
  numberId,
  setNumber,
  setOne,
  setTwo,
  setThree,
  setExploreButton,
}) => {
  const [promosCliked, setpromosCliked] = useState(false);
  const handleClick = () => {
    setBundles(true); // Set the state to true on click

    setTimeout(() => {
      setBundles(false); // Revert the state back to false after 600ms
    }, 1600);
  };
  // const handleSolutions = () => {
  // 	// handleClick();
  // 	const navbarHeight = 130; // Height of your fixed navbar in pixels
  // 	const executiveDiv = document.getElementById('Solutions');
  // 	if (executiveDiv) {
  // 		const topOffset = executiveDiv.offsetTop - navbarHeight;
  // 		window.scrollTo({
  // 			top: topOffset,
  // 			behavior: 'smooth',
  // 		});
  // 	}
  // };

  const handelPromos = () => {
    setpromosCliked(true);
    setTimeout(() => {
      setpromosCliked(false);
    }, 1500);
  };
  useEffect(() => {
    if (recources === true) {
      setTimeout(() => {
        setRecources(false);
      }, 3000);
    }
  }, [recources]);

  return (
    <div
      id="Resources"
      className="h-full w-full   bg-black lg:p-10 p-6 overflow-hidden  "
    >
      <div className="w-full h-full flex  justify-center">
        <div className="w-full lg:w-[85%] h-full flex flex-col lg:flex-row  justify-between">
          <div className="h-full ">
            <div className="grid grid-cols-2 lg:grid-cols-3 h-full gap-6  justify-start ">
              <div className="text-white    h-full  flex flex-col gap-1  items-start  ">
                <HashLink
                  smooth
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <h3 className="font-bold text-xl mb-4 hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Solutions
                  </h3>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(18)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Sales & Strategy
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(19)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Business Development
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(20)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Brand & Creative
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(21)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Digital Marketing
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(22)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Copy & Content
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(23)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Video Production
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(24)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Web & Tech
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(25)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Build My Team
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => setNumber(26)}
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Solutions"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Customize My Solution
                  </p>
                </HashLink>
                {/* <HashLink
									smooth
									className='font-bold text-xl  mt-6 md:hidden  hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer'
									onClick={() => {
										setOne(false);
										setTwo(false);
										setThree(false);
										setFour(true);
									}}
									to='/#Experience'>
									Experience
								</HashLink> */}

                <HashLink to={"/#landingPage"}>
                  <img
                    src={logo}
                    alt=""
                    className="w-[170px] hidden lg:flex mt-[107px] "
                  />
                  <img
                    src={logo_Black}
                    alt=""
                    className="w-[170px] hidden  mt-[107px] "
                  />
                </HashLink>
              </div>
              <div className="text-white h-full   flex flex-col  gap-1 ">
                <HashLink
                  smooth
                  onClick={() => {
                    setOne(true);
                    setTwo(false);
                    setThree(false);
                    setFour(false);
                  }}
                  className="hover:text-lime-400  group relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#landingPage"
                >
                  <p className="font-bold text-xl mb-4   hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Search
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => {
                    setTwo(false);
                    setOne(false);
                    setThree(true);
                    setFour(false);
                  }}
                  className="hover:text-lime-400 group   relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Experience"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    All Solutions
                  </p>
                </HashLink>

                <HashLink
                  smooth
                  onClick={() => {
                    setOne(true);
                    setTwo(false);
                    setThree(false);
                    setFour(false);
                  }}
                  className="hover:text-lime-400 group   relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Experience"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    OnDemand™
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => {
                    setOne(true);
                    setTwo(false);
                    setThree(false);
                    setFour(false);
                  }}
                  className="hover:text-lime-400 group   relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Experience"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Bundles
                  </p>
                </HashLink>
                <HashLink
                  smooth
                  onClick={() => {
                    setExploreButton(true);
                  }}
                  className="hover:text-lime-400 group   relative cursor-pointer transition-all duration-75 text-base ease-in"
                  to="/#Explore"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Explore
                  </p>
                </HashLink>

                <HashLink
                  smooth
                  className="  hidden lg:flex     hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer"
                  onClick={() => {
                    setOne(false);
                    setTwo(true);
                    setThree(false);
                    setFour(false);
                  }}
                  to="/"
                >
                  <p className="hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Member
                  </p>
                </HashLink>
                <div
                  className="  hidden lg:flex gap-2 items-center group     transition-all duration-75 ease-in cursor-pointer"
                  onClick={() => {
                    setLetsTalkOpener(!LetsTalkOpener);
                  }}
                >
                  <p className="group-hover:text-lime-400 transition-all duration-75 ease-in ">
                    Let's Talk
                  </p>
                  <FaCalendar className="h-[17px] w-[17px] text-white group-hover:text-[#6DC939] transition-all duration-75 ease-in" />
                </div>
                <div
                  className=" hidden lg:flex gap-2 items-center group     transition-all duration-75 ease-in cursor-pointer"
                  onClick={() => {
                    // setLoginMenuOpener(true);
                    window.location.href = "https://salesboxmarket.myshopify.com";
                  }}
                >
                  <p className="group-hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Login
                  </p>
                  <CiLogin className="text-xl group-hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer" />
                  {/* <CiLogin className='h-[20px] w-[20px] text-white group-hover:text-[#6DC939]' /> */}
                </div>

                {/* <HashLink
									smooth
									className='font-bold text-xl hidden md:flex    hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer'
									onClick={() => {
										setOne(false);
										setTwo(false);
										setThree(false);
										setFour(true);
									}}
									to='/#Experience'>
									Experience
								</HashLink> */}
              </div>
              <div className="text-white h-full  flex flex-col gap-1 ">
                <HashLink
                  smooth
                  className="text-black font-semibold group relative cursor-pointer  transition-all duration-75 text-base ease-in"
                  onClick={() => {
                    setRecources(true);
                  }}
                  to="/#Resources"
                >
                  <h3
                    className={`${recources
                      ? "font-bold text-xl min-w-[260px] mb-4 hover:text-lime-400 scale-105 text-lime-400 transition-all duration-300 ease-in cursor-pointer"
                      : "font-bold text-xl min-w-[260px] mb-4 hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer"
                      }''`}
                  >
                    Resource Center
                  </h3>
                </HashLink>
                <HashLink
                  smooth
                  className="hover:text-lime-400 group relative cursor-pointer transition-all duration-200 text-base ease-in"
                  to={"/lobby"}
                >
                  <div className="flex items-center  group  gap-3">
                    <p className="group-hover:text-lime-400 transition-all duration-200  ease-in cursor-pointer">
                      Virtual Lobby
                    </p>

                    <svg
                      width="25"
                      height="15"
                      viewBox="0 0 889 553"
                      className="group-hover:fill-lime-400 fill-white transition-all duration-200  ease-in"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.199951 275.8C0.199951 217.4 -0.100049 159 0.399951 100.6C0.499951 88.6004 2.39995 76.4004 4.99995 64.6004C12.3 31.2004 33.7 11.2004 66.8 4.60041C80.6 1.80041 94.8 0.300415 108.9 0.300415C190.2 0.00041455 271.4 0.300415 352.7 0.400415C388.1 0.300415 423.6 0.000414543 459 0.000414543C491.8 0.000414543 524.6 -0.299585 557.4 0.500415C570.1 0.800415 583 2.60041 595.4 5.40041C629.2 13.0004 648.9 34.9004 655.4 68.5004C657.7 80.5004 659.2 92.9004 659.2 105.1C659.5 209.3 659.6 313.5 659.4 417.6C659.4 437.8 659 458.3 656.3 478.3C650.6 519.4 626.4 543 585.3 549.3C573 551.2 560.5 552.3 548 552.3C479.2 552.5 410.5 552.3 341.7 552.2C294.4 552.1 247.2 552.1 199.9 552.1C167.9 552.1 136 552.6 104 551.9C90.5 551.6 76.7 549.7 63.5 546.7C30.0999 539.1 10.6 517.3 4.19995 484.1C1.69995 471.3 0.299951 458 0.199951 445C-0.200049 388.6 -4.88274e-05 332.2 -4.88274e-05 275.8C0.0999512 275.8 0.199951 275.8 0.199951 275.8Z"
                        fill=""
                      />
                      <path
                        d="M888.7 60.5C888.7 204.4 888.7 347.6 888.7 491.4C887.1 490.7 885.8 490.3 884.6 489.6C826.6 458.3 768.7 427 710.7 395.9C706.7 393.8 705.5 391.3 705.5 387C705.6 313.3 705.6 239.6 705.5 165.9C705.5 162.6 706.2 160.6 709.4 158.8C768.2 126.6 826.9 94.2 885.6 61.9C886.3 61.6 887.1 61.3 888.7 60.5Z"
                        fill=""
                      />
                    </svg>
                  </div>
                </HashLink>

                <HashLink
                  className="hover:text-lime-400  transition-all duration-75 ease-in cursor-pointer"
                  to={"/about"}
                >
                  <div className="flex items-center group gap-3">
                    About Us
                    {/* <svg
											className='fill-white group-hover:fill-lime-500'
											width='19'
											height='19'
											viewBox='0 0 17 17'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'>
											<path
												fillRule='evenodd'
												clipRule='evenodd'
												d='M16.6667 8.33333C16.6667 12.9357 12.9357 16.6667 8.33333 16.6667C3.73096 16.6667 0 12.9357 0 8.33333C0 3.73096 3.73096 0 8.33333 0C12.9357 0 16.6667 3.73096 16.6667 8.33333ZM8.33333 13.125C8.6785 13.125 8.95833 12.8452 8.95833 12.5V7.5C8.95833 7.15483 8.6785 6.875 8.33333 6.875C7.98817 6.875 7.70833 7.15483 7.70833 7.5V12.5C7.70833 12.8452 7.98817 13.125 8.33333 13.125ZM8.33333 4.16667C8.79358 4.16667 9.16667 4.53977 9.16667 5C9.16667 5.46023 8.79358 5.83333 8.33333 5.83333C7.87308 5.83333 7.5 5.46023 7.5 5C7.5 4.53977 7.87308 4.16667 8.33333 4.16667Z'
												fill=''
											/>
										</svg> */}
                  </div>
                </HashLink>

                <HashLink
                  smooth
                  className="hover:text-lime-400 flex gap-3 group group relative cursor-pointer items-center transition-all duration-75 text-base ease-in"
                  to={"/team"}
                >
                  <p className="group-hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Join the Team
                  </p>

                  <div className="h-[16px] flex items-center justify-center rounded-sm bg-white group-hover:bg-lime-500 transition-all duration-75 ease-in cursor-pointer  ">
                    <h5 className="group-hover:text-black pl-[1px] pr-[1px] transition-all duration-75 ease-in rounded-md text-black font-medium  text-[8px]">
                      Hiring
                    </h5>
                  </div>
                </HashLink>
                <HashLink
                  smooth
                  className="hover:text-lime-400  flex gap-3 group group relative cursor-pointer items-center transition-all duration-75 text-base ease-in"
                  to={"/invest"}
                >
                  <p className="group-hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                    Investor Relations
                  </p>
                </HashLink>

                <div className="group     flex min-w-[300px]  items-center justify-start gap-2  transition-all duration-75 ease-in cursor-pointer">
                  <a
                    href="https://calendly.com/salesbox-meet/partners?back=1&month=2023-10"
                    target="_blank"
                    className="flex items-center justify-center gap-2"
                    rel="noopener noreferrer"
                  >
                    <p className="group-hover:text-lime-400 transition-all duration-75 ease-in">
                      Join Partner Program
                    </p>
                    {/* <svg
											className='w-6 fill-white  group-hover:fill-lime-400 transition-all duration-75 ease-in'
											width='24'
											height='24'
											viewBox='0 0 20 19'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'>
											<path
												d='M10.2751 9.73321C12.0536 8.31217 11.9525 5.97794 10.6841 4.67803C9.39272 3.3595 7.30633 3.35018 6.01037 4.6594C4.7282 5.9593 4.61791 8.29819 6.3964 9.72389C4.31 10.3249 2.74291 11.5829 1.68133 13.4931C-0.326945 10.9492 -0.777312 6.70476 1.66754 3.39677C4.16754 0.0188883 8.72177 -0.987487 12.3431 1.04856C16.139 3.18245 17.2788 7.42227 16.3964 10.5579C15.3164 10.5253 14.3422 10.8561 13.4644 11.513C12.5499 10.6604 11.4975 10.0687 10.2751 9.73321Z'
												fill=''
											/>
											<path
												d='M12.83 12.1196C12.3383 12.6973 11.9937 13.3263 11.8098 14.0438C11.6444 14.6821 11.603 15.3298 11.7271 15.9774C11.7593 16.1451 11.7041 16.2103 11.5617 16.2663C8.35854 17.5009 5.44494 17.0024 2.81626 14.7846C2.81167 14.78 2.80707 14.7753 2.79788 14.7707C2.23722 14.2535 2.23722 14.2535 2.64163 13.5826C4.78777 10.0649 9.47986 9.28685 12.6141 11.9332C12.683 11.9845 12.7427 12.0404 12.83 12.1196Z'
												fill=''
											/>
											<path
												d='M16.259 11.4336C18.3178 11.4289 19.9998 13.1342 19.9998 15.2168C19.9998 17.2948 18.3316 18.9907 16.2819 18.9954C14.2139 19.0001 12.5319 17.3134 12.5274 15.2261C12.5228 13.1528 14.2047 11.4383 16.259 11.4336ZM15.8086 15.925C15.4731 15.6967 15.1652 15.4824 14.8527 15.2727C14.5908 15.0957 14.3334 15.1283 14.1864 15.3426C14.0393 15.5569 14.1082 15.8225 14.361 15.9996C14.7608 16.2745 15.156 16.5493 15.5558 16.8196C15.8729 17.0339 16.0614 17.0013 16.3049 16.7031C16.9529 15.8971 17.6009 15.0957 18.2489 14.2897C18.2856 14.2431 18.327 14.1918 18.3546 14.1406C18.4557 13.9542 18.4143 13.7212 18.2581 13.5908C18.0926 13.451 17.8536 13.451 17.6928 13.6001C17.6376 13.6514 17.5917 13.7119 17.5411 13.7725C16.9667 14.4853 16.3922 15.1982 15.8086 15.925Z'
												fill=''
											/>
											<path
												d='M8.32159 9.46789C6.98887 9.45857 5.90431 8.34503 5.9181 6.99854C5.92729 5.64739 7.02104 4.55715 8.35836 4.56647C9.69108 4.57578 10.7848 5.69398 10.7664 7.03581C10.7481 8.39163 9.65891 9.47721 8.32159 9.46789Z'
												fill=''
											/>
										</svg> */}
                  </a>
                </div>

                <a
                  href="https://www.linkedin.com/company/sales-mrkt/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className=""
                >
                  <span className="flex items-center min-w-[300px]  gap-1 ">
                    <h5 className=" flex   gap-2 items-center hover:text-lime-400 transition-all duration-75 ease-in cursor-pointer">
                      Find us on <img src={LinkedInLogo} alt="" />
                    </h5>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="h-full  hidden lg:flex flex-row lg:flex-col items-end justify-between gap-2   w-fit">
            <h3
              className={`${promosCliked
                ? "font-bold text-start text-lime-500 scale-110   mb-4 hover:text-lime-400 transition-all duration-150 ease-in cursor-pointer"
                : "font-bold text-start  mb-4 hover:text-lime-400 transition-all duration-150 ease-in cursor-pointer"
                }`}
            >
              Receive News & Promos
            </h3>
            <EmailInput isCliked={promosCliked} />
            <div className="flex flex-col items-end mt-[103px] w-[200px] gap-4">
              <img src={certified} className="w-[200px] " alt="" />
              <img src={BankingInfo} className="w-[240px]" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="text-white lg:-mt-[45px] mt-6  flex-col items-center justify-center  text-center">
        <h4 className="md:text-sm text-[12px] flex items-center justify-center gap-1">
          <a
            className="text-white transition-all"
            href="https://www.salesbox.io/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          &
          <a
            className="text-white transition-all"
            href="https://www.salesbox.io/terms-of-use/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </h4>
        <h5 className="md:text-sm text-[11px] text-white ">
          Copyright © 2023 Salesmrkt. All Rights Reserved.
        </h5>
      </div>
    </div>
  );
};

export default Footer;
