import { useEffect, useState } from "react";
import Loading from "../../Loading"
import Logo from '../../Assets/SVG/SalesmrktLogo.svg';
import SideIcons from '../../Assets/BOA_Assets/SideIcons.svg'
import FemaleTBD from '../../Assets/BOA_Assets/FemaleTBD.svg'
import KevinAbrams from '../../Assets/BOA_Assets/KevinAbrams.svg'
import MaleTBD from '../../Assets/BOA_Assets/MaleTBD.svg'

import RobertPorter from '../../Assets/BOA_Assets/RoberPorter.png'
import RoberPorterIcons from '../../Assets/BOA_Assets/RoberPorterIcons.svg'
import RoberPorterCompany from '../../Assets/BOA_Assets/ForbesSalsmrktPP.png'

import JohnQuellar from '../../Assets/BOA_Assets/JohnCuellar.png'
import JohnCuellarIcons from '../../Assets/BOA_Assets/JohnCuellarIcons.svg'
import JohnCuellarCompany from '../../Assets/BOA_Assets/JohnCuellarCompany.png'

import Pekka from '../../Assets/BOA_Assets/Pekka.png'
import PekkaIcons from '../../Assets/BOA_Assets/PekkaIcons.svg'
import PekkaCompany from '../../Assets/BOA_Assets/PekkaCompany.png'

import Female from '../../Assets/BOA_Assets/Female.png'
import femaleIcons from '../../Assets/BOA_Assets/FemaleIcon.svg'

import JohnCiccio from '../../Assets/BOA_Assets/JohnCiccio.png'
import JohnCiccioIcons from '../../Assets/BOA_Assets/JohnCiccioIcons.svg'
import JohnCiccioCopmany from '../../Assets/BOA_Assets/Syneos.svg'

import Bridgette from '../../Assets/BOA_Assets/Bridgette.png'
import BridgetteLogos from '../../Assets/BOA_Assets/bridgette-companies.png'

import Murray from '../../Assets/BOA_Assets/Murray.png'
import Murrayicons from '../../Assets/BOA_Assets/Murrayicons.svg'
import MurrayCompany from '../../Assets/BOA_Assets/MurrayCompany.svg'

import Charletta from '../../Assets/BOA_Assets/Charletta Jean Louis.png'
import CharlettaCompany from '../../Assets/BOA_Assets/MightyOaks.png'
import CharlettaJeanLouisIcons from '../../Assets/BOA_Assets/CharlettaJeanLouisIcons.svg'

import Mike from '../../Assets/BOA_Assets/MikeMcCarter.png'
import MikeIcons from '../../Assets/BOA_Assets/MikeIcons.svg'
import MikeCompany from '../../Assets/BOA_Assets/MikeCompany.png'
import LoginFullPagePaul from "../LoginMenu/LoginFullPagePaul";
import NavBar from "../NavBar/NavBar";
import Footer from "./Footer";


interface BoardMemberProps {
	name: string;
	position: string;
	profileImage: string;
	icon1: string;
	icon2: string;
	linkedinUrl: string;
}
const BoardOfAdvisors = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [bundles, setBundles] = useState(false);
	const [navOne, setNavOne] = useState(false);
	const [navTwo, setNavTwo] = useState(false);
	const [navThree, setNavThree] = useState(false);
	const [navFour, setNavFour] = useState(true);
	const [exploreButton, setExploreButton] = useState(false);
	const [resources, setresources] = useState(false);
	const [isIdle, setIsIdle] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [popUpHasBeenShown, setPopUpHasBeenShown] = useState(false);
	const [LoginMenuOpener, setLoginMenuOpener] = useState(false);
	const [LetsTalkOpener, setLetsTalkOpener] = useState(false);
	let idleTimer: NodeJS.Timeout;
	const [FooterSelected, setFooterSelected] = useState(18);
	useEffect(() => {
		// Simulate loading for 1 second
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 1900);

		// Clean up the timer on component unmount
		return () => clearTimeout(timer);
	}, []);

	const BoardMember: React.FC<BoardMemberProps> = ({
		name,
		position,
		profileImage,
		icon1,
		icon2,
		linkedinUrl,
	}) => {
		return (
			<div className="grid grid-rows-2 gap-1 w-full max-w-[280px] items-center justify-center">
				<div className="flex relative items-center justify-center gap-1 ">

					<img src={profileImage} alt={name} className="max-w-[155px]" />
					<a href={linkedinUrl} target="_blank" className={`  ${linkedinUrl ? '' : 'pointer-events-none'}`} rel="noopener noreferrer">
						<img src={icon1} alt="Icon 1" className="max-w-none" />
					</a>
				</div>
				<div className="mr-5 flex flex-col min-h-[120px]">
					<p className="text-xl lg:text-2xl xl:text-xl 3xl:text-2xl flex items-center justify-center font-semibold text-center mb-1">{name}</p>
					<p className="text-center text-[1vw] mb-3">{position}</p>
					{icon2 !== "" && (
						<img src={icon2} alt="Icon 2" className=" mt-auto mx-auto" />
					)}
				</div>

			</div>
		);
	};
	return (
		<div className="w-full h-full">
			<Loading isLoading={isLoading} />
			<LoginFullPagePaul
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
			/>
			<NavBar
				LetsTalkOpener={LetsTalkOpener}
				setLetsTalkOpener={setLetsTalkOpener}
				Four={navFour}
				setNumber={setFooterSelected}
				setFour={setNavFour}
				budnles={bundles}
				setBundles={setBundles}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
				One={navOne}
				Two={navTwo}
				Three={navThree}
				setExploreButton={setExploreButton}
				recources={resources}
				setRecources={setresources}
				isOpen={isOpen}
				setOpen={setOpen}
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
			/>
			<div className="w-full h-full flex flex-col gap-10 items-center justify-center p-4 lg:p-0 lg:pr-16 lg:pl-16 mt-[100px] relative ">
				<div className="flex flex-col items-center justify-center  w-full h-full min-h-[85vh]">
					<h2 className="text-black text-5xl xl:text-6xl mb-[65px] text-center   font-semibold">
						Board of Advisors
					</h2>
					<div className="w-full h-full flex items-center justify-center lg:justify-between gap-6">
						<img src={SideIcons} className="hidden lg:flex" alt="" />
						<div className="flex flex-col gap-10 xl:gap-14 2xl:gap-14 max-w-[1000px]">
							{/* <BoardMember profileImage={RobertPorter} name="Robert Porter" position="Interim CFO / Investor" icon1={RoberPorterIcons} icon2={RoberPorterCompany} linkedinUrl="https://www.linkedin.com/in/robert-porter-75b204121/"/> */}
							<div className="flex xl:flex-row flex-col gap-10 xl:gap-14 2xl:gap-14 justify-center items-center">
								<div className="flex flex-row xl:gap-14 2xl:gap-14">
									<BoardMember profileImage={Charletta} name="Charletta Jean Louis" position="Advisor" icon1={CharlettaJeanLouisIcons} icon2={CharlettaCompany} linkedinUrl="https://www.linkedin.com/in/charletta-jean-louis-30022115/" />
									<BoardMember profileImage={Pekka} name="Pekka Huttunen" position="Client Service Partner" icon1={PekkaIcons} icon2={PekkaCompany} linkedinUrl="https://www.linkedin.com/in/pekka-huttunen/" />
								</div>
								<div className="flex flex-row xl:gap-14 2xl:gap-14">
									<BoardMember profileImage={Bridgette} name="Bridgette L. Smith" position="Executive Chairman, CEO & Founder" icon1={JohnCuellarIcons} icon2={BridgetteLogos} linkedinUrl="https://www.linkedin.com/in/bridgette-l-smith-%F0%9F%A6%8B-5b902b92/overlay/about-this-profile/" />
									<BoardMember profileImage={JohnQuellar} name="John Cuellar" position="CEO & Founder" icon1={JohnCuellarIcons} icon2={JohnCuellarCompany} linkedinUrl="https://www.linkedin.com/in/cuellar/" />
								</div>
							</div>
							<div className="flex xl:flex-row flex-col gap-10 xl:gap-14 2xl:gap-14 justify-center items-center">
								<div className="flex flex-row xl:gap-14 2xl:gap-14">
									<BoardMember profileImage={JohnCiccio} name="John Ciccio" position="Senior Vice President" icon1={JohnCiccioIcons} icon2={JohnCiccioCopmany} linkedinUrl="https://www.linkedin.com/in/john-ciccio/" />
									<BoardMember profileImage={Murray} name="Murray Warner" position="Senior Vice President" icon1={Murrayicons} icon2={MurrayCompany} linkedinUrl="https://www.linkedin.com/in/murrayjaywarner/" />
								</div>
								<div className="flex flex-row xl:gap-14 2xl:gap-14">
									<BoardMember profileImage={Mike} name="Mike McCarter" position="Principal GPM" icon1={MikeIcons} icon2={MikeCompany} linkedinUrl="https://www.linkedin.com/in/mrmccarter/" />
								</div>
							</div>
						</div>
						<div className="flex-col items-start  justify-center gap-6 hidden  lg:flex">
							<img src={FemaleTBD} className="max-w-[70px]" alt="" />
							<a href="https://www.linkedin.com/in/kevin-abrams-55b991228/" className="flex ml-[-5px] max-w-[75px] items-center justify-center" target="_blank" rel="noopener noreferrer">
								<img src={KevinAbrams} className="" alt="" />
							</a>
							<img src={FemaleTBD} alt="" className="max-w-[70px]" />
							<img src={MaleTBD} alt="" className="max-w-[70px]" />

						</div>

					</div>

				</div>
				<div className='w-full overflow-visible  items-center mb-10     justify-between hidden lg:flex  '>
					<div>
						<div>
							<div className='border border-lime-500 rounded-r-md pl-8 pr-8 font-semibold '>
								Mentorship
							</div>
						</div>
					</div>
					<div>
						<img
							src={Logo}
							className='max-w-[150px]'
							alt=''
						/>
					</div>
				</div>
			</div>
			<Footer
				LetsTalkOpener={LetsTalkOpener}
				setLetsTalkOpener={setLetsTalkOpener}
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
				Four={navFour}
				setFour={setNavFour}
				numberId={FooterSelected}
				setNumber={setFooterSelected}
				recources={resources}
				budnles={bundles}
				setBundles={setBundles}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
				One={navOne}
				Two={navTwo}
				Three={navThree}
				setExploreButton={setExploreButton}
				setRecources={setresources}
			/>
		</div>
	)
}

export default BoardOfAdvisors