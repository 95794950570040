import React, { useState, useRef, useEffect } from 'react';
import Sales from '../../Assets/Cards/Sales.png';
import Sales_small from '../../Assets/Cards/Group 26963.png';
import video from '../../Assets/Cards/Video.png';
import video_small from '../../Assets/Cards/Video_small.png';
import copy from '../../Assets/Cards/Copy.png';
import copy_small from '../../Assets/Cards/Copy_small.png';
import business from '../../Assets/Cards/Buisness.png';
import business_small from '../../Assets/Cards/Buisness_small.png';
import web from '../../Assets/Cards/Web&Tech.png';
import web_small from '../../Assets/Cards/Web&Tech_small.png';
import team from '../../Assets/Cards/TeamBuild.png';
import team_small from '../../Assets/Cards/TeamBuild_small.png';
import brand from '../../Assets/Cards/Braind.png';
import brand_small from '../../Assets/Cards/Braind_small.png';
import marketing from '../../Assets/Cards/Marketing.png';
import marketing_small from '../../Assets/Cards/Marketing_small.png';
import SideScroller from '../SideScroller/SideScroller';
import DivComponent from '../SideScroller/DivComponent';
import SideScrollerAdsOnDemand from '../SideScroller/SideScrollerAdsOnDemand';
import SideScrollerAdsOnDemandTester from '../SideScroller/SideScrollerAdsOnDemandTester';
interface UnderLandingProps {
	numberId: number;
	setNumber: React.Dispatch<React.SetStateAction<any>>;
}
const UnderLandingAdsOnDemandTester: React.FC<UnderLandingProps> = ({
	numberId,
	setNumber,
}) => {
	const [scrolled, setScrolled] = useState(false);
	return (
		<div
			id='Solutions'
			className='h-full pt-[80px] -mt-[80px]    w-full flex flex-col items-center justify-center  '>
			<h2 className='text-black mt-9 text-2xl text-center  xl:text-4xl -mb-3'>
				Enterprise-Grade Sales & Marketing Solutions
			</h2>

			<div className='w-full h-full hidden mb-20 lg:flex    '>
				<SideScrollerAdsOnDemandTester
					scrollNumber={numberId}
					scrolled={scrolled}
					setScrolled={setScrolled}
				/>
			</div>

			<div className='w-full h-full'></div>

			<div className='w-full lg:hidden  mb-6 mt-6 p-6 grid gap-3 grid-cols-2'>
				<DivComponent
					id='Sales-&-Strategy'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Sales & Strategy'
					textHidden='Get expert insights on your sales strategy & '
					textHiddenSecondLine='operations to supercharge your selling power.'
					image={Sales}
					icon={Sales_small}
				/>

				<DivComponent
					id='Business-Development'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Business Development'
					textHidden='Watch your business scale with our long list of market'
					textHiddenSecondLine='analysis, lead generation & prospecting services.'
					image={business}
					icon={business_small}
				/>

				<DivComponent
					id='Brand-&-Creative'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Brand & Creative'
					textHidden='Whether you’re developing a new brand or revamping'
					textHiddenSecondLine='an existing venture, we’ve got you covered.'
					image={brand}
					icon={brand_small}
				/>

				<DivComponent
					id='Digital-Marketing'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Digital Marketing'
					textHidden='Boost your online visibility with proven solutions '
					textHiddenSecondLine='to get your website, ads & socials noticed.'
					image={marketing}
					icon={marketing_small}
				/>

				<DivComponent
					id='Copy-&-Content'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Copy & Content'
					textHidden='Your comprehensive set of copywriting and content '
					textHiddenSecondLine='services for websites, socials, campaigns & more.'
					image={copy}
					icon={copy_small}
				/>

				<DivComponent
					id='Video-Production'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Video Production'
					textHidden='Leverage the most engaging content available to  '
					textHiddenSecondLine='tell your story, promote your business & drive sales.'
					image={video}
					icon={video_small}
				/>

				<DivComponent
					id='Web-&-Tech'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Web & Tech'
					textHidden='From website & app development to tech platforms'
					textHiddenSecondLine='& solutions, we have the skills to suit your needs.'
					image={web}
					icon={web_small}
				/>

				<DivComponent
					id='Build-My-Team'
					setScrolled={setScrolled}
					scrolled={scrolled}
					text='Build My Team'
					textHidden='Get professional support for team development, whether '
					textHiddenSecondLine='you are starting afresh or extending your current workforce.'
					image={team}
					icon={team_small}
				/>
			</div>
			{/* <Main /> */}
		</div>
	);
};

export default UnderLandingAdsOnDemandTester;
