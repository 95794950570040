import React from "react";

interface YouTubeEmbedProps {
  videoId: string;
  onClose: () => void;
}

const YouTubeEmbed: React.FC<YouTubeEmbedProps> = ({ videoId, onClose }) => {
  const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="overlay z-[999999]">
      <div className="video-container">
        <button
          className="absolute text-white/90 bg-black/90 rounded-lg w-8 h-8  text-2xl bottom-[-40px] z-[99999] hover:text-gray-500 hover:scale-90 transition-all duration-300 ease-in right-[0%]"
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
        >
          X
        </button>
        <iframe
          width="780"
          height="439"
          className="hidden lg:flex"
          src={embedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <iframe
          width="340"
          height="192"
          className="flex lg:hidden"
          src={embedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
        <iframe
          className="flex z-[999999] aspect-video md:hidden"
          width="370px"
          src={embedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </div>
  );
};

export default YouTubeEmbed;
