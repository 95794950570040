import { memo, useEffect } from 'react';
import MarqueeLib from 'react-fast-marquee';

interface MarqueeProps {
	items: any[];
	speed: number;
}

const Marquee: React.FC<MarqueeProps> = ({ items, speed = 40 }) => {
	return (
		<MarqueeLib
			autoFill
			speed={speed}>
			{items.map((item, index) => {
				return (
					<div
						key={index}
						className='w-20 h-20 border  p-3 flex items-center justify-center bg-white overflow-hidden  rounded-lg  mx-2 '>
						{item}
					</div>
				);
			})}
		</MarqueeLib>
	);
};

export default memo(Marquee);
