import React, { useState, useEffect, useRef } from 'react';
import './SideScroller.css';
import Sales from '../../Assets/Cards/Sales.png';
import Sales_small from '../../Assets/Cards/Group 26963.png';
import VideoLive from '../../Assets/Video/Video Production 3.0.mp4';
import video_small from '../../Assets/Cards/Video_small.png';
import copy from '../../Assets/Cards/Copy.png';
import copy_small from '../../Assets/Cards/Copy_small.png';
import business from '../../Assets/Cards/Buisness.png';
import business_small from '../../Assets/Cards/Buisness_small.png';
import web from '../../Assets/Cards/Web&Tech.png';
import web_small from '../../Assets/Cards/Web&Tech_small.png';
import team from '../../Assets/Cards/TeamBuild.png';
import team_small from '../../Assets/Cards/TeamBuild_small.png';
import brand from '../../Assets/Cards/Braind.png';
import brand_small from '../../Assets/Cards/Braind_small.png';
import BlankImage_Back from '../../Assets/Cards/AdsOnDemandLatest.svg';
import marketing from '../../Assets/Cards/Marketing.png';
import marketing_small from '../../Assets/Cards/Marketing_small.png';
import BuildMyBundle from '../../Assets/Cards/Group 27078.png';
import BuildMyBundle_small from '../../Assets/Cards/Group 27014.png';
import BlankImage from '../../Assets/Video/Ads OnDemand (New) 4 (1).mp4';
import DivComponent from './DivComponent';
import VideProductionPlusIndex from './VideProductionPlusIndex';
import VideoComponentAdsOnDemand from './VideoComponentAdsOnDemand';
import VideoComponentAdsOnDemandTester from './VideoComponentAdsOnDemandTester';
const SideScrollerAdsOnDemandTester: React.FC<{
	scrolled: boolean;
	scrollNumber: number;
	setScrolled: (value: boolean) => void;
}> = ({ setScrolled, scrolled, scrollNumber }) => {
	const [selectedComponentIndex, setSelectedComponentIndex] = useState(20);
	const [carouselWidth, setCarouselWidth] = useState(0);
	const sideScrollerRef = useRef<HTMLDivElement>(null);
	const clonedComponents = [
		<DivComponent
			id='Sales-&-Strategy'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Sales & Strategy'
			textHidden='Get expert insights on your sales strategy & '
			textHiddenSecondLine='operations to supercharge your selling power.'
			image={Sales}
			icon={Sales_small}
		/>,
		<DivComponent
			id='Business-Development'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Business Development'
			textHidden='Watch your business scale with our long list of market'
			textHiddenSecondLine='analysis, lead generation & prospecting services.'
			image={business}
			icon={business_small}
		/>,
		<DivComponent
			id='Brand-&-Creative'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Brand & Creative'
			textHidden='Whether you’re developing a new brand or revamping'
			textHiddenSecondLine='an existing venture, we’ve got you covered.'
			image={brand}
			icon={brand_small}
		/>,
		<DivComponent
			id='Digital-Marketing'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Digital Marketing'
			textHidden='Boost your online visibility with proven solutions '
			textHiddenSecondLine='to get your website, ads & socials noticed.'
			image={marketing}
			icon={marketing_small}
		/>,
		<DivComponent
			id='Copy-&-Content'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Copy & Content'
			textHidden='Your comprehensive set of copywriting and content '
			textHiddenSecondLine='services for websites, socials, campaigns & more.'
			image={copy}
			icon={copy_small}
		/>,
		<VideProductionPlusIndex
			myindex={5}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Video Production'
			textHidden='Leverage the most engaging content available to  '
			textHiddenSecond='tell your story, promote your business & drive sales.'
			image={VideoLive}
			icon={video_small}
		/>,
		<DivComponent
			id='Web-&-Tech'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Web & Tech'
			textHidden='From website & app development to tech platforms'
			textHiddenSecondLine='& solutions, we have the skills to suit your needs.'
			image={web}
			icon={web_small}
		/>,
		<DivComponent
			id='Build-My-Team'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Build My Team'
			textHidden='Get professional support for team development, whether '
			textHiddenSecondLine='you are starting afresh or extending your current workforce.'
			image={team}
			icon={team_small}
		/>,
		<DivComponent
			id='Customize-My-Solution'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Customize My Solution'
			textHidden='All our solutions are designed to be tailored to your needs,  '
			textHiddenSecondLine='work with your resources and integrate with your team.'
			image={BuildMyBundle}
			icon={BuildMyBundle_small}
		/>,
		<VideoComponentAdsOnDemandTester
			myindex={1}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text=''
			textHidden=''
			image={BlankImage}
			icon={BlankImage_Back}
		/>,
		<DivComponent
			id='Sales-&-Strategy'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Sales & Strategy'
			textHidden='Get expert insights on your sales strategy & '
			textHiddenSecondLine='operations to supercharge your selling power.'
			image={Sales}
			icon={Sales_small}
		/>,
		<DivComponent
			id='Business-Development'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Business Development'
			textHidden='Watch your business scale with our long list of market'
			textHiddenSecondLine='analysis, lead generation & prospecting services.'
			image={business}
			icon={business_small}
		/>,
		<DivComponent
			id='Brand-&-Creative'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Brand & Creative'
			textHidden='Whether you’re developing a new brand or revamping'
			textHiddenSecondLine='an existing venture, we’ve got you covered.'
			image={brand}
			icon={brand_small}
		/>,
		<DivComponent
			id='Digital-Marketing'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Digital Marketing'
			textHidden='Boost your online visibility with proven solutions '
			textHiddenSecondLine='to get your website, ads & socials noticed.'
			image={marketing}
			icon={marketing_small}
		/>,
		<DivComponent
			id='Copy-&-Content'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Copy & Content'
			textHidden='Your comprehensive set of copywriting and content '
			textHiddenSecondLine='services for websites, socials, campaigns & more.'
			image={copy}
			icon={copy_small}
		/>,
		<VideProductionPlusIndex
			myindex={5}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Video Production'
			textHidden='Leverage the most engaging content available to  '
			textHiddenSecond='tell your story, promote your business & drive sales.'
			image={VideoLive}
			icon={video_small}
		/>,
		<DivComponent
			id='Web-&-Tech'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Web & Tech'
			textHidden='From website & app development to tech platforms'
			textHiddenSecondLine='& solutions, we have the skills to suit your needs.'
			image={web}
			icon={web_small}
		/>,
		<DivComponent
			id='Build-My-Team'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Build My Team'
			textHidden='Get professional support for team development, whether '
			textHiddenSecondLine='you are starting afresh or extending your current workforce.'
			image={team}
			icon={team_small}
		/>,
		<DivComponent
			id='Customize-My-Solution'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Customize My Solution'
			textHidden='All our solutions are designed to be tailored to your needs,  '
			textHiddenSecondLine='work with your resources and integrate with your team.'
			image={BuildMyBundle}
			icon={BuildMyBundle_small}
		/>,

		<VideoComponentAdsOnDemandTester
			myindex={1}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text=''
			textHidden=''
			image={BlankImage}
			icon={BlankImage_Back}
		/>,
		<DivComponent
			id='Sales-&-Strategy'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Sales & Strategy'
			textHidden='Get expert insights on your sales strategy & '
			textHiddenSecondLine='operations to supercharge your selling power.'
			image={Sales}
			icon={Sales_small}
		/>,
		<DivComponent
			id='Business-Development'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Business Development'
			textHidden='Watch your business scale with our long list of market'
			textHiddenSecondLine='analysis, lead generation & prospecting services.'
			image={business}
			icon={business_small}
		/>,
		<DivComponent
			id='Brand-&-Creative'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Brand & Creative'
			textHidden='Whether you’re developing a new brand or revamping'
			textHiddenSecondLine='an existing venture, we’ve got you covered.'
			image={brand}
			icon={brand_small}
		/>,
		<DivComponent
			id='Digital-Marketing'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Digital Marketing'
			textHidden='Boost your online visibility with proven solutions '
			textHiddenSecondLine='to get your website, ads & socials noticed.'
			image={marketing}
			icon={marketing_small}
		/>,
		<DivComponent
			id='Copy-&-Content'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Copy & Content'
			textHidden='Your comprehensive set of copywriting and content '
			textHiddenSecondLine='services for websites, socials, campaigns & more.'
			image={copy}
			icon={copy_small}
		/>,
		<VideProductionPlusIndex
			myindex={5}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Video Production'
			textHidden='Leverage the most engaging content available to  '
			textHiddenSecond='tell your story, promote your business & drive sales.'
			image={VideoLive}
			icon={video_small}
		/>,
		<DivComponent
			id='Web-&-Tech'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Web & Tech'
			textHidden='From website & app development to tech platforms'
			textHiddenSecondLine='& solutions, we have the skills to suit your needs.'
			image={web}
			icon={web_small}
		/>,
		<DivComponent
			id='Build-My-Team'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Build My Team'
			textHidden='Get professional support for team development, whether '
			textHiddenSecondLine='you are starting afresh or extending your current workforce.'
			image={team}
			icon={team_small}
		/>,
		<DivComponent
			id='Customize-My-Solution'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Customize My Solution'
			textHidden='All our solutions are designed to be tailored to your needs,  '
			textHiddenSecondLine='work with your resources and integrate with your team.'
			image={BuildMyBundle}
			icon={BuildMyBundle_small}
		/>,

		<VideoComponentAdsOnDemand
			myindex={1}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text=''
			textHidden=''
			image={BlankImage}
			icon={BlankImage_Back}
		/>,

		<DivComponent
			id='Sales-&-Strategy'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Sales & Strategy'
			textHidden='Get expert insights on your sales strategy & '
			textHiddenSecondLine='operations to supercharge your selling power.'
			image={Sales}
			icon={Sales_small}
		/>,
		<DivComponent
			id='Business-Development'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Business Development'
			textHidden='Watch your business scale with our long list of market'
			textHiddenSecondLine='analysis, lead generation & prospecting services.'
			image={business}
			icon={business_small}
		/>,
		<DivComponent
			id='Brand-&-Creative'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Brand & Creative'
			textHidden='Whether you’re developing a new brand or revamping'
			textHiddenSecondLine='an existing venture, we’ve got you covered.'
			image={brand}
			icon={brand_small}
		/>,
		<DivComponent
			id='Digital-Marketing'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Digital Marketing'
			textHidden='Boost your online visibility with proven solutions '
			textHiddenSecondLine='to get your website, ads & socials noticed.'
			image={marketing}
			icon={marketing_small}
		/>,
		<DivComponent
			id='Copy-&-Content'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Copy & Content'
			textHidden='Your comprehensive set of copywriting and content '
			textHiddenSecondLine='services for websites, socials, campaigns & more.'
			image={copy}
			icon={copy_small}
		/>,
		<VideProductionPlusIndex
			myindex={5}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Video Production'
			textHidden='Leverage the most engaging content available to  '
			textHiddenSecond='tell your story, promote your business & drive sales.'
			image={VideoLive}
			icon={video_small}
		/>,
		<DivComponent
			id='Web-&-Tech'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Web & Tech'
			textHidden='From website & app development to tech platforms'
			textHiddenSecondLine='& solutions, we have the skills to suit your needs.'
			image={web}
			icon={web_small}
		/>,
		<DivComponent
			id='Build-My-Team'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Build My Team'
			textHidden='Get professional support for team development, whether '
			textHiddenSecondLine='you are starting afresh or extending your current workforce.'
			image={team}
			icon={team_small}
		/>,
		<DivComponent
			id='Customize-My-Solution'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Customize My Solution'
			textHidden='All our solutions are designed to be tailored to your needs,  '
			textHiddenSecondLine='work with your resources and integrate with your team.'
			image={BuildMyBundle}
			icon={BuildMyBundle_small}
		/>,

		<VideoComponentAdsOnDemand
			myindex={1}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text=''
			textHidden=''
			image={BlankImage}
			icon={BlankImage_Back}
		/>,

		<DivComponent
			id='Sales-&-Strategy'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Sales & Strategy'
			textHidden='Get expert insights on your sales strategy & '
			textHiddenSecondLine='operations to supercharge your selling power.'
			image={Sales}
			icon={Sales_small}
		/>,
		<DivComponent
			id='Business-Development'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Business Development'
			textHidden='Watch your business scale with our long list of market'
			textHiddenSecondLine='analysis, lead generation & prospecting services.'
			image={business}
			icon={business_small}
		/>,
		<DivComponent
			id='Brand-&-Creative'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Brand & Creative'
			textHidden='Whether you’re developing a new brand or revamping'
			textHiddenSecondLine='an existing venture, we’ve got you covered.'
			image={brand}
			icon={brand_small}
		/>,
		<DivComponent
			id='Digital-Marketing'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Digital Marketing'
			textHidden='Boost your online visibility with proven solutions '
			textHiddenSecondLine='to get your website, ads & socials noticed.'
			image={marketing}
			icon={marketing_small}
		/>,
		<DivComponent
			id='Copy-&-Content'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Copy & Content'
			textHidden='Your comprehensive set of copywriting and content '
			textHiddenSecondLine='services for websites, socials, campaigns & more.'
			image={copy}
			icon={copy_small}
		/>,
		<VideProductionPlusIndex
			myindex={5}
			index={selectedComponentIndex}
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Video Production'
			textHidden='Leverage the most engaging content available to  '
			textHiddenSecond='tell your story, promote your business & drive sales.'
			image={VideoLive}
			icon={video_small}
		/>,
		<DivComponent
			id='Web-&-Tech'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Web & Tech'
			textHidden='From website & app development to tech platforms'
			textHiddenSecondLine='& solutions, we have the skills to suit your needs.'
			image={web}
			icon={web_small}
		/>,
		<DivComponent
			id='Build-My-Team'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Build My Team'
			textHidden='Get professional support for team development, whether '
			textHiddenSecondLine='you are starting afresh or extending your current workforce.'
			image={team}
			icon={team_small}
		/>,
		<DivComponent
			id='Customize-My-Solution'
			setScrolled={setScrolled}
			scrolled={scrolled}
			text='Customize My Solution'
			textHidden='All our solutions are designed to be tailored to your needs,  '
			textHiddenSecondLine='work with your resources and integrate with your team.'
			image={BuildMyBundle}
			icon={BuildMyBundle_small}
		/>,
	];

	const handlePrevious = () => {
		setScrolled(true);

		setSelectedComponentIndex((prevIndex) =>
			prevIndex === 0 ? clonedComponents.length - 1 : prevIndex - 1
		);
	};

	const handleNext = () => {
		setScrolled(true);

		setSelectedComponentIndex((prevIndex) =>
			prevIndex === clonedComponents.length - 1 ? 0 : prevIndex + 1
		);
	};

	const handleSlideToIndex = (index: number) => {
		setScrolled(true);
		setSelectedComponentIndex(index);
	};
	useEffect(() => {
		const handleArrowKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'ArrowLeft') {
				handlePrevious();
			} else if (event.key === 'ArrowRight') {
				handleNext();
			}
		};

		document.addEventListener('keydown', handleArrowKeyPress);

		return () => {
			document.removeEventListener('keydown', handleArrowKeyPress);
		};
	}, []);

	useEffect(() => {
		const updateCarouselWidth = () => {
			const carouselWrapper = document.querySelector(
				'.carousel'
			) as HTMLElement;
			const carouselWrapperWidth = carouselWrapper?.offsetWidth || 0;
			setCarouselWidth(carouselWrapperWidth);
		};

		// Update the carousel width when the component mounts and on window resize
		updateCarouselWidth();
		window.addEventListener('resize', updateCarouselWidth);

		return () => {
			window.removeEventListener('resize', updateCarouselWidth);
		};
	}, []);
	useEffect(() => {
		handleSlideToIndex(scrollNumber);
	}, [scrollNumber]);

	useEffect(() => {
		const calculateNumItemsToShow = () => {
			const centerItemWidth = 600; // Width of the center item
			const minItemWidth = 300; // Minimum width of other items
			const scaleFactor = 0.1; // Scale factor for item width calculation
			const availableWidth = carouselWidth - centerItemWidth; // Available width for other items
			const numItems = 4; // Calculate the number of items to be shown

			const itemWidth = availableWidth / (numItems - 1); // Calculate the width of other items

			return { numItems, itemWidth, scaleFactor };
		};

		const { numItems, itemWidth, scaleFactor } = calculateNumItemsToShow();
		const translateX = -selectedComponentIndex * itemWidth;

		clonedComponents.forEach((_, index) => {
			const offsetX = translateX + index * itemWidth;
			const distanceFromCenter = Math.abs(selectedComponentIndex - index);
			const scale = 1 - scaleFactor * distanceFromCenter;
			const zIndex = numItems - distanceFromCenter;
			const carouselItem = document.getElementById(
				`carousel-item-${index}`
			) as HTMLElement;
			if (carouselItem) {
				carouselItem.style.transform = `translateX(${offsetX}px) scale(${scale})`;
				carouselItem.style.zIndex = zIndex.toString();
				carouselItem.style.transition = 'all 0.5s ease-in-out';
			}
		});
	}, [selectedComponentIndex, clonedComponents, carouselWidth]);

	return (
		<div
			className='carousel-wrapper relative '
			ref={sideScrollerRef}>
			<button
				className='arrow left-arrow  rounded-full p-2'
				onClick={handlePrevious}>
				<svg
					width='25'
					height='25'
					viewBox='0 0 11 12'
					fill='#97CC3C'
					className='rotate-180 hover:scale-110 transition-all'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z'
						fill=''
					/>
				</svg>
			</button>
			<button
				className='arrow right-arrow'
				onClick={handleNext}>
				<svg
					width='25'
					height='25'
					viewBox='0 0 11 12'
					fill='#97CC3C'
					className=' hover:scale-110 transition-all'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z'
						fill=''
					/>
				</svg>
			</button>
			<div className='flex items-center  absolute z-50 bottom-[-30px]    justify-center'>
				<a
					href='https://calendly.com/salesbox-meet/ads-ondemand-campaign'
					target='_blank'
					className='opacity-100 w-[650px] transition-opacity duration-500 ease-in'
					rel='noopener noreferrer'>
					<p className='  p-2  rounded  text-center hover:text-white font-semibold border-lime-500 border   overflow-hidden group   hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 hover:ring-2 hover:ring-offset-2 hover:ring-lime-400  pl-6 pr-6 pt-2 pb-2  text-black  transition-all duration-200 ease-in  flex items-center justify-center gap-1 group lg:left-[170px] top-[78%] md:top-[85%] text-base md:text-lg left-[10px]   lg:top-[58%]     '>
						Schedule Free Consultation
					</p>
					<p className=' text-center mt-1   text-xs     '>
						$500 OFF your first Purchase
					</p>
				</a>
			</div>
			<div className='carousel'>
				{clonedComponents.map((component, index) => (
					<div
						key={index}
						id={`carousel-item-${index}`}
						className={`carousel-item ${
							index === selectedComponentIndex
								? 'focused largeImage'
								: 'notFocused smallImage'
						}`}
						style={{
							zIndex:
								index === selectedComponentIndex
									? clonedComponents.length
									: clonedComponents.length - index,
						}}
						onClick={() => {
							handleSlideToIndex(index);
						}} // Added onClick handler
					>
						{component}
					</div>
				))}
			</div>
		</div>
	);
};

export default SideScrollerAdsOnDemandTester;
