import React, { useState, useRef, useEffect } from "react";
import bgVideo from "../../Assets/Video/HomePageBGVIDEO_Comp.mp4";
import IntroVideo from "../../Assets/Video/Salesmrkt - Welcome to the Future of Sales, Marketing & Tech (1).mp4";
import TextAnimation from "../TextAnimation/TextAnimation";
import bgImage from "../../Assets/img/Marketplace home image (4).jpg";
import useScreen from "../../hooks/useScreen";
import VideoEmbed from "../YoutubeEmbed/VideoEmbed";
interface LandingProps {
  clickedState: boolean;
  stateSetter: any;
  loginMenu: boolean;
  setLoginMenu: any;
}
const LandingPage: React.FC<LandingProps> = ({
  clickedState,
  loginMenu,
  setLoginMenu,
}) => {
  const linkRef = useRef<HTMLInputElement>(null);
  const [showOverlay, setShowOverlay] = useState(false);
  // const [city, setCity] = useState('Seattle');
  // const [date, setDate] = useState('');
  // const [monthDate, setMonthDate] = useState('');
  // const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  const screen = useScreen();
  // state to conditionaly render an image on mobile and video on 700px plus sizes
  const current = new Date();
  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[current.getMonth()]; // Get the month name

    // setDate(
    // 	`${current.toLocaleTimeString([], {
    // 		hour12: true,
    // 		hour: 'numeric',
    // 		minute: 'numeric',
    // 	})}`
    // );
    // setMonthDate(`${month} ${current.getDate()}`);
  }, [current]);
  const handleButtonClick = () => {
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
  useEffect(() => {
    if (clickedState && linkRef.current) {
      linkRef.current.focus();
    }
  }, [clickedState]);

  return (
    <div
      className="w-full h-[calc(80vh-80px)] md:h-[calc(100vh-80px)] mt-[80px] overflow-hidden relative  min-h-[450px] z-0"
      id="landingPage"
    >
      {(screen?.width ?? 768) >= 768 ? (
        <>
          <div className="bg-gradient-to-r from-black/100 right-60 via-transparent   absolute  z-20 w-full h-[100%]   to-transparent"></div>
          <div className="bg-gradient-to-r from-black/100 right-64 via-transparent   absolute  z-20 w-full h-[100%]   to-transparent"></div>
          <div className="bg-gradient-to-r from-black/60  via-black/20 to-black/0   absolute right-0  z-20 w-full h-[100%]   "></div>
        </>
      ) : (
        <>
          <div className="bg-gradient-to-r from-black/20 right-0 via-black/10   absolute  z-20 w-full h-[100%]    to-transparent"></div>
          {/* <div className='bg-gradient-to-r from-black/100 right-64 via-transparent   absolute  z-20 w-full h-[100%]    to-transparent'></div> */}
          <div className="bg-gradient-to-b from-black/0  via-black/20 to-black/80   absolute right-0  z-20 w-full h-[100%]    "></div>
        </>
      )}

      {(screen?.width ?? 768) >= 768 ? (
        <video
          loop
          autoPlay
          muted
          playsInline
          disablePictureInPicture
          preload={"auto"}
          className="h-full  w-[calc(100%)] overflow-hidden object-cover absolute z-0"
          src={bgVideo}
        ></video>
      ) : (
        <img
          src={bgImage}
          alt="bg"
          loading="eager"
          className=" h-full   flex  w-full overflow-hidden object-cover left-0 absolute z-0"
        />
      )}

      <div className=" w-full h-[100%]  flex flex-col justify-end     md:-mt-16 lg:flex-row items-center md:justify-center cursor-pointer relative z-50  lg:justify-between  p-6 lg:gap-0 ">
        <div
          onClick={() => setShowOverlay(true)}
          className="lg:w-[80%]   w-full  lg:bg-transparent  lg:h-full md:flex lg:items-center items-start justify-center lg:justify-start lg:p-8"
        >
          <div className="  cursor-default   sm:bg-transparent  flex flex-col bg-gradient-to-r gap-1     lg:p-3  rounded-[20px]  ">
            <h2 className="drop-shadow-lg flex  textshadow  text-[25px] md:text-[30px] lg:text-[36px] 2xl:text-[49px]  shadow-black ">
              All-in-One Marketplace
            </h2>
            <div className="relative space-y-4">
              <h1 className="w-full flex   text-[40px] md:text-[58px] lg:text-[64px] xl:text-[72px] 2xl:text-[114px]   drop-shadow-lg  textshadow shadow-black">
                Sales, Marketing
              </h1>
              <h1 className="w-full flex   text-[40px] md:text-[58px] lg:text-[64px] xl:text-[72px] 2xl:text-[114px]   drop-shadow-lg  textshadow shadow-black">
                & Tech
              </h1>
              <TextAnimation />
            </div>
            <div
              onClick={handleButtonClick}
              className="lg:absolute mt-2   bottom-[0px] cursor-pointer group flex   gap-3 items-center justify-center w-max"
            >
              <div className=" relative transition-all ease-in-out duration-300 ">
                <svg
                  width="72"
                  height="72"
                  viewBox="0 0 72 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="lg:w-20 lg:h-20 w-12 h-12"
                >
                  <path
                    className="group-hover:drawn group-hover:stroke-lime-500  "
                    d="M28.3666 35.4769C28.3666 38.8059 28.3666 42.1349 28.3666 45.464C28.3666 47.3134 29.3969 47.8683 31.036 46.9435C34.8761 44.7704 38.7162 42.5973 42.5563 40.3779C44.6168 39.222 46.6774 38.0661 48.6911 36.864C50.0492 36.078 50.0492 34.8758 48.6911 34.0436C48.5974 33.9973 48.4569 33.9048 48.3633 33.8586C42.6031 30.5758 36.7961 27.293 31.036 24.0102C29.3969 23.0855 28.4134 23.6404 28.3666 25.4898C28.3666 28.8651 28.3666 32.1941 28.3666 35.4769Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                  />
                  <circle
                    className=" group-hover:stroke-lime-500  "
                    cx="36"
                    cy="36"
                    r="32.5"
                    stroke="white"
                    strokeWidth="3"
                  />
                </svg>
              </div>
              {showOverlay && (
                <div>
                  {
                    <VideoEmbed
                      videoPath={IntroVideo}
                      onClose={handleCloseOverlay}
                    />
                  }
                </div>
              )}
              <h4 className="opacity-100 relative   group-hover:text-lime-500 transition-all ease-in-out duration-300  hashTag textshadow font-semibold mt-1 text-2xl text-white drop-shadow-lg shadow-black">
                Play
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
