import { FacebookIcon, InstagramIcon, LinkedInIcon, YoutubeIcon } from "Components/Icons";

const CalendlyThankYou = () => {

  return (
    <div id="home" className="h-full w-full ">
      <section className="bg-black w-full h-20 border-b border-b-gray-300 border-opacity-20 flex items-center justify-center">
        <img src={require('Assets/logo.png')} alt="Salesmrkt" height={30} width={160} />
      </section>

      <section className="bg-[#D8EFFF] text-black flex flex-col h-screen w-full justify-center items-center py-[3%] px-[3%]">
        <div className="text-[45px] font-bold mb-10 text-center sm:text-left">
          Thank You for Booking a Call.
        </div>
        <div className="mb-16 text-center sm:text-left">
          You will receive a confirmation email shortly with all the details.
        </div>
        <div className="flex flex-col max-w-[700px]">
            <img src={require('Assets/homev2/hero-image.png')} alt="" className="mx-10"/>
            <div className="bg-black w-full rounded-full py-5 flex flex-row items-center justify-center gap-10">
              <div className="uppercase tracking-widest text-white text-[20px]">
                Find us on
              </div>
            <div className="flex items-center gap-2 sm:gap-5 text-white">
              <a
                href="https://www.linkedin.com/company/sales-mrkt/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://www.youtube.com/@salesmrkt"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeIcon />
              </a>
              <a
                href="https://www.instagram.com/salesmrkt_/"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                href="https://www.facebook.com/Salesmrkt.B2B.Marketplace"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>

            </div>
            </div>
        </div>
      </section>
    </div>
  );
};

export default CalendlyThankYou;
