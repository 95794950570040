import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Pages/Home";
import { HelmetProvider } from "react-helmet-async";
import Loading_Lazy from "./Loading_Lazy";
import CardTester from "./Components/Testers/CardTester";
import HomeAdsOnDemandTester from "./Components/Pages/HomeAdsOnDemandTester";
import InvestorPage from "./Components/Pages/InvestorPage";
import PitchDeck from "./Components/Pages/PitchDeck";
import SalesmrktOverview from "./Components/Pages/SalesmrktOverview";
import SalesmrktOverviewNew from "./Components/Pages/SalesmrktOverviewNew";
import CapitalStack from "./Components/Pages/CapitalStack";
import FundgrowthPage from "./Components/Pages/FundgrowthPage";
import EscrowPage from "./Components/Pages/EscrowPage";
import NotFoundPage from "./Components/Pages/NotFoundPage";
import LiveSalesExperience from "./Components/Pages/LiveSalesExperience";
import BoardOfAdvisors from "./Components/Pages/BoardOfAdvisors";
import Vision_V2 from "./Components/Pages/Vision_V2";
import PitchDeckV2 from "./Components/Pages/PitchDeckV2";
import CalendlyThankYou from "Components/Pages/CalendlyThankYou";
import AdsLandingPage from "Components/Pages/AdsLandingPage";
const VirtualLobby = lazy(() => import("./Components/Pages/VirtualLobby"));
const HiringPage = lazy(() => import("./Components/Pages/HiringPage"));
const AboutUs = lazy(() => import("./Components/Pages/AboutUs"));
const HomeAdsOnDemand = lazy(
  () => import("./Components/Pages/HomeAdsOnDemand")
);
const LandingPageDemo = lazy(
  () => import("./Components/Pages/LandingPageDemo")
);
const HomeVideoOnDemand = lazy(
  () => import("./Components/Pages/HomeVideoOnDemand")
);
const HomeSalesTeamOnDemand = lazy(
  () => import("./Components/Pages/HomeSalesTeamOnDemand")
);
const OrgChart = lazy(() => import("./Components/Pages/OrgChart"));
const ThankYouPage = lazy(() => import("./Components/Pages/ThankYouPage"));
const HRENDOFYEARBUDGETOFFER = lazy(
  () => import("./Components/OnDemand/HRENDOFYEARBUDGETOFFER")
);
const BlackFriday = lazy(() => import("./Components/Emails/BlackFriday"));

const VirtualLobbyVideoProducation_1_LP = lazy(
  () => import("./Components/OnDemand/VirtualLobbyVideoProducation_1_LP")
);
const MarketingAgency_SS = lazy(
  () => import("./Components/OnDemand/MarketingAgency_SS")
);
const CreativeAgency_BF = lazy(
  () => import("./Components/OnDemand/CreativeAgency_BF")
);
const MarketingOnDemand_MN = lazy(
  () => import("./Components/OnDemand/MarketingOnDemand_MN")
);
const MarketingOnDemand_NH = lazy(
  () => import("./Components/OnDemand/MarketingOnDemand_NH")
);
const SaasTechOnDemand_MN = lazy(
  () => import("./Components/OnDemand/Saas&TechOnDemand_MN")
);
const SaasTechOnDemand_NH = lazy(
  () => import("./Components/OnDemand/Saas&TechOnDemand_NH")
);
const SaasTechOnDemand_SS = lazy(
  () => import("./Components/OnDemand/Saas&TechOnDemand_SS")
);
const EnterpriseOnDemand_MN = lazy(
  () => import("./Components/OnDemand/EnterpriseOnDemand_MN")
);
const HRTechServices_NH = lazy(
  () => import("./Components/OnDemand/HRTech&Services_NH")
);
const HRTechServices_SS = lazy(
  () => import("./Components/OnDemand/HRTech&Services_SS")
);
const FinancialServciesOnDemand_SS = lazy(
  () => import("./Components/OnDemand/Financial Servcies OnDemand_SS")
);
const EnterpriseOnDemand_SS = lazy(
  () => import("./Components/OnDemand/EnterpriseOnDemand_SS")
);
const FinancialServciesOnDemand_NH = lazy(
  () => import("./Components/OnDemand/Financial Servcies OnDemand_NH")
);
const EnterpriseOnDemand_NH = lazy(
  () => import("./Components/OnDemand/EnterpriseOnDemand_NH")
);
const EnterpriseOnDemand_BF = lazy(
  () => import("./Components/OnDemand/EnterpriseOnDemand_BF")
);
const VirtualLobbyVideoProducation_NH = lazy(
  () => import("./Components/OnDemand/VirtualLobbyVideoProducation_NH")
);
const VirtualLobbyVideoProducation_1 = lazy(
  () => import("./Components/OnDemand/VirtualLobbyVideoProducation_1")
);
const HRTechServices_MN = lazy(
  () => import("./Components/OnDemand/VirtualLobbyOther")
);
const JoinTheTeam = lazy(() => import("./Components/Pages/JoinTheTeam"));
const HomePageV2 = lazy(() => import("./Components/Pages/HomePageV2"));
function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Suspense fallback={<Loading_Lazy />}>
          <Routes>
            {/* // Core Pages */}

            <Route path="/" element={<HomePageV2 />} />
            {/* demo page */}
            <Route path="/landing-page-demo" element={<LandingPageDemo />} />

            <Route path="/Invest" element={<InvestorPage />} />
            <Route path="/ThankYou" element={<ThankYouPage />} />
            <Route path="/lobby" element={<VirtualLobby />} />
            <Route path="/campaigns-on-demand" element={<AdsLandingPage />} />
            <Route path="/thank-you-meeting" element={<CalendlyThankYou />} />
            <Route path="/CardTester" element={<CardTester />} />
            <Route path="/Team" element={<HiringPage />} />
            <Route path="/About" element={<AboutUs />} />
            <Route path="/Apply" element={<JoinTheTeam />} />
            <Route path="/OrgChart" element={<OrgChart />} />
            <Route path="/live" element={<LiveSalesExperience />} />
            <Route path="/Financing" element={<FundgrowthPage />} />
            <Route path="/Escrow" element={<EscrowPage />} />
            <Route path="/AdsOnDemand" element={<HomeAdsOnDemand />} />
            <Route path="/Vision" element={<Vision_V2 />} />
            <Route path="/BOA" element={<BoardOfAdvisors />} />
            <Route
              path="/AdsOnDemandTester_1"
              element={<HomeAdsOnDemandTester />}
            />
            <Route path="/VideoOnDemand" element={<HomeVideoOnDemand />} />
            <Route
              path="/SalesTeamOnDemand"
              element={<HomeSalesTeamOnDemand />}
            />
            {/* // Core pages */}
            <Route path="/HRTech&Services" element={<HRTechServices_MN />} />
            {/* // BF // */}
            <Route
              path="/VideoProduction_BF"
              element={<VirtualLobbyVideoProducation_1 />}
            />
            <Route
              path="/VideoProduction_BF_LP"
              element={<VirtualLobbyVideoProducation_1_LP />}
            />
            <Route
              path="/EOY-email-campaign-offer"
              element={<HRENDOFYEARBUDGETOFFER />}
            />
            <Route
              path="/EnterpriseOnDemand_BF"
              element={<EnterpriseOnDemand_BF />}
            />
            <Route path="/CreativeAgency_BF" element={<CreativeAgency_BF />} />
            {/* // BF // // NH// */}
            <Route
              path="/VideoProduction_NH"
              element={<VirtualLobbyVideoProducation_NH />}
            />
            <Route
              path="/EnterpriseOnDemand_NH"
              element={<EnterpriseOnDemand_NH />}
            />
            <Route
              path="/FinancialServicesOnDemand_NH"
              element={<FinancialServciesOnDemand_NH />}
            />
            <Route path="/HRTech&Services_NH" element={<HRTechServices_NH />} />
            <Route path="/TechServices_NH" element={<SaasTechOnDemand_NH />} />
            <Route
              path="/MarketingOnDemand_NH"
              element={<MarketingOnDemand_NH />}
            />
            {/* // NH // // SS // */}
            <Route
              path="/EnterpriseOnDemand_SS"
              element={<EnterpriseOnDemand_SS />}
            />
            <Route
              path="/FinancialServicesOnDemand_SS"
              element={<FinancialServciesOnDemand_SS />}
            />
            <Route path="/HRTech&Services_SS" element={<HRTechServices_SS />} />
            <Route
              path="/Saas&TechOnDemand_SS"
              element={<SaasTechOnDemand_SS />}
            />
            <Route
              path="/MarketingAgency_SS"
              element={<MarketingAgency_SS />}
            />
            {/* // SS // // MN // */}
            <Route
              path="/EnterpriseOnDemand_MN"
              element={<EnterpriseOnDemand_MN />}
            />
            <Route path="/HRTech&Services_MN" element={<HRTechServices_MN />} />
            <Route
              path="/Saas&TechOnDemand_MN"
              element={<SaasTechOnDemand_MN />}
            />
            <Route
              path="/MarketingOnDemand_MN"
              element={<MarketingOnDemand_MN />}
            />
            {/* Black Friday Custom Email */}
            <Route path="/Holiday-Offer" element={<BlackFriday />} />
            <Route path="/Pitch-Deck-V1" element={<PitchDeck />} />
            <Route path="/Pitch-Deck" element={<PitchDeckV2 />} />
            {/* <Route
							path='/Overview'
							element={<SalesmrktOverview />}
						/> */}
            <Route path="/Overview" element={<SalesmrktOverviewNew />} />
            <Route path="/CapitalStack" element={<CapitalStack />} />

            {/* <Route
						path='/Partner'
						element={<CertifiedPartnerNetwork />}
					/> */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
