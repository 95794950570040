import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import FirstTalker from '../../Assets/Video/World Class Customer Service 2024 2.0 (1).mp4';
// import Calendar from './Calender';
import logo from "../../Assets/SVG/Group 26784 1.svg";
import { BsFillCameraVideoFill } from "react-icons/bs";
import { AiOutlineClockCircle } from "react-icons/ai";
import Nii from "../../Assets/img/image (2).png";
import useScreen from "../../hooks/useScreen";
const CalendlyWidget = lazy(() => import("./Calender"));
interface CoustomerProps {
  Explore: boolean;
  Two: boolean;
  One: boolean;
  Three: boolean;
  Four: boolean;
  setOne: React.Dispatch<React.SetStateAction<any>>;
  setFour: React.Dispatch<React.SetStateAction<any>>;
  setThree: React.Dispatch<React.SetStateAction<any>>;
  setExplore: React.Dispatch<React.SetStateAction<any>>;
  setTwo: React.Dispatch<React.SetStateAction<any>>;
}
const CoustomerServices: React.FC<CoustomerProps> = ({
  Explore,
  setExplore,
  One,
  Two,
  Three,
  Four,
  setOne,
  setTwo,
  setThree,
  setFour,
}) => {
  const [date, setDate] = useState("");
  const [monthDate, setMonthDate] = useState("");
  const videoRef = useRef<HTMLVideoElement>(null);

  const screen = useScreen();

  const current = new Date();
  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[current.getMonth()]; // Get the month name

    setDate(
      `${current.toLocaleTimeString([], {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })}`
    );
    setMonthDate(`${month} ${current.getDate()}`);
  }, [current]);
  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
    };
  }, []);
  useEffect(() => {
    if (Explore === true) {
      setTimeout(() => {
        setExplore(false);
      }, 3000);
    }
  }, [Explore]);

  return (
    <div
      id="Explore"
      className="h-full lg:p-6 w-full pt-[100px] -mt-[100px]  mb-6  flex flex-col items-center justify-center"
    >
      {(screen?.width ?? 1280) >= 1280 ? (
        <div className="flex-col hidden lg:mt-28 mb-6 xl:flex  p-6 xl:flex-row items-center xl:items-start  justify-center gap-10">
          <div className="flex flex-col items-start justify-start xl:w-[70%] w-[90%] relative ">
            <h5 className="absolute flex gap-1  right-[3%] top-[3%] font-medium   text-[8px] text-zinc-900">
              {monthDate} - {date}
            </h5>

            <div>
              <video
                src={FirstTalker}
                className="max-h-[840px]   h-full w-full shadow-xl shadow-black/40 rounded-[40px] "
                loop
                autoPlay
                muted
                playsInline
                disablePictureInPicture
                preload="auto"
              ></video>
            </div>
            <div className=" flex-col absolute   bottom-[0] right-0 xl:flex xl:right-[-375px]  hidden gap-4 items-start justify-center z-10   ">
              <div className="flex flex-col  items-center justify-center bg-white    gap-3 ">
                <a
                  href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    Explore
                      ? "p-2 rounded w-full min-w-[335px] text-center text-base  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 bg-lime-500 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400"
                      : "p-2 rounded w-full min-w-[335px] text-center text-base  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400  text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400"
                  } transition-all ease-out duration-300`}
                >
                  <span className="relative ">Explore Solutions</span>
                </a>
                <a
                  href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${"p-2   rounded w-full min-w-[285px] text-center text-base  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 "} transition-all ease-out duration-300`}
                >
                  <span className="relative ">Discover Advantages</span>
                </a>
              </div>
            </div>
          </div>
          <div className="flex   xl:flex-col relative">
            <div className="flex flex-col items-start relative shadow-xl overflow-hidden	rounded-xl p-2 pb-3  hover:shadow-gray-400 transition-all duration-500 ease-in-out   mt-6 xl:mt-0">
              <Suspense fallback={<div>Loading...</div>}>
                <CalendlyWidget />
              </Suspense>
            </div>
            <div className=" flex-col xl:hidden flex   gap-4 items-center justify-center z-10  ">
              <h3 className="text-black  text-center w-full">
                Live - Click to Connect
              </h3>
              <div className="flex flex-col  items-center justify-center gap-3">
                <a
                  href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${
                    Explore
                      ? "p-2 rounded w-full min-w-[335px] text-center text-base  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 bg-lime-500 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400"
                      : "p-2 rounded w-full min-w-[335px] text-center text-base  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400  text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400"
                  } transition-all ease-out duration-300`}
                >
                  <span className="relative ">Explore Solutions</span>
                </a>
                <a
                  href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`${"p-2    rounded w-full min-w-[285px] text-center text-base  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 "} transition-all ease-out duration-300`}
                >
                  <span className="relative ">Discover Advantages</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col xl:hidden bg-gradient-to-b lg:mt-28 mt-6  from-white p-6  overflow-hidden">
          <div className="flex    flex-col items-center  justify-center bg-white border p-1 rounded-md  xl:w-[70%] w-full relative ">
            <div>
              <h1 className="font-medium    text-zinc-950 text-xl my-3 md:my-4 text-center  ">
                <span className="text-lime-600 animate-pulse">Live</span> - Just
                a click away
              </h1>
              {/* <video
              src={FirstTalker}
              className="max-h-[840px] hidden md:f  h-full w-full "
              loop
              autoPlay
              muted
              playsInline
              disablePictureInPicture
              controls
              preload="auto"
            ></video> */}
              <div className="w-full h-full flex flex-col gap-3 items-center justify-center">
                <img src={Nii} alt="Nii" className="rounded-full w-[75%]" />
                <h2 className="text-black">Nii H - Client Services</h2>
              </div>
            </div>

            <a
              href="https://calendly.com/salesbox-meet/salesmrkt"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className=" max-h-[600px]  rounded-lg overflow-hidden       noScrollBar pointer-events-none">
                <div className="  flex rounded-2xl  ">
                  <div className="md:flex border-r hidden mr-2 w-fit flex-col items-center justify-start">
                    <div className="flex w-fit items-center justify-center  mt-6 gap-3">
                      <img src={logo} alt="video production lead generation" />
                    </div>
                    <div className="flex flex-col w-fit items-start p-6">
                      <h5 className="text-start mb-3">
                        Revolutionize Your #SalesExperience
                      </h5>
                      <h3 className="text-xl max-w-[350px] text-black  font-bold">
                        Your One-Stop Shop for Sales & Marketing Solutions
                      </h3>
                      <div className="flex gap-4 items-center mt-10 justify-center">
                        <AiOutlineClockCircle className="text-2xl" />
                        <h5>30 min</h5>
                      </div>
                      <div className="flex gap-4 mt-3 w-4/5 items-center justify-center">
                        <BsFillCameraVideoFill className="text-3xl " />
                        <h5>
                          Web conferencing details provided upon confirmation.
                        </h5>
                      </div>
                      <div>
                        <h4 className="text-black font-normal  text-start mt-16">
                          Schedule an intro call to learn more about our
                          complete suite of Sales, Business Development,
                          Marketing, and Smart Tech solutions for
                          <b> B2B Companies</b>.
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="max-h-[450px]  overflow-hidden  w-full">
                    <Suspense fallback={<div>Loading...</div>}>
                      <CalendlyWidget />
                    </Suspense>
                    {/* <InlineWidget
										url='https://calendly.com/salesbox-meet/salesmrkt'
										pageSettings={{
											backgroundColor: 'fff',
											hideEventTypeDetails: true,
											hideLandingPageDetails: true,
											hideGdprBanner: true,
											primaryColor: '70C242',
											textColor: '#4d5055',
										}}
									/> */}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoustomerServices;
