import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  MutableRefObject,
} from "react";
import Turnkey from "../../Assets/CaroselImages/Enterprise-Grade Solutions 2.mp4";
import bundle from "../../Assets/CaroselImages/Value Driven Bundles 2.0.mp4";
import card from "../../Assets/CaroselImages/Membership Benefits 2.0.mp4";
import satisfaction from "../../Assets/CaroselImages/Trusted Partner v10.0_1.mp4";
import SalesExp from "../../Assets/CaroselImages/Sales Experience with sound 6.0Latest.mp4";
import TN from "../../Assets/CaroselImages/image (15).png";
import TN_Cards from "../../Assets/CaroselImages/image (13).png";
import TN_Bundles from "../../Assets/CaroselImages/image (12).png";
import TN_SalesExp from "../../Assets/CaroselImages/image (14).png";
import TN_TrustedPartner from "../../Assets/CaroselImages/NewTN_Satisfaction.png";
// import ReactPlayer from "react-player";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useScreen from "../../hooks/useScreen";
import { useLocation, useNavigation } from "react-router";

interface TopScrollerTextProps {
  handleClick: (linkNumber: number) => void;
  selected: boolean;
  text: string;
  id: number;
  view: ReactNode;
}

const TopScrollerText: React.FC<TopScrollerTextProps> = ({
  handleClick,
  selected,
  text,
  id,
}) => {
  return (
    <div
      className={`  rounded-full w-max transition-all duration-300 cursor-pointer font-semibold`}
      onClick={() => handleClick(id)}
    >
      {text}
    </div>
  );
};

interface CarouselProps {
  One: boolean;
  Two: boolean;
  Three: boolean;
  Four: boolean;
  setOne: React.Dispatch<React.SetStateAction<any>>;
  setTwo: React.Dispatch<React.SetStateAction<any>>;
  setThree: React.Dispatch<React.SetStateAction<any>>;
  setFour: React.Dispatch<React.SetStateAction<any>>;
}
const ImageCarosel: React.FC<CarouselProps> = ({
  One,
  Two,
  Three,
  Four,
  setOne,
  setTwo,
  setThree,
  setFour,
}) => {
  const [selectedFirst, setIsselectedFirst] = useState(false);
  const [selectedSecond, setIsselectedSecond] = useState(false);
  const [selectedThird, setIsselectedThird] = useState(false);
  const [selectedFourth, setIsselectedFourth] = useState(false);
  const [selectedFifth, setIsselectedFifth] = useState(true);
  const [hasPlayed, setHasPlayed] = useState(false);

  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
  const videoRef1 = useRef<HTMLVideoElement | null>(null);
  const videoRef2 = useRef<HTMLVideoElement | null>(null);
  const videoRef3 = useRef<HTMLVideoElement | null>(null);
  const videoRef4 = useRef<HTMLVideoElement | null>(null);
  const videoRef5 = useRef<HTMLVideoElement | null>(null);

  const screen = useScreen();

  const handleVideoPlay = () => {
    setHasPlayed(true);
  };

  function handleClick(linkNumber: number) {
    if (linkNumber === 1) {
      setIsselectedFirst(false);
      setIsselectedSecond(false);
      setIsselectedThird(false);
      setIsselectedFourth(true);
      setIsselectedFifth(false);
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
      if (videoRef1.current) {
        videoRef1.current.currentTime = 0;
        videoRef1.current.pause();
      }
      if (videoRef2.current) {
        videoRef2.current.currentTime = 0;
        videoRef2.current.pause();
      }
      if (videoRef3.current) {
        videoRef3.current.currentTime = 0;
        videoRef3.current.pause();
      }
      if (videoRef5.current) {
        videoRef5.current.currentTime = 0;
        videoRef5.current.pause();
      }
    } else if (linkNumber === 2) {
      setIsselectedFirst(false);
      setIsselectedSecond(true);
      setIsselectedThird(false);
      if (videoRef1.current) {
        videoRef1.current.currentTime = 0;
        videoRef1.current.pause();
      }
      if (videoRef4.current) {
        videoRef4.current.currentTime = 0;
        videoRef4.current.pause();
      }
      if (videoRef3.current) {
        videoRef3.current.currentTime = 0;
        videoRef3.current.pause();
      }
      if (videoRef5.current) {
        videoRef5.current.currentTime = 0;
        videoRef5.current.pause();
      }
      setIsselectedFourth(false);
      setIsselectedFifth(false);
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
    } else if (linkNumber === 3) {
      setIsselectedFirst(false);
      setIsselectedSecond(false);
      setIsselectedThird(true);
      if (videoRef1.current) {
        videoRef1.current.currentTime = 0;
        videoRef1.current.pause();
      }
      if (videoRef4.current) {
        videoRef4.current.currentTime = 0;
        videoRef4.current.pause();
      }
      if (videoRef2.current) {
        videoRef2.current.currentTime = 0;
        videoRef2.current.pause();
      }
      if (videoRef5.current) {
        videoRef5.current.currentTime = 0;
        videoRef5.current.pause();
      }
      setIsselectedFourth(false);
      setIsselectedFifth(false);
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
    } else if (linkNumber === 4) {
      setIsselectedFirst(true);
      setIsselectedSecond(false);
      setIsselectedThird(false);
      setIsselectedFourth(false);
      if (videoRef1.current) {
        videoRef1.current.currentTime = 0;
        videoRef1.current.pause();
      }
      if (videoRef3.current) {
        videoRef3.current.currentTime = 0;
        videoRef3.current.pause();
      }
      if (videoRef2.current) {
        videoRef2.current.currentTime = 0;
        videoRef2.current.pause();
      }
      if (videoRef5.current) {
        videoRef5.current.currentTime = 0;
        videoRef5.current.pause();
      }
      setIsselectedFifth(false);
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
    } else if (linkNumber === 5) {
      setIsselectedFirst(false);
      setIsselectedSecond(false);
      setIsselectedThird(false);
      setIsselectedFourth(false);
      setIsselectedFifth(true);
      if (videoRef1.current) {
        videoRef1.current.currentTime = 0;
        videoRef1.current.pause();
      }
      if (videoRef3.current) {
        videoRef3.current.currentTime = 0;
        videoRef3.current.pause();
      }
      if (videoRef2.current) {
        videoRef2.current.currentTime = 0;
        videoRef2.current.pause();
      }
      if (videoRef4.current) {
        videoRef4.current.currentTime = 0;
        videoRef4.current.pause();
      }

      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
    } else {
      setIsselectedFourth(true);
      setIsselectedFirst(false);
      if (videoRef1.current) {
        videoRef1.current.currentTime = 0;
        videoRef1.current.pause();
      }
      if (videoRef3.current) {
        videoRef3.current.currentTime = 0;
        videoRef3.current.pause();
      }
      if (videoRef2.current) {
        videoRef2.current.currentTime = 0;
        videoRef2.current.pause();
      }
      if (videoRef4.current) {
        videoRef4.current.currentTime = 0;
        videoRef4.current.pause();
      }
      if (videoRef5.current) {
        videoRef5.current.pause();
        videoRef5.current.currentTime = 0;
      }
      setIsselectedSecond(false);
      setIsselectedThird(false);
      setIsselectedFifth(false);
      setOne(false);
      setTwo(false);
      setThree(false);
      setFour(false);
    }
  }
  useEffect(() => {
    if (One === true) {
      setIsselectedFirst(false);
      setIsselectedSecond(true);
      setIsselectedThird(false);
      setIsselectedFourth(false);
      setIsselectedFifth(false);
    } else if (Two === true) {
      setIsselectedFirst(false);
      setIsselectedSecond(false);
      setIsselectedThird(true);
      setIsselectedFourth(false);
      setIsselectedFifth(false);
    } else if (Three === true) {
      setIsselectedFirst(false);
      setIsselectedSecond(false);
      setIsselectedThird(false);
      setIsselectedFourth(true);
      setIsselectedFifth(false);
    } else if (Four === true) {
      setIsselectedFirst(false);
      setIsselectedSecond(false);
      setIsselectedThird(false);
      setIsselectedFourth(false);
      setIsselectedFifth(true);
    }
  }, [One, Two, Three, Four]);

  const arrEls: TopScrollerTextProps[] = [
    {
      handleClick,
      id: 5,
      selected: selectedFifth,
      text: "#SalesExperience",
      view: (
        <View
          title="#SalesExperience"
          selected
          id={1}
          currentSlideIndex={currentSlideIndex}
          videoRef={videoRef5}
          videoUrl={SalesExp}
          handleVideoPlay={handleVideoPlay}
          poster={TN}
          linkText="Launch Your Solution >"
          link="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
          description="Step up your sales by merging the best of B2B | B2C to create a
			more memorable experience, gain a competitive edge | shorten your
			sales cycle."
        />
      ),
    },
    {
      handleClick,
      id: 1,
      selected: selectedFourth,
      text: "Enterprise-Grade",
      view: (
        <View
          id={2}
          title="Enterprise-Grade"
          currentSlideIndex={currentSlideIndex}
          selected
          videoRef={videoRef4}
          videoUrl={Turnkey}
          handleVideoPlay={handleVideoPlay}
          poster={TN_SalesExp}
          linkText="Grow Your Sales >"
          link="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
          description="Elevate every aspect of your business’s sales and marketing with
			  our enterprise-level and expert-led tailored solutions."
        />
      ),
    },
    {
      handleClick,
      id: 2,
      selected: selectedSecond,
      text: "Value-Driven",
      view: (
        <View
          id={3}
          title="Value-Driven"
          currentSlideIndex={currentSlideIndex}
          selected
          videoRef={videoRef2}
          videoUrl={bundle}
          handleVideoPlay={handleVideoPlay}
          poster={TN_Bundles}
          linkText="Select Your Services >"
          link="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
          description="Choose your perfect bundled solution to receive exclusive savings
			on everything needed to amplify your selling potential."
        />
      ),
    },
    {
      handleClick,
      id: 4,
      selected: selectedFirst,
      text: "Trusted Partner",
      view: (
        <View
          id={4}
          title="Trusted Partner"
          currentSlideIndex={currentSlideIndex}
          selected
          videoRef={videoRef1 as MutableRefObject<HTMLVideoElement | null>}
          videoUrl={satisfaction}
          handleVideoPlay={handleVideoPlay}
          linkText="Start Your Membership >"
          link="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
          description="Shop with confidence knowing we only partner with top-tier,
			certified providers and ensure 100% satisfaction, offering an
			alternative if needed."
        />
      ),
    },
    {
      handleClick,
      id: 3,
      selected: selectedThird,
      text: "Advantage Plan",
      view: (
        <View
          id={5}
          title="Advantage Plan"
          currentSlideIndex={currentSlideIndex}
          selected
          videoRef={videoRef3}
          poster={TN_Cards}
          videoUrl={card}
          handleVideoPlay={handleVideoPlay}
          linkText="Explore Your Benefits >"
          link="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
          description=" Membership options offer a host of savings and rewards. Discover
			your perfect deal and get ready to enjoy the perks."
        />
      ),
    },
  ];

  if ((screen?.width ?? 768) < 768) {
    return (
      <div className=" w-full h-full md:mt-16 md:mb-16 mt-10 mb-12 p-4 ">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          initialSlide={0}
          style={{
            overflow: "visible",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          loop
          onSlideChange={(swiper: any) => {
            setCurrentSlideIndex((swiper.realIndex ?? 0) + 1);
            // window.location.replace("/#");
          }}
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
        >
          {arrEls.map((el, index) => {
            return (
              <SwiperSlide key={`arr-elms-${index}`}>{el.view}</SwiperSlide>
            );
          })}
          <Navigators />
        </Swiper>
      </div>
    );
  }

  return (
    <div className=" w-full h-full    flex md:mt-16 md:mb-16 mt-10 mb-12   justify-center items-center ">
      <div className="hidden md:grid-container w-[90%] lg:w-[85%] ">
        <div className="md:flex md:flex-col grid grid-cols-2 mb-3 gap-4 md:gap-0 md:mb-0  items-start justify-between h-[90%]    ">
          <div
            className="flex w-full items-center group justify-between cursor-pointer "
            onClick={() => handleClick(5)}
          >
            <div className="flex items-center    gap-2">
              <div className="relative">
                <div
                  className={`${
                    selectedFifth
                      ? "w-[6px] h-[15px] md:h-[25px] top-[0px]  absolute left-[-10px] lg:left-[-15px] opacity-100 bg-black rounded-md"
                      : "opacity-0"
                  } transition-all duration-300 ease-in`}
                ></div>
                <div className="flex flex-col gap-2 ">
                  <div className="flex items-center    justify-start">
                    <h3
                      className={`${
                        selectedFifth
                          ? "text-lime-500    text 2xl:text-[25px] font-bold md:text-[18px] lg:text-[20px] md:text-[18px]"
                          : "text-black group-hover:text-lime-500  font-bold transition-all 2xl:text-[25px] lg:text-[20px] md:text-[18px] "
                      }`}
                    >
                      #SalesExperience
                    </h3>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 11 12"
                      fill="none"
                      className={` ${
                        selectedFifth
                          ? "fill-lime-500 w-3 md:w-5 duration-100 transition-all ease-in "
                          : "  duration-100 transition-all ease-in fill-white group-hover:fill-lime-500 "
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
                        fill=""
                      />
                    </svg>
                  </div>
                  <div className="w-fit">
                    <p className="hidden md:flex text-xs lg:text-base   w-fit">
                      B2B Meets B2C
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex w-full items-center group  justify-between cursor-pointer "
            onClick={() => handleClick(1)}
          >
            <div className="flex items-center gap-2">
              <div className="relative  ">
                <div
                  className={`${
                    selectedFourth
                      ? "w-[6px] h-[15px] md:h-[25px] top-[0px]  absolute left-[-10px] lg:left-[-15px] opacity-100 bg-black rounded-md"
                      : "opacity-0"
                  } transition-all duration-300 ease-in`}
                ></div>
                <div className="flex flex-col gap-2">
                  <div className="flex  items-center justify-start">
                    <h3
                      className={`${
                        selectedFourth
                          ? "text-lime-500 2xl:text-[25px] font-bold lg:text-[20px] md:text-[18px] "
                          : "text-black group-hover:text-lime-500  font-bold transition-all 2xl:text-[25px] lg:text-[20px] md:text-[18px] "
                      }`}
                    >
                      Enterprise-Grade
                    </h3>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 11 12"
                      fill="none"
                      className={` ${
                        selectedFourth
                          ? "fill-lime-500 w-3 md:w-5 duration-100 transition-all ease-in "
                          : "  duration-100 transition-all ease-in fill-white group-hover:fill-lime-500 "
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
                        fill=""
                      />
                    </svg>
                  </div>
                  <p className="hidden md:flex text-xs lg:text-base   w-fit">
                    Customized & Scalable Solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex w-full items-center group justify-between cursor-pointer  "
            onClick={() => handleClick(2)}
          >
            <div className="flex items-center gap-2">
              <div className="relative">
                <div
                  className={`${
                    selectedSecond
                      ? "w-[6px] h-[15px] md:h-[25px] top-[0px]  absolute left-[-10px] lg:left-[-15px] opacity-100 bg-black rounded-md"
                      : "opacity-0"
                  } transition-all duration-300 ease-in`}
                ></div>
                <div className="flex flex-col gap-2 ">
                  <div className="flex items-center    justify-start">
                    <h3
                      className={`${
                        selectedSecond
                          ? "text-lime-500 2xl:text-[25px] font-bold lg:text-[20px] md:text-[18px] "
                          : "text-black group-hover:text-lime-500  font-bold transition-all 2xl:text-[25px] lg:text-[20px] md:text-[18px] "
                      }`}
                    >
                      Value-Driven
                    </h3>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 11 12"
                      fill="none"
                      className={` ${
                        selectedSecond
                          ? "fill-lime-500 w-3 md:w-5 duration-100 transition-all ease-in "
                          : "  duration-100 transition-all ease-in fill-white group-hover:fill-lime-500 "
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
                        fill=""
                      />
                    </svg>
                  </div>
                  <div className="w-fit">
                    <p className="hidden md:flex text-xs lg:text-base   w-fit">
                      OnDemand™, Bundles & Upgrades
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex  items-center w-full group transition-all duration-150 ease-in    justify-between cursor-pointer"
            onClick={() => handleClick(4)}
          >
            <div className="flex  items-center  gap-2 ">
              <div className="relative">
                <div
                  className={`${
                    selectedFirst
                      ? "w-[6px] h-[15px] md:h-[25px] top-[0px]  absolute left-[-10px] lg:left-[-15px] opacity-100 bg-black rounded-md"
                      : "opacity-0"
                  } transition-all duration-300 ease-in`}
                ></div>
                <div className="flex flex-col gap-2 ">
                  <div className="flex items-center    justify-start">
                    <h3
                      className={`${
                        selectedFirst
                          ? "text-lime-500 2xl:text-[25px] font-bold lg:text-[20px] md:text-[18px] "
                          : "text-black group-hover:text-lime-500  font-bold transition-all 2xl:text-[25px] lg:text-[20px] md:text-[18px] "
                      }`}
                    >
                      Trusted Partner
                    </h3>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 11 12"
                      fill="none"
                      className={` ${
                        selectedFirst
                          ? "fill-lime-500 w-3 md:w-5 duration-100 transition-all ease-in "
                          : "  duration-100 transition-all ease-in fill-white group-hover:fill-lime-500 "
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
                        fill=""
                      />
                    </svg>
                  </div>
                  <div className="w-fit">
                    <p className="hidden md:flex text-xs lg:text-base   w-fit">
                      Satisfaction Guarantee
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex w-full items-center group justify-between cursor-pointer "
            id="bundles"
            onClick={() => handleClick(3)}
          >
            <div className="flex items-center gap-2">
              <div className="relative">
                <div
                  className={`${
                    selectedThird
                      ? "w-[6px] h-[15px] md:h-[25px] top-[0px]  absolute left-[-10px] lg:left-[-15px] opacity-100 bg-black rounded-md"
                      : "opacity-0"
                  } transition-all duration-300 ease-in`}
                ></div>
                <div className="flex flex-col gap-2 ">
                  <div className="flex items-center    justify-start">
                    <h3
                      className={`${
                        selectedThird
                          ? "text-lime-500 2xl:text-[25px] font-bold lg:text-[20px] md:text-[18px] "
                          : "text-black group-hover:text-lime-500  font-bold transition-all 2xl:text-[25px] lg:text-[20px] md:text-[18px] "
                      }`}
                    >
                      Advantage Plan
                    </h3>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 11 12"
                      fill="none"
                      className={` ${
                        selectedThird
                          ? "fill-lime-500 w-3 md:w-5 duration-100 transition-all ease-in "
                          : "  duration-100 transition-all ease-in fill-white group-hover:fill-lime-500 "
                      }`}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
                        fill=""
                      />
                    </svg>
                  </div>
                  <div className="w-fit">
                    <p className="hidden md:flex text-xs lg:text-base   w-fit">
                      Benefits & Rewards
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:h-full   ">
          <div
            className={`${
              selectedFourth
                ? "flex flex-col overflow-hidden relative  w-full h-full items-start  justify-center"
                : "hidden"
            }`}
          >
            <div className=" w-full shadow-lg cursor-pointer  rounded-[10px] relative overflow-hidden ">
              {/* <ReactPlayer
								ref={videoRef4}
								url={Turnkey}
								
								loop={false}
								width='100%'
								height='100%'
								controls={true}
							/> */}

              <video
                src={Turnkey}
                poster={TN_SalesExp}
                controls
                autoPlay={false}
                playsInline={false}
                disablePictureInPicture
                className="w-full h-full"
                onPlay={handleVideoPlay}
                ref={videoRef4}
              ></video>
            </div>
            <div className="w-full flex">
              <h5 className="transition-all duration-75 ease-in mt-3  lg:text-[16px]  xl:text-[20px] md:text-lg text-[12px] ">
                Elevate every aspect of your business’s sales and marketing with
                our enterprise-level and expert-led tailored solutions.
                <a
                  href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-lime-500 hover:text-gray-800 font-semibold transition-all duration-100 ml-2"
                >
                  Grow Your Sales{">"}
                </a>
              </h5>
            </div>
          </div>
          <div
            className={`${
              selectedSecond
                ? "flex flex-col overflow-hidden relative  w-full h-full items-start  justify-center"
                : "hidden"
            }`}
          >
            <div className="w-[100%]  shadow-lg cursor-pointer rounded-[10px] relative overflow-hidden">
              <video
                src={bundle}
                poster={TN_Bundles}
                controls
                playsInline={false}
                disablePictureInPicture
                className="w-full h-full"
                onPlay={handleVideoPlay}
                ref={videoRef2}
              ></video>
            </div>
            <h5 className="   transition-all duration-75 ease-in mt-3  lg:text-[16px]  xl:text-[20px] md:text-lg text-[12px] ">
              Choose your perfect bundled solution to receive exclusive savings
              on everything needed to amplify your selling potential.
              <a
                href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-lime-500 hover:text-gray-800 font-semibold transition-all duration-100 ml-2"
              >
                Select Your Services{">"}
              </a>
            </h5>
          </div>

          <div
            className={`${
              selectedThird
                ? "flex flex-col overflow-hidden relative cursor-pointer  w-full h-full items-start  justify-center"
                : "hidden"
            }`}
          >
            <div className="w-[100%]     shadow-lg  rounded-[10px] relative overflow-hidden">
              <video
                src={card}
                poster={TN_Cards}
                controls
                playsInline={false}
                disablePictureInPicture
                className="w-full h-full"
                onPlay={handleVideoPlay}
                ref={videoRef3}
              ></video>
            </div>
            <h5 className="transition-all duration-75 ease-in mt-3  w-full  lg:text-[16px]  xl:text-[20px] md:text-lg text-[16px]  ">
              Membership options offer a host of savings and rewards. Discover
              your perfect deal and get ready to enjoy the perks.
              <a
                href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-lime-500 hover:text-gray-800 font-semibold transition-all duration-100 ml-2"
              >
                Explore Your Benefits{">"}
              </a>
            </h5>
          </div>

          <div
            className={`${
              selectedFirst
                ? "flex flex-col overflow-hidden relative   w-full h-full items-start  justify-center"
                : "hidden"
            }`}
          >
            <div className="  shadow-lg  rounded-[10px] overflow-hidden">
              <video
                src={satisfaction}
                poster={TN_TrustedPartner}
                controls
                playsInline={false}
                disablePictureInPicture
                className="w-full h-full"
                onPlay={handleVideoPlay}
                ref={videoRef1}
              ></video>
            </div>
            <h5 className="transition-all duration-75 ease-in mt-3 lg:text-[16px]  xl:text-[20px]   md:text-lg text-[12px] ">
              Shop with confidence knowing we only partner with top-tier,
              certified providers and ensure 100% satisfaction, offering an
              alternative if needed.
              <a
                href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-lime-500 hover:text-gray-800 font-semibold transition-all duration-100 ml-2"
              >
                Start Your Membership
                {">"}
              </a>
            </h5>
          </div>

          <div
            className={`${
              selectedFifth
                ? "flex flex-col overflow-hidden relative cursor-pointer  w-full h-full items-start  justify-center"
                : "hidden"
            }`}
          >
            <div className=" shadow-lg relative  rounded-[10px] overflow-hidden">
              {/* <ReactPlayer
								ref={videoRef5}
								url={SalesExp}
								playing={true}
								loop={false}
								width='100%'
								height='100%'
								controls={true}
								light={TN}
							/> */}

              <video
                src={SalesExp}
                poster={TN}
                controls
                playsInline={false}
                disablePictureInPicture
                className="w-full h-full"
                onPlay={handleVideoPlay}
                ref={videoRef5}
              ></video>
            </div>
            <h5 className="transition-all  duration-75 ease-in mt-3  lg:text-[16px]  xl:text-[20px] md:text-lg text-[12px]">
              Step up your sales by merging the best of B2B | B2C to create a
              more memorable experience, gain a competitive edge | shorten your
              sales cycle.
              <a
                href="https://calendly.com/salesbox-meet/salesmrkt?month=2023-08"
                target="_blank"
                rel="noopener noreferrer"
                className=" text-lime-500 hover:text-gray-800 font-semibold transition-all duration-100 ml-2"
              >
                Launch Your Solution
                {">"}
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};
interface ClickableSideTextDesktopProps {
  handleClick: () => void;
  selected: boolean;
  title: string;
  subTitle: string;
}

const ClickableSideTextDesktop: React.FC<ClickableSideTextDesktopProps> = ({
  handleClick,
  selected,
  subTitle,
  title,
}) => (
  <div
    className="flex w-full items-center group justify-between cursor-pointer "
    onClick={handleClick}
  >
    <div className="flex items-center    gap-2">
      <div className="relative">
        <div
          className={`${
            selected
              ? "w-[6px] h-[15px] md:h-[25px] top-[0px]  absolute left-[-10px] lg:left-[-15px] opacity-100 bg-black rounded-md"
              : "opacity-0"
          } transition-all duration-300 ease-in`}
        ></div>
        <div className="flex flex-col gap-2 ">
          <div className="flex items-center    justify-start">
            <h3
              className={`${
                selected
                  ? "text-lime-500 2xl:text-[25px] font-bold lg:text-[20px] md:text-[18px]"
                  : "text-black group-hover:text-lime-500  font-bold transition-all 2xl:text-[25px] lg:text-[20px] md:text-[18px] "
              }`}
            >
              {title}
            </h3>
            <svg
              width="18"
              height="18"
              viewBox="0 0 11 12"
              fill="none"
              className={` ${
                selected
                  ? "fill-lime-500 w-3 md:w-5 duration-100 transition-all ease-in "
                  : "  duration-100 transition-all ease-in fill-white group-hover:fill-lime-500 "
              }`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.558719 0.921234H4.78272L10.0387 6.32123L4.78272 11.6972H0.558719L5.79072 6.32123L0.558719 0.921234Z"
                fill=""
              />
            </svg>
          </div>
          <div className="w-fit">
            <p className="hidden md:flex text-xs lg:text-base   w-fit">
              {subTitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

interface ViewProps {
  selected: boolean;
  videoRef: any;
  videoUrl: any;
  description: string;
  link: string;
  linkText: string;
  title?: string;
  currentSlideIndex?: number;
  id?: number;
  poster?: string;
  handleVideoPlay: () => void;
}

const View: React.FC<ViewProps> = ({
  description,
  link,
  linkText,
  selected,
  videoRef,
  videoUrl,
  title,
  currentSlideIndex,
  handleVideoPlay,

  poster,
  id,
}) => {
  // const [reload, setReload] = useState(false);

  // useEffect(() => {
  //   if (reload) {
  //     setReload(false);
  //   } else {
  //     setReload(true);
  //   }
  //   setTimeout(() => {
  //     setReload(true);
  //   }, 500);
  //   console.log(currentSlideIndex ?? 1, id ?? 1);
  // }, [currentSlideIndex]);

  return (
    <div
      className={`${
        selected
          ? "flex flex-col overflow-hidden relative  w-full h-full items-start  justify-center"
          : "hidden"
      }`}
    >
      <div className=" w-full shadow-lg  rounded-[10px] overflow-hidden ">
        {/* <video src={card} controls width="100%" height="100%"></video> */}
        {/* {(currentSlideIndex ?? 1) === id ? ( */}
        <video
          ref={videoRef}
          src={videoUrl}
          loop={false}
          width="100%"
          height="100%"
          onPlay={handleVideoPlay}
          poster={poster}
          controls
          autoPlay={false}
          playsInline={false}
          disablePictureInPicture
        />
        {/* ) : ( */}
        {/* <video
            ref={videoRef}
            src={videoUrl}
            loop={false}
            autoPlay={false}
            width="100%"
            height="100%"
            controls={false}
          /> */}
        {/* )} */}
      </div>
      <div className="w-full mt-4">
        {title && (
          <TopScrollerText
            text={title}
            selected
            handleClick={() => {}}
            id={1}
            view={<div></div>}
          />
        )}
        <h5 className="transition-all duration-75 ease-in mt-3  lg:text-[16px]  xl:text-[20px] md:text-lg text-[12px] ">
          {description}
        </h5>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className=" text-lime-500 hover:text-gray-800 font-semibold transition-all duration-100 mt-2 lg:text-[16px]  xl:text-[20px] md:text-lg text-[12px]"
        >
          {linkText}
        </a>
      </div>
    </div>
  );
};

const Navigators = () => {
  const swiper = useSwiper();
  const { hash } = useLocation();

  useEffect(() => {
    if (window) {
      console.log(hash);
      const path = window.location.hash;
      if (path === "#Bundles") {
        swiper.slideTo(2, 300);
      } else if (path === "#Membership") {
        swiper.slideTo(4, 300);
      } else if (path === "#Experience") {
        swiper.slideTo(0, 300);
      }
    }
  }, [hash]);

  return <></>;
};

export default ImageCarosel;
