import React, { useState } from "react";
import AllInOne from "../../Assets/SalesExperienceAssets/AllInOne.png";
import CampaginsOnDemand from "../../Assets/SalesExperienceAssets/CampaginsOnDemand.png";
import MultiMediaAssets from "../../Assets/SalesExperienceAssets/MultiMediaAssets.svg";
import ContentMachiene from "../../Assets/SalesExperienceAssets/ContentMachiene.png";
import PersonalizedOutReach from "../../Assets/SalesExperienceAssets/PersonalizedOutReach.png";
import ClientFeedback from "../../Assets/SalesExperienceAssets/ClientFeedback.png";

import image301 from "../../Assets/SalesExperienceAssets/Group 1000003046.png";
import image302 from "../../Assets/SalesExperienceAssets/Group 1000003048.png";
import image303 from "../../Assets/SalesExperienceAssets/Group 1000003049.png";
import image304 from "../../Assets/SalesExperienceAssets/Group 1000003047.png";
import image305 from "../../Assets/SalesExperienceAssets/Group 1000003050.png";
import image306 from "../../Assets/SalesExperienceAssets/Group 1000003051.png";

import VideoEmbed from "../YoutubeEmbed/VideoEmbed";

import image401 from "../../Assets/SalesExperienceAssets/Rectangle 1368.png";
import image402 from "../../Assets/SalesExperienceAssets/Rectangle 1368 (1).png";
import image403 from "../../Assets/SalesExperienceAssets/Rectangle 1368 (2).png";
import image404 from "../../Assets/SalesExperienceAssets/Rectangle 1368 (3).png";
import image405 from "../../Assets/SalesExperienceAssets/Rectangle 1368 (4).png";
import image406 from "../../Assets/SalesExperienceAssets/Rectangle 1368 (5).png";
import image407 from "../../Assets/SalesExperienceAssets/Rectangle 1368 (6).png";
import image408 from "../../Assets/SalesExperienceAssets/Rectangle 1369.png";

import IntroVideo from "../../Assets/Video/Salesmrkt - Welcome to the Future of Sales, Marketing & Tech (1).mp4";

import live from "../../Assets/SalesExperienceAssets/Group 1000003088.png";
import logo from "../../Assets/SalesExperienceAssets/Group 1000003087.png";

import icon01 from "../../Assets/SalesExperienceAssets/Group.png";
import icon02 from "../../Assets/SalesExperienceAssets/Group 27290.png";

import icon101 from "../../Assets/SalesExperienceAssets/Group 26973.svg";
import icon102 from "../../Assets/SalesExperienceAssets/Group.svg";
import icon103 from "../../Assets/SalesExperienceAssets/Group (1).svg";
import icon104 from "../../Assets/SalesExperienceAssets/Group (4).svg";
import icon105 from "../../Assets/SalesExperienceAssets/Group (5).svg";
import icon106 from "../../Assets/SalesExperienceAssets/Group (6).svg";
import icon107 from "../../Assets/SalesExperienceAssets/Group (7).svg";
import icon108 from "../../Assets/SalesExperienceAssets/Group (8).svg";

const data = [
  {
    title: "Business Development",
    image: image408,
  },
  {
    title: "Sales & Strategy",
    image: image401,
  },
  {
    title: "Brand & Creative",
    image: image402,
  },
  {
    title: "Digital Marketing",
    image: image403,
  },
  {
    title: "Copy & Content",
    image: image404,
  },
  {
    title: "Video Production",
    image: image405,
  },
  {
    title: "Web & Tech",
    image: image406,
  },
  {
    title: "Build My Team",
    image: image407,
  },
];

const LiveSalesExperience = () => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showBTSMenu, setShowBTSMenu] = useState(false);
  const [showCampaignMenu, setShowCampaignMenu] = useState(false);
  const [showSalesMenu, setShowSalesMenu] = useState(false);

  const [currentCampaignIndex, setCurrentCampaignIndex] = useState(0);

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center  ">
      <div className="w-full relative flex items-center justify-center my-10 py-4">
        <div className="w-60">
          <img src={live} alt="" width="80%" height="80%" />
        </div>
        <div className="absolute top-0 right-32">
          <img src={logo} alt="" width="100%" height="100%" />
        </div>
      </div>
      <div className="md:grid  md:grid-cols-2 lg:grid-cols-4 gap-4 w-full lg:w-[90%]">
        <div className="p-4 border rounded-lg flex flex-col gap-4 items-center justify-between">
          <img src={AllInOne} alt="" />
          <div className="grid grid-cols-2 w-full">
            <div className="w-full flex items-center justify-center gap-2 border-r border-[#0000004D]">
              <h3 className="text-black text-xl font-semibold">Demo</h3>
              <button onClick={() => setShowOverlay(true)}>
                <svg
                  width="37"
                  height="36"
                  viewBox="0 0 37 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.8914 34.1998C27.8384 34.1998 35.0914 26.9468 35.0914 17.9998C35.0914 9.05279 27.8384 1.7998 18.8914 1.7998C9.94439 1.7998 2.69141 9.05279 2.69141 17.9998C2.69141 26.9468 9.94439 34.1998 18.8914 34.1998Z"
                    fill="#84CC16"
                    stroke="#84CC16"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.0587 12.6207L24.0033 17.346C24.3501 17.5523 24.4706 18.0122 24.2724 18.3732C24.2084 18.4899 24.1154 18.5866 24.0033 18.6533L16.0587 23.3787C15.7118 23.585 15.27 23.4596 15.0719 23.0986C15.0094 22.9848 14.9766 22.8561 14.9766 22.725V13.2743C14.9766 12.8585 15.3004 12.5215 15.6998 12.5215C15.8257 12.5215 15.9494 12.5557 16.0587 12.6207Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="w-full flex items-center justify-center gap-2">
              <h3 className="text-black text-xl font-semibold">Summary</h3>
              <button onClick={() => {}}>
                <svg
                  width="35"
                  height="32"
                  viewBox="0 0 35 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.0438 8.33915H11.0588C10.8992 8.33915 10.7595 8.28595 10.6398 8.17955C10.5201 8.07315 10.447 7.94015 10.4204 7.78055L9.14357 1.23691C9.09037 0.864505 8.93077 0.565253 8.66476 0.339152C8.39876 0.113051 8.07956 0 7.70716 0H1.88172C1.48272 0 1.14357 0.139651 0.864265 0.418953C0.584963 0.698254 0.445312 1.03741 0.445312 1.43641V1.63591C0.445312 2.03491 0.584963 2.38071 0.864265 2.67332C1.14357 2.96592 1.48272 3.11222 1.88172 3.11222H5.95155C6.05795 3.11222 6.15105 3.14547 6.23085 3.21197C6.31065 3.27847 6.36385 3.36492 6.39045 3.47132L10.4603 25.1771C10.5401 25.6825 10.8061 26.0549 11.2583 26.2943C11.4711 26.4273 11.7105 26.4938 11.9765 26.4938H12.6548C12.0696 27.1588 11.7903 27.9368 11.8169 28.8279C11.8435 29.719 12.176 30.4705 12.8144 31.0823C13.4528 31.6941 14.2175 32 15.1087 32C15.9998 32 16.7712 31.6941 17.4229 31.0823C18.0746 30.4705 18.4137 29.719 18.4403 28.8279C18.4669 27.9368 18.1743 27.1588 17.5625 26.4938H24.984C24.3722 27.1588 24.0862 27.9368 24.1261 28.8279C24.166 29.719 24.5052 30.4705 25.1436 31.0823C25.782 31.6941 26.5467 32 27.4378 32C28.3289 32 29.0937 31.6941 29.7321 31.0823C30.3705 30.4705 30.703 29.719 30.7296 28.8279C30.7562 27.9368 30.4769 27.1588 29.8917 26.4938H30.57C30.969 26.4938 31.3082 26.3541 31.5875 26.0748C31.8668 25.7955 32.0064 25.4564 32.0064 25.0574V24.7382C32.0064 24.3392 31.8601 24 31.5675 23.7207C31.2749 23.4414 30.9291 23.3017 30.5301 23.3017H13.8119C13.6523 23.3017 13.5126 23.2485 13.3929 23.1421C13.2732 23.0357 13.2001 22.916 13.1735 22.783L12.7745 20.6284C12.7479 20.4156 12.8011 20.2294 12.9341 20.0698C13.0671 19.9102 13.2267 19.8437 13.4129 19.8703H31.767C32.1128 19.8703 32.4121 19.7639 32.6648 19.5511C32.9175 19.3383 33.0704 19.059 33.1236 18.7132L34.4403 9.97506C34.5201 9.54946 34.4204 9.17041 34.1411 8.8379C33.8618 8.5054 33.496 8.33915 33.0438 8.33915Z"
                    fill="#84CC16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="p-4 border rounded-lg flex flex-col gap-4 items-center justify-between">
          <h2 className="text-center text-4xl text-black font-semibold mt-8">
            {data[currentCampaignIndex].title}
          </h2>
          <div className="w-full flex gap-4">
            <div className="w-[2rem] flex flex-col gap-2">
              <button onClick={() => setCurrentCampaignIndex(0)}>
                <img src={icon102} width="100%" height="100%" alt="" />
              </button>
              <button onClick={() => setCurrentCampaignIndex(1)}>
                <img src={icon103} width="100%" height="100%" alt="" />
              </button>
              <button onClick={() => setCurrentCampaignIndex(2)}>
                <img src={icon104} width="100%" height="100%" alt="" />
              </button>
              <button onClick={() => setCurrentCampaignIndex(3)}>
                <img src={icon105} width="100%" height="100%" alt="" />
              </button>
              <button onClick={() => setCurrentCampaignIndex(4)}>
                <img src={icon106} width="100%" height="100%" alt="" />
              </button>
              <button onClick={() => setCurrentCampaignIndex(5)}>
                <img src={icon107} width="100%" height="100%" alt="" />
              </button>
              <button onClick={() => setCurrentCampaignIndex(6)}>
                <img src={icon108} width="100%" height="100%" alt="" />
              </button>
              <button onClick={() => setCurrentCampaignIndex(7)}>
                <img src={icon101} width="100%" height="100%" alt="" />
              </button>
            </div>
            <div className="w-[calc(100%-2rem-1rem)]">
              <img src={data[currentCampaignIndex].image} alt="" />
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="relative">
              <div
                className="flex p-2 border-r border-[#0000004D] cursor-pointer"
                onClick={() => setShowCampaignMenu((prevState) => !prevState)}
              >
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.65"
                    d="M8.65625 0L8.65625 3.42L4.32811 7.6322L-2.2084e-05 3.42L-2.19345e-05 -3.78378e-07L4.32811 4.23153L8.65625 0Z"
                    fill="black"
                  />
                </svg>
                <h3 className="text-black text-xl font-semibold">
                  Campaigns OnDemand
                </h3>
                <div className="w-20 self-center">
                  <img src={icon01} alt="" width="100%" height="100%" />
                </div>
              </div>
              <div
                className={`absolute -bottom-[calc(110%+5rem)] w-full flex flex-col transition-all duration-300 ${
                  showCampaignMenu
                    ? "opacity-100 pointer-events-auto"
                    : "opacity-0 pointer-events-none"
                } `}
              >
                {showCampaignMenu && (
                  <>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 1
                    </a>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 2
                    </a>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 3
                    </a>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 4
                    </a>
                  </>
                )}
              </div>
            </div>
            <div className="relative">
              <div
                className="flex p-2 cursor-pointer"
                onClick={() => setShowSalesMenu((prevState) => !prevState)}
              >
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 9 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.65"
                    d="M8.65625 0L8.65625 3.42L4.32811 7.6322L-2.2084e-05 3.42L-2.19345e-05 -3.78378e-07L4.32811 4.23153L8.65625 0Z"
                    fill="black"
                  />
                </svg>
                <h3 className="text-black text-xl font-semibold">
                  Sales Team OnDemand
                </h3>
                <div className="w-20 self-center">
                  <img src={icon02} alt="" width="100%" height="100%" />
                </div>
              </div>
              <div
                className={`absolute -bottom-[calc(110%+5rem)] w-full flex flex-col transition-all duration-300 ${
                  showSalesMenu
                    ? "opacity-100 pointer-events-auto"
                    : "opacity-0 pointer-events-none"
                } `}
              >
                {showSalesMenu && (
                  <>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 1
                    </a>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 2
                    </a>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 3
                    </a>
                    <a
                      href="/"
                      className="p-2 text-sm bg-white hover:bg-gray-200"
                    >
                      Demo link 4
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 border rounded-lg flex flex-col gap-4 items-center justify-between">
          {/* <img
						src={MultiMediaAssets}
						alt=''
						className='pt-4 pl-4 pr-4'
					/> */}
          <h2 className="text-center text-4xl text-black font-semibold mt-8">
            Multimedia Sales Assets
          </h2>
          <div className="grid grid-cols-3 gap-y-8  ">
            <a
              href=""
              className="px-4 border-r border-[#0000004D] overflow-hidden cursor-pointer"
            >
              <img src={image301} alt="" width="100%" height="100%" />
            </a>
            <a
              href=""
              className="px-4 border-[#0000004D] overflow-hidden cursor-pointer"
            >
              <img src={image302} alt="" width="100%" height="100%" />
            </a>
            <a
              href=""
              className="px-4 border-l border-[#0000004D] overflow-hidden cursor-pointer"
            >
              <img src={image303} alt="" width="100%" height="100%" />
            </a>
            <a
              href=""
              className="px-4 border-r border-[#0000004D] overflow-hidden cursor-pointer"
            >
              <img src={image304} alt="" width="100%" height="100%" />
            </a>
            <a
              href=""
              className="px-4 border-[#0000004D] overflow-hidden cursor-pointer"
            >
              <img src={image305} alt="" width="100%" height="100%" />
            </a>
            <a
              href=""
              className="px-4 border-l border-[#0000004D] overflow-hidden cursor-pointer"
            >
              <img src={image306} alt="" width="100%" height="100%" />
            </a>
          </div>
          <div className="flex items-center justify-center gap-2">
            <h3 className="text-black text-xl font-semibold">
              #SalesExperience
            </h3>
            <button onClick={() => setShowOverlay(true)}>
              <svg
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8914 34.1998C27.8384 34.1998 35.0914 26.9468 35.0914 17.9998C35.0914 9.05279 27.8384 1.7998 18.8914 1.7998C9.94439 1.7998 2.69141 9.05279 2.69141 17.9998C2.69141 26.9468 9.94439 34.1998 18.8914 34.1998Z"
                  fill="#84CC16"
                  stroke="#84CC16"
                  stroke-width="3.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.0587 12.6207L24.0033 17.346C24.3501 17.5523 24.4706 18.0122 24.2724 18.3732C24.2084 18.4899 24.1154 18.5866 24.0033 18.6533L16.0587 23.3787C15.7118 23.585 15.27 23.4596 15.0719 23.0986C15.0094 22.9848 14.9766 22.8561 14.9766 22.725V13.2743C14.9766 12.8585 15.3004 12.5215 15.6998 12.5215C15.8257 12.5215 15.9494 12.5557 16.0587 12.6207Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-4 border rounded-lg flex flex-col items-center justify-between">
          <img src={ContentMachiene} alt="" className="w-[90%] pt-4" />
          <img src={PersonalizedOutReach} alt="" className="w-[90%]" />
          <img src={ClientFeedback} alt="" className="w-[90%]" />
          {/*  */}
        </div>

        <div className="p-4 border col-span-2 rounded-lg flex flex-col gap-4 items-center justify-center">
          <div className="flex items-center w-full justify-between pl-4 pr-4 gap-3">
            <h2 className="text-black font-semibold">
              Next-Gen Virtual Experience
            </h2>
            <div className="flex items-center justify-center gap-2">
              <h3 className="text-black text-xl font-semibold">Live</h3>
              <button onClick={() => setShowOverlay(true)}>
                <svg
                  width="37"
                  height="36"
                  viewBox="0 0 37 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.8914 34.1998C27.8384 34.1998 35.0914 26.9468 35.0914 17.9998C35.0914 9.05279 27.8384 1.7998 18.8914 1.7998C9.94439 1.7998 2.69141 9.05279 2.69141 17.9998C2.69141 26.9468 9.94439 34.1998 18.8914 34.1998Z"
                    fill="#84CC16"
                    stroke="#84CC16"
                    stroke-width="3.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.0587 12.6207L24.0033 17.346C24.3501 17.5523 24.4706 18.0122 24.2724 18.3732C24.2084 18.4899 24.1154 18.5866 24.0033 18.6533L16.0587 23.3787C15.7118 23.585 15.27 23.4596 15.0719 23.0986C15.0094 22.9848 14.9766 22.8561 14.9766 22.725V13.2743C14.9766 12.8585 15.3004 12.5215 15.6998 12.5215C15.8257 12.5215 15.9494 12.5557 16.0587 12.6207Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="p-4 border col-span-2 rounded-lg flex flex-col gap-4 items-center justify-center relative">
          <div
            className="flex w-full items-center justify-between pl-4 pr-4 gap-3 cursor-pointer"
            onClick={() => setShowBTSMenu((prevState) => !prevState)}
          >
            <h2 className="text-black font-semibold">Behind The Scenes</h2>
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 0L22 8.69197L11 19.3973L-2.32681e-05 8.69197L-2.28882e-05 -9.61652e-07L11 10.7545L22 0Z"
                fill="#84CC16"
              />
            </svg>
          </div>
          <div
            className={`absolute -bottom-[calc(110%+8rem)] w-full flex flex-col transition-all duration-300 ${
              showBTSMenu
                ? "opacity-100 pointer-events-auto"
                : "opacity-0 pointer-events-none"
            } `}
          >
            {showBTSMenu && (
              <>
                <a href="/" className="p-4 bg-white hover:bg-gray-200">
                  Demo link 1
                </a>
                <a href="/" className="p-4 bg-white hover:bg-gray-200">
                  Demo link 2
                </a>
                <a href="/" className="p-4 bg-white hover:bg-gray-200">
                  Demo link 3
                </a>
                <a href="/" className="p-4 bg-white hover:bg-gray-200">
                  Demo link 4
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full my-10 bg-[#F4F4F4] p-6">
        <h2 className="text-black text-4xl text-center font-medium">
          We value highly efficient clients {">>"}{" "}
          <span className="text-[#84CC16] font-semibold">
            Sign up today with a 10% Bonus Incentive
          </span>
        </h2>
      </div>
      {showOverlay && (
        <div>
          {<VideoEmbed videoPath={IntroVideo} onClose={handleCloseOverlay} />}
        </div>
      )}
    </div>
  );
};

export default LiveSalesExperience;
