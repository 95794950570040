import { FC } from "react";

interface IconProps {
  size?: number;
  color?: string;
}

export const YoutubeIcon: FC<IconProps> = ({
  color = "currentColor",
  size = 1,
}) => (
  <svg
    width={33 * size}
    height={23 * size}
    viewBox="0 0 33 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1239 15.7486V6.5292C16.3968 8.06927 18.9316 9.557 21.9296 11.161C19.4569 12.5323 16.3968 14.0709 13.1239 15.7486ZM31.3638 1.94397C30.7992 1.20015 29.837 0.621146 28.8126 0.429457C25.8015 -0.142339 7.01662 -0.143965 4.00718 0.429457C3.18568 0.583459 2.45416 0.955702 1.82576 1.53405C-0.822024 3.9916 0.00766586 17.1707 0.645884 19.3055C0.914261 20.2295 1.2612 20.8959 1.69814 21.3334C2.26108 21.9117 3.03184 22.3099 3.91716 22.4885C6.39639 23.0013 19.1689 23.288 28.7602 22.5655C29.6439 22.4115 30.4261 22.0005 31.043 21.3975C33.4911 18.9498 33.3243 5.03065 31.3638 1.94397Z"
      fill={color}
    />
  </svg>
);

export const InstagramIcon: FC<IconProps> = ({
  color = "currentColor",
  size = 1,
}) => (
  <svg
    width={25 * size}
    height={25 * size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4286 1H7.57143C3.94213 1 1 3.94213 1 7.57143V17.4286C1 21.0578 3.94213 24 7.57143 24H17.4286C21.0578 24 24 21.0578 24 17.4286V7.57143C24 3.94213 21.0578 1 17.4286 1Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5067 17.4284C9.78465 17.4284 7.57812 15.2219 7.57812 12.4999C7.57812 9.77781 9.78465 7.57129 12.5067 7.57129C15.2287 7.57129 17.4353 9.77781 17.4353 12.4999C17.4353 13.8069 16.916 15.0606 15.9917 15.9849C15.0674 16.9091 13.8138 17.4284 12.5067 17.4284Z"
      stroke={color}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.0714 5.10714C18.6178 5.10714 18.25 5.47491 18.25 5.92857C18.25 6.38223 18.6178 6.75 19.0714 6.75C19.5251 6.75 19.8929 6.38223 19.8929 5.92857C19.8929 5.47491 19.5251 5.10714 19.0714 5.10714Z"
      fill="white"
      stroke={color}
      stroke-linecap="round"
    />
  </svg>
);

export const FacebookIcon: FC<IconProps> = ({
  color = "currentColor",
  size = 1,
}) => (
  <svg
    width={24 * size}
    height={24 * size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7187 0C5.27343 0 0 5.26171 0 11.7422C0 17.6016 4.28906 22.4648 9.89061 23.3437V15.1406H6.91405V11.7422H9.89061V9.15233C9.89061 6.21093 11.6367 4.59374 14.3203 4.59374C15.5976 4.59374 16.9336 4.8164 16.9336 4.8164V7.71093H15.457C14.0039 7.71093 13.5469 8.61327 13.5469 9.53907V11.7422H16.8047L16.2773 15.1406H13.5469V23.3437C16.3083 22.9076 18.8229 21.4986 20.6366 19.3711C22.4503 17.2437 23.4438 14.5378 23.4375 11.7422C23.4375 5.26171 18.164 0 11.7187 0Z"
      fill={color}
    />
  </svg>
);

export const LinkedInIcon: FC<IconProps> = ({
  color = "currentColor",
  size = 1,
}) => (
  <svg
    width={23 * size}
    height={23 * size}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1581 0.00206269H1.96919C1.72187 -0.0095225 1.47467 0.0277776 1.24177 0.111849C1.00887 0.195921 0.794851 0.325097 0.611931 0.491975C0.429026 0.658868 0.280819 0.8602 0.175819 1.08443C0.0708194 1.30867 0.0110677 1.55142 0 1.79877V21.0308C0.0144887 21.5484 0.226615 22.041 0.592814 22.4071C0.959012 22.7733 1.45151 22.9855 1.96919 23H21.1581C21.6613 22.9776 22.1358 22.7588 22.4797 22.3907C22.8237 22.0226 23.0097 21.5345 22.9979 21.0308V1.79877C23.0021 1.55801 22.9569 1.31897 22.8654 1.09626C22.7738 0.873568 22.6377 0.671919 22.4654 0.503675C22.2932 0.335446 22.0883 0.204186 21.8635 0.117929C21.6387 0.0316729 21.3987 -0.00775454 21.1581 0.00206269ZM7.18684 19.1766H3.83777V8.92816H7.18684V19.1766ZM5.59136 7.37576C5.35766 7.38391 5.12479 7.34364 4.90741 7.25748C4.69 7.17133 4.49277 7.04114 4.3281 6.87511C4.16342 6.70908 4.03485 6.5108 3.95048 6.29271C3.86609 6.07461 3.82773 5.84143 3.83777 5.6078C3.82744 5.37164 3.86658 5.13594 3.95269 4.91581C4.0388 4.69566 4.16998 4.49595 4.33779 4.32949C4.50562 4.16301 4.70638 4.03346 4.9272 3.94915C5.14804 3.86482 5.38405 3.82759 5.62011 3.83984C5.85381 3.8317 6.08668 3.87196 6.30407 3.95812C6.52147 4.04427 6.71871 4.17446 6.88337 4.34049C7.04805 4.50652 7.17662 4.7048 7.261 4.92289C7.34538 5.14099 7.38375 5.37417 7.3737 5.6078C7.38404 5.84396 7.3449 6.07966 7.25878 6.29979C7.17267 6.51994 7.0415 6.71965 6.87368 6.88611C6.70586 7.05259 6.5051 7.18213 6.28428 7.26645C6.06344 7.35078 5.82742 7.38801 5.59136 7.37576ZM19.1745 19.1766H15.8111V13.5709C15.8111 12.2341 15.3367 11.3142 14.1437 11.3142C13.7732 11.3174 13.4125 11.4351 13.1115 11.6513C12.8104 11.8674 12.5836 12.1714 12.462 12.5216C12.3686 12.784 12.3295 13.0628 12.347 13.3409V19.1766H9.04105V8.92816H12.347V10.3655C12.6409 9.82551 13.0783 9.37734 13.611 9.07032C14.1437 8.76344 14.7508 8.60976 15.3655 8.62627C17.5359 8.62627 19.1745 10.0637 19.1745 13.1253V19.1766Z"
      fill={color}
    />
  </svg>
);
