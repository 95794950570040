/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useRef, useState } from 'react';
import Loading from '../../Loading';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PitchDeck.css';
import VideoEmbed from 'Components/YoutubeEmbed/VideoEmbed';
import ReactPlayer from 'react-player';

const PitchDeckV2 = () => {

	const [isLoading, setIsLoading] = useState(true);
	const [videoPlayerIsOpen, setVideoPlayerIsOpen] = useState(false);
	const [videoPlayerLink, setVideoPlayerLink] = useState("https://www.youtube.com/watch?v=l8pl0dujUbU");

	const playerWrapperRef = useRef(null);

	const handleClickOutside = (event: any) => {
		if (playerWrapperRef.current && !(playerWrapperRef.current as HTMLElement).contains(event.target)) {
			setVideoPlayerIsOpen(false);
		}

	};

	useEffect(() => {
		if (videoPlayerIsOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [videoPlayerIsOpen]);


	const sliderRef = useRef<Slider>(null);
	useEffect(() => {
		window.scrollTo(0, 0);
		// Simulate loading for 1 second
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2200);
		document.addEventListener('keydown', handleKeyDown as any);
		// Clean up the timer on component unmount
		return () => {
			document.removeEventListener('keydown', handleKeyDown as any);
			clearTimeout(timer)
		};
	}, []);

	// handle keyboard input here //
	const handleKeyDown = (e: React.KeyboardEvent) => {
		const keyboardEvent = e as any;

		if (keyboardEvent.key === 'ArrowLeft') {
			sliderRef.current?.slickPrev();
		} else if (keyboardEvent.key === 'ArrowRight') {
			sliderRef.current?.slickNext();
		}
	};

	const CustomPrevArrow = (props: any) => (
		<div
			{...props}
			className=' w-3 absolute cursor-pointer z-50  left-[-25px] top-[50%] '>
			<svg
				className='hidden lg:flex'
				width='18'
				height='18'
				viewBox='0 0 22 23'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M21.3754 22.55L10.6254 22.55L0.47539 11.5L10.6254 0.449999L21.3754 0.45L11.1754 11.5L21.3754 22.55Z'
					fill='black'
				/>
			</svg>
		</div>
	);

	const CustomNextArrow = (props: any) => (
		<div
			{...props}
			className=' w-3 absolute cursor-pointer z-50 right-[-25px] top-[50%] '>
			{/* Your custom next arrow content, e.g., an SVG or an icon */}
			<svg
				className='hidden lg:flex'
				width='18'
				height='18'
				viewBox='0 0 22 23'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M0.624609 0.449998H11.3746L21.5246 11.5L11.3746 22.55H0.624609L10.8246 11.5L0.624609 0.449998Z'
					fill='black'
				/>
			</svg>
		</div>
	);
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
	};

	const data = [
		{
			img: require("Assets/PitchDeckV2/slide-1.png"),
			clickableElements: [
				{
					classes: "w-[30%] h-[38%] bottom-[5%] left-[10%]",
					isVideo: true,
					link: "https://www.youtube.com/watch?v=l8pl0dujUbU"
				},
			]
		},
		{ img: require("Assets/PitchDeckV2/slide-2.jpg") },
		{ img: require("Assets/PitchDeckV2/slide-3.jpg") },
		{
			img: require("Assets/PitchDeckV2/slide-4.jpg"),
			clickableElements: [
				{
					classes: "w-[100%] h-[100%] bottom-[5%]",
					isVideo: true,
					link: "https://www.youtube.com/watch?v=XfWqXLIWyp8"
				},
			]
		},
		{
			img: require("Assets/PitchDeckV2/slide-5.jpg"),
			clickableElements: [
				{
					classes: "w-[100%] h-[100%] bottom-[5%]",
					isVideo: false,
					link: "https://www.salesmrkt.com"
				},
			]
		},
		{
			img: require("Assets/PitchDeckV2/slide-6.jpg"),
			clickableElements: [
				{
					classes: "w-[13%] h-[5%] top-[16.5%] left-[55%]",
					isVideo: false,
					link: "https://salesmrkt.com"
				},
			]
		},
		{ img: require("Assets/PitchDeckV2/slide-7.jpg") },
		{ img: require("Assets/PitchDeckV2/slide-8.jpg") },
		{ img: require("Assets/PitchDeckV2/slide-9.jpg") },
		{
			img: require("Assets/PitchDeckV2/slide-10.jpg"),
			clickableElements: [
				{
					classes: "w-[17%] h-[10%] bottom-[21%] right-[16.5%]",
					isVideo: false,
					link: "https://www.forrester.com/press-newsroom/forrester-us-b2b-e-commerce-will-reach-an-estimated-3-trillion-by-2027/#:~:text=Trillion%20By%202027-,Forrester%3A%20US%20B2B%20E%2DCommerce%20Will%20Reach%20An,Estimated%20%243%20Trillion%20By%202027&text=While%20US%20B2B%20e%2Dcommerce,and%20%243%20trillion%20by%202027"
				},
			]
		},
		{
			img: require("Assets/PitchDeckV2/slide-11.jpg"),
			clickableElements: [
				{
					classes: "w-[20%] h-[10%] top-[33%] left-[24%]",
					isVideo: false,
					link: "https://docs.google.com/document/d/1rI-xL06dDj7lc7fVviHLqcKOVKRJNiI76rLXSwj3KRo"
				},
			]
		},
		{ img: require("Assets/PitchDeckV2/slide-12.png") },
		{ img: require("Assets/PitchDeckV2/slide-13.png") },
		{
			img: require("Assets/PitchDeckV2/slide-14.png"),
			clickableElements: [
				{
					classes: "w-[15%] h-[10%] bottom-[28%] right-[10%]",
					isVideo: false,
					link: "https://salesmrkt.com/boa"
				},
			]
		},
		{
			img: require("Assets/PitchDeckV2/slide-15.png"),
			clickableElements: [
				{
					classes: "w-[15%] h-[10%] top-[19%] right-[43.5%]",
					isVideo: false,
					link: "https://docs.google.com/spreadsheets/d/11Uxd-z_79h9rRruxmOdoAS8UDsat6zE2/edit?gid=1698675998#gid=1698675998"
				},
			]
		},
		{ img: require("Assets/PitchDeckV2/slide-16.png") },
	]

	return (
		<div className='w-full h-screen outline-none  flex items-center justify-center'>
			<div
				tabIndex={0}
				className='w-full h-full  '>
				<Loading isLoading={isLoading} />

				<div className='w-full flex pl-5 pr-5  items-center justify-center '>
					<div className='w-[93%] h-fit'>
						<Slider
							ref={sliderRef}
							{...settings}
							className='your-slider-class  border-none outline-none '>
							{
								data.map((elem, index) => {
									return (
										<div className='relative'>
											<img
												key={index}
												src={elem.img}
												className='max-h-[97vh] w-full border-none outline-none aspect-video  object-contain 2xl:object-contain'
												alt=''
											/>
											{
												elem.clickableElements &&
												(
													elem.clickableElements.map((clickable) => {
														if (clickable.isVideo) {
															return (<div onClick={() => {
																setVideoPlayerLink(clickable.link);
																setVideoPlayerIsOpen(true)
															}} className={`absolute cursor-pointer z-10 ${clickable.classes}`} />)

														} else {
															return (<a href={clickable.link} target='_blank' rel="noreferrer" className={`absolute z-10 ${clickable.classes}`} />)
														}
													})
												)
											}

										</div>
									)
								})
							}


						</Slider>
						{videoPlayerIsOpen && (
							<div className='absolute z-50 top-0 left-0 outline-none bg-black h-screen w-screen bg-opacity-30 flex items-center justify-center'>
								<div ref={playerWrapperRef}>
									<ReactPlayer
										height={"500px"}
										width={"850px"}
										url={videoPlayerLink}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PitchDeckV2;
