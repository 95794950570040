import React, { useEffect, useRef, useState } from "react";
import Google from "../../Assets/Icons/LoginIcons/icons8-google.svg";
import Microsoft from "../../Assets/Icons/LoginIcons/icons8-microsoft.svg";
import Apple from "../../Assets/Icons/LoginIcons/icons8-apple.svg";
import { GoLocation } from "react-icons/go";
import "./Login.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios, { AxiosError } from "axios";
import { Navigate } from "react-router";
interface LoginProps {
  LoginMenuOpener: boolean;
  setLoginMenuOpener: React.Dispatch<React.SetStateAction<any>>;
}
const LoginFullPagePaul: React.FC<LoginProps> = ({
  LoginMenuOpener,
  setLoginMenuOpener,
}) => {
  const [city, setCity] = useState("Seattle");
  const [date, setDate] = useState("");
  const [monthDate, setMonthDate] = useState("");
  const current = new Date();
  const loginRef = useRef<HTMLDivElement | null>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  //   "body": "{\"email\":\"eric@salesbox.io\", \"password\":\"K394X3NRFbrLsGf\"}",

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");
    try {
      const response = await axios.post(
        "https://36coehmhwe.execute-api.eu-north-1.amazonaws.com/default/shopifylogin",
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // Add any other headers as needed
          },
        }
      );
      const data = response.data;
      // console.log("login data", data);

      if (data) {
        if (data.accessToken) {
          window.location.replace(
            `https://salesboxmarket.myshopify.com?token=${data.accessToken}`
          );
        } else {
          setErrorMessage("Something went wrong, try again");
        }
      } else {
        setErrorMessage("Something went wrong, try again");
      }

      //   if (data.customerAccessTokenCreate.customerAccessToken) {
      //     // Login successful
      //     console.log(
      //       "Login successful:",
      //       data.customerAccessTokenCreate.customerAccessToken
      //     );
      //     const { customerAccessToken } = data.customerAccessTokenCreate;
      //     if (customerAccessToken) {
      //       // Redirect or perform other actions here
      //       Navigate({ to: "/" });
      //     } else if (
      //       data.customerAccessTokenCreate.customerUserErrors.length > 0
      //     ) {
      //       // Handle specific user errors
      //       console.log(
      //         "Login Failed",
      //         data.customerAccessTokenCreate.customerUserErrors
      //       );
      //       alert(
      //         `Login failed. ${data.customerAccessTokenCreate.customerUserErrors[0].message}`
      //       );
      //     } else {
      //       // Handle other errors
      //       console.log("Failed for other reason");
      //     }
      //   } else if (data.customerAccessTokenCreate.customerUserErrors.length > 0) {
      //     // Handle specific user errors
      //     console.log(
      //       "Login Failed",
      //       data.customerAccessTokenCreate.customerUserErrors
      //     );
      //     alert(
      //       `Login failed. ${data.customerAccessTokenCreate.customerUserErrors[0].message}`
      //     );
      //   } else {
      //     // Handle other errors
      //     console.log("Failed for other reason");
      //   }
    } catch (error: unknown) {
      // Handle network or unexpected errors
      const err = error as AxiosError;
      // console.error("Login Error:", error);
      if (err.response?.status === 400) {
        setErrorMessage("Incorrect email/password");
      } else {
        setErrorMessage("Something went wrong!,");
      }
    }
  };
  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = months[current.getMonth()]; // Get the month name
    setDate(
      `${current.toLocaleTimeString([], {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })}`
    );
    setMonthDate(`${month} ${current.getDate()}`);
  }, [current]);
  return (
    <div
      className={`w-full h-[100vh] mt-[80px]  z-[999] fixed top-0  ${
        LoginMenuOpener ? "login-visible" : "login-hidden"
      }`}
    >
      <div className="w-full h-[100vh]  grid grid-cols-2">
        <div className="flex flex-col bg-white relative items-center justify-center">
          <AiOutlineCloseCircle
            onClick={() => {
              setLoginMenuOpener(false);
            }}
            className="absolute text-3xl cursor-pointer hover:scale-110 hover:text-lime-500 transition-all text-black top-[40px] right-[20px]"
          />
          <h2 className="text-black text-5xl mb-2">
            Login to{" "}
            <span className="text-[#6DC939] font-semibold">Salesmrkt</span>{" "}
          </h2>
          <form
            onSubmit={handleSubmit}
            className="max-w-[550px]   lg:scale-100 flex relative  transition-all duration-300 ease-in   w-full bg-white md:bg-white rounded-[20px] p-6   cursor-pointer   flex-col items-center gap-4"
          >
            <input
              className="h-7 w-full border-t-0 border-b-1 border-slate-600 p-5 outline-1 mt-3 outline text-black outline-slate-300 rounded-sm "
              type="email"
              name="Email"
              placeholder="Email"
              required
              id="#email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="h-7 w-full border-t-0 border-b-1 border-slate-600 p-5 outline-1 outline text-black outline-slate-300 rounded-sm "
              type="password"
              name="password"
              id="#password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage && (
              <p className="w-full text-[#ff0000]">{errorMessage}</p>
            )}
            <button
              type="submit"
              className="p-2  rounded w-full text-center  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300"
            >
              <span className="relative ">Login</span>
            </button>
            <div className="h-[1px] border-b w-full"></div>
            <a
              href="https://salesboxmarket.myshopify.com/account/register?from=salesmrkt"
              target="_blank"
              rel="noopener noreferrer"
              className=" p-2  rounded w-full text-center font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300"
            >
              <span className="relative ">Sign Up</span>
            </a>
            <div className="w-full flex justify-between  mb-1">
              <div className="flex select-none  items-center w-1/2 ">
                <label
                  htmlFor="#Remember"
                  className="flex items-center cursor-pointer  gap-2"
                >
                  <input
                    className="w-5 h-5 cursor-pointer"
                    type="checkbox"
                    name="Remember"
                    id="#Remember"
                  />
                  <h4 className="text-sm font-normal text-blue-400 transition-all duration-75 ease-in">
                    Remember Me
                  </h4>
                </label>
              </div>
              <a
                className="text-sm text-black underline transition-all duration-75 ease-in"
                href="https://salesboxmarket.myshopify.com/account/login?return_url=%2Faccount&from=salesmrkt"
              >
                Forgot Password
              </a>
            </div>
            <div className="w-full flex items-center justify-center  gap-6">
              <a
                href="/"
                className="w-[48px] h-[48px] border-2 rounded-lg flex items-center justify-center "
              >
                <img
                  src={Google}
                  alt="Google Icon"
                  className=" p-1 hover:scale-110 transition-all duration-75 ease-in"
                />
              </a>
              <a
                href="/"
                className="w-[48px] h-[48px] border-2 rounded-lg flex items-center justify-center"
              >
                <img
                  src={Microsoft}
                  alt="Microsoft Icon"
                  className=" p-1 hover:scale-110 transition-all duration-75 ease-in "
                />
              </a>
              <a
                href="/"
                className="w-[48px] h-[48px] border-2 rounded-lg flex items-center justify-center "
              >
                <img
                  src={Apple}
                  alt="Apple Icon"
                  className=" p-1 hover:scale-110 transition-all duration-75 ease-in"
                />
              </a>
            </div>
            <div className="flex text-black items-center justify-center gap-2">
              <GoLocation />
              <p>{`${city}`}</p>
              <p>-</p>
              <p>{`${monthDate}`}</p>
            </div>
          </form>
        </div>
        <div
          onClick={() => {
            setLoginMenuOpener(false);
          }}
          className="h-full cursor-pointer bg-black/90   w-full relative"
        ></div>
      </div>
    </div>
  );
};
export default LoginFullPagePaul;
