import React, { useState, useEffect, useRef } from 'react';
import { MdOutlineReplay } from 'react-icons/md';
import Thumbnail from '../../Assets/img/Featured Solution_Latest.00_00_00_00.Still001.jpg';
interface DivCompProps {
	text: string;
	textHidden: string;
	image: string;
	icon: string;
	myindex: number;
	scrolled: boolean;
	index: number;
	setScrolled: (value: boolean) => void;
}
const VideoComponentAdsOnDemandTester: React.FC<DivCompProps> = ({
	text,
	textHidden,
	image,
	icon,
	index,
	myindex,
	scrolled,
	setScrolled,
}) => {
	const [clicked, setClicked] = useState(false);
	const [inView, setInView] = useState(false);
	const [videoHasFinished, setVideoHasFinished] = useState(false);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const handleReplay = () => {
		if (videoRef.current) {
			videoRef.current.currentTime = 0; // Reset the video to 0
			videoRef.current.play(); // Start playing the video
			setVideoHasFinished(false);
			setClicked(false); // Reset the videoHasFinished state
		}
	};
	const handleClick = () => {
		setClicked(true);
		setScrolled(false);
		setTimeout(() => {
			setClicked(false);
		}, 10200);
	};
	// useEffect(() => {
	// 	const options = {
	// 		root: null,
	// 		rootMargin: '0px',
	// 		threshold: 0.3,
	// 	};

	// 	const callback: IntersectionObserverCallback = (entries) => {
	// 		const entry = entries[0];
	// 		setInView(entry.isIntersecting);
	// 	};

	// 	const observer = new IntersectionObserver(callback, options);

	// 	if (videoRef.current) {
	// 		observer.observe(videoRef.current);
	// 	}

	// 	return () => {
	// 		if (videoRef.current) {
	// 			observer.unobserve(videoRef.current);
	// 		}
	// 	};
	// }, []);
	useEffect(() => {
		if (index === 9 || index === 19 || index === 29 || index === 39) {
			if (videoRef.current) {
				setVideoHasFinished(false);
				videoRef.current.play();
			}
		} else {
			if (videoRef.current) {
				videoRef.current.currentTime = 0;
				videoRef.current.pause();
				setClicked(false);
			}
		}
	}, [index]);

	useEffect(() => {
		// Listen for the 'ended' event to detect when the video has finished
		if (videoRef.current) {
			videoRef.current.addEventListener('ended', () => {
				setVideoHasFinished(true);
			});
		}

		return () => {
			if (videoRef.current) {
				// Clean up event listener when component unmounts
				videoRef.current.removeEventListener('ended', () => {
					setVideoHasFinished(true);
				});
			}
		};
	}, [videoHasFinished]);
	// useEffect(() => {
	// 	// Delay autoplay of the video by 2300 milliseconds
	// 	const delayTimeout = setTimeout(() => {
	// 		if (videoRef.current) {
	// 			videoRef.current.play(); // Start playing the video after the delay
	// 		}
	// 	}, 2000); // delay of 2.8 seconds --> change accordingly

	// 	return () => {
	// 		clearTimeout(delayTimeout);
	// 	};
	// }, []);

	return (
		<span className='w-full h-full cursor-pointer    relative group  flex items-center  justify-center'>
			{videoHasFinished && !clicked && (
				<MdOutlineReplay
					onClick={handleReplay}
					className='text-lime-500 z-[9999999] animate-pulse text-6xl absolute top-[20px] right-[15px]'
				/>
			)}
			<a
				href='https://calendly.com/salesbox-meet/ads-ondemand-campaign'
				target='_blank'
				className={`${
					clicked
						? 'opacity-100 transition-opacity duration-500 ease-in'
						: 'opacity-0 transition-opacity duration-500 ease-in pointer-events-none'
				}`}
				rel='noopener noreferrer'>
				<p className='z-[999999]  p-2  rounded w-[320px] text-center hover:text-white font-semibold border-lime-500 border   overflow-hidden group  absolute hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 hover:ring-2 hover:ring-offset-2 hover:ring-lime-400  pl-6 pr-6 pt-2 pb-2  text-[#84CC16]  transition-all duration-200 ease-in  flex items-center justify-center gap-1 group lg:left-[170px] top-[78%] md:top-[85%] text-base md:text-lg left-[10px]   lg:top-[58%]     '>
					Schedule Free Consultation
				</p>
				<p className='absolute lg:left-[250px]   text-xs     lg:top-[69%]'>
					$500 OFF your first Purchase
				</p>
			</a>

			<div
				className={`${
					clicked
						? ' h-full  flex flex-col cursor-pointer  lg:p-1 overflow-visible items-center  justify-center  opacity-100 absolute transition-all duration-500 ease-in -z-10 rounded-md lg:rounded-[20px] '
						: ' h-full flex flex-col cursor-pointer  lg:p-1 overflow-visible items-center  justify-center  opacity-0 absolute transition-all duration-500 ease-in -z-10 rounded-md lg:rounded-[20px]'
				}`}>
				<img
					src={icon}
					className=' h-full     z-20   '
					alt='Salesmrkt Back Card'
				/>
			</div>
			<div
				onClick={handleClick}
				className={`${
					clicked
						? 'front  h-full w-full relative  - opacity-0 rounded-md lg:rounded-[20px]  transition-all duration-500 ease-in '
						: 'front  w-full h-full relative rounded-md lg:rounded-[20px] - opacity-100 transition-all duration-500 ease-in'
				}`}>
				<video
					ref={videoRef}
					muted
					playsInline
					disablePictureInPicture
					preload={'auto'}
					poster={Thumbnail}
					className='w-full h-full object-cover  rounded-md lg:rounded-[20px]    z-10 '
					src={image}></video>
			</div>
		</span>
	);
};
export default VideoComponentAdsOnDemandTester;
