import React, { useRef, useState, useEffect, RefObject } from 'react';
import LandingPage from './LandingPage';
import NavBar from '../NavBar/NavBar';
import UnderLanding from './UnderLanding';
import CoustomerServices from './CoustomerServices';
import UnderBanner from './UnderBanner';
import Footer from './Footer';
import ImageCarosel from './ImageCarosel';
import LeadingBrandsone from './LeadingBrandsone';
import Loading from '../../Loading';
import { useLocation } from 'react-router-dom';
import LoginFullPagePaul from '../LoginMenu/LoginFullPagePaul';
import UnderLandingAdsOnDemand from './UnderLandingAdsOnDemand';
import UnderLandingAdsOnDemandTester from './UnderLandingAdsOnDemandTester';
const HomeAdsOnDemandTester = () => {
	const [bundles, setBundles] = useState(false);
	const [navOne, setNavOne] = useState(false);
	const [navTwo, setNavTwo] = useState(false);
	const [navThree, setNavThree] = useState(false);
	const [navFour, setNavFour] = useState(true);
	const [exploreButton, setExploreButton] = useState(false);
	const [resources, setresources] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [shown, setIsShow] = useState(false);
	const [FooterSelected, setFooterSelected] = useState(19);
	const [isIdle, setIsIdle] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [popUpHasBeenShown, setPopUpHasBeenShown] = useState(false);
	const [LoginMenuOpener, setLoginMenuOpener] = useState(false);
	const [LetsTalkOpener, setLetsTalkOpener] = useState(false);

	let idleTimer: NodeJS.Timeout;
	const location = useLocation();

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2300);

		return () => clearTimeout(timer);
	}, []);
	useEffect(() => {
		const scrollIfNeeded = () => {
			const match = location.pathname.match(/AdsOnDemand/);
			if (match) {
				const targetElement = document.getElementById('Solutions');
				if (targetElement) {
					targetElement.scrollIntoView({ behavior: 'smooth' });
				}
			}
		};

		// Scroll to the target element if the URL matches on component mount
		scrollIfNeeded();
	}, [location]);

	return (
		<div className='h-full w-full overflow-hidden differentCursor'>
			<LoginFullPagePaul
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
			/>
			<Loading isLoading={isLoading} />
			<NavBar
				LetsTalkOpener={LetsTalkOpener}
				setLetsTalkOpener={setLetsTalkOpener}
				Four={navFour}
				setNumber={setFooterSelected}
				setFour={setNavFour}
				budnles={bundles}
				setBundles={setBundles}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
				One={navOne}
				Two={navTwo}
				Three={navThree}
				setExploreButton={setExploreButton}
				recources={resources}
				setRecources={setresources}
				isOpen={isOpen}
				setOpen={setOpen}
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
			/>
			<LandingPage
				clickedState={bundles}
				stateSetter={setBundles}
				loginMenu={LoginMenuOpener}
				setLoginMenu={setLoginMenuOpener}
			/>
			<UnderLandingAdsOnDemandTester
				numberId={FooterSelected}
				setNumber={setFooterSelected}
			/>
			<UnderBanner />
			<ImageCarosel
				Four={navFour}
				setFour={setNavFour}
				One={navOne}
				Two={navTwo}
				Three={navThree}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
			/>
			<LeadingBrandsone />
			<CoustomerServices
				One={navOne}
				Two={navTwo}
				Three={navThree}
				Four={navFour}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
				setFour={setNavFour}
				Explore={exploreButton}
				setExplore={setExploreButton}
			/>
			<Footer
				LetsTalkOpener={LetsTalkOpener}
				setLetsTalkOpener={setLetsTalkOpener}
				LoginMenuOpener={LoginMenuOpener}
				setLoginMenuOpener={setLoginMenuOpener}
				Four={navFour}
				setFour={setNavFour}
				numberId={FooterSelected}
				setNumber={setFooterSelected}
				recources={resources}
				budnles={bundles}
				setBundles={setBundles}
				setOne={setNavOne}
				setTwo={setNavTwo}
				setThree={setNavThree}
				One={navOne}
				Two={navTwo}
				Three={navThree}
				setExploreButton={setExploreButton}
				setRecources={setresources}
			/>
		</div>
	);
};

export default HomeAdsOnDemandTester;
