import { useEffect, useState } from "react";

interface UseScreenHookReturn {
  width: number;
}

// type UseScreenHook = () => UseScreenHookReturn | null;

const useScreen = (): UseScreenHookReturn | null => {
  const [screen, setScreen] = useState<UseScreenHookReturn | null>(null);

  const handleResize = () => {
    setScreen((prevState) => ({ ...prevState, width: window.innerWidth }));
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    handleResize()
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return screen;
};

export default useScreen
