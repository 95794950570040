import React from 'react';
import StackImage from '../../Assets/img/OverviewAssets/Capital Stack.svg';
const CapitalStack = () => {
	return (
		<div className='w-full   flex items-center justify-center '>
			<div className='w-[100%] mt-10  h-full '>
				<img
					src={StackImage}
					alt='Capital Stack'
					className='w-full h-full '
				/>
			</div>
		</div>
	);
};

export default CapitalStack;
