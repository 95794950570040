import { InlineWidget } from "react-calendly";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BsCameraVideo } from "react-icons/bs";
import logo from "Assets/SVG/SalesmrktLogo.svg";
import { ReactHTMLElement, useEffect, useRef, useState } from "react";

const calendlyLink = "https://calendly.com/salesbox-meet/all-in-one-marketplace-pipeline";

const CalendlyWidget = () => {
  return (
    <div className="border flex-col md:flex-row  flex rounded-2xl h-fit">
      <div className="hidden md:flex  border-r max-w-[350px] mr-2 flex-col items-center justify-start">
        <div className="flex w-fit items-center justify-center  mt-6 gap-3">
          <img src={logo} alt="Logo" />
        </div>
        <div className="flex flex-col w-fit items-start p-6">
          <h5 className="text-start mb-3">
            Revolutionize Your #SalesExperience
          </h5>
          <h3 className="text-xl max-w-[350px] text-black  font-bold">
            All-in-One Marketplace Sales, Marketing & Tech
          </h3>
          <div className="flex gap-4 items-center mt-10 justify-center">
            <AiOutlineClockCircle className="text-2xl" />
            <h5>30 min</h5>
          </div>
          <div className="flex gap-4 mt-3  items-center justify-center">
            <BsCameraVideo className="w-[20px]  h-[24px] " />
            <div>
              <h5>Web conferencing details</h5>
              <h5>provided upon confirmation.</h5>
            </div>
          </div>
          <div>
            <h4 className="text-black font-normal  text-start mt-16">
              Schedule an intro call to learn more about our complete
              suite of Sales, Business Development, Marketing, and Smart
              Tech solutions for
              <b> B2B Companies</b>.
            </h4>
          </div>
        </div>
      </div>
      <a
        href={calendlyLink}
        target="_blank"
        className="pl-1 pr-1 "
        rel="noopener noreferrer"
      >
        <div className="w-[400px]" id="calendly-widget">
          <InlineWidget
            url={calendlyLink}
            pageSettings={{
              backgroundColor: "fff",
              hideEventTypeDetails: true,
              hideLandingPageDetails: true,
              hideGdprBanner: true,
              primaryColor: "70C242",
              textColor: "#4d5055",
            }}
          />
        </div>
      </a>
    </div>
  )
}

const AdsLandingPage = () => {
  const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState(false);
  const calendlyModalRef = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (calendlyModalRef.current && !calendlyModalRef.current.contains(event.target)) {
      setCalendlyModalIsOpen(false);
    }
  };

  useEffect(() => {
    if (calendlyModalIsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendlyModalIsOpen]);


  const renderScheduleCallBtn = () => {
    return (
      <button className="bg-lime-500 w-full sm:max-w-[150px] h-12 text-center px-5 text-[14px] font-semibold rounded-sm text-white border border-lime-500 hover:bg-transparent hover:text-lime-500"
        onClick={() => setCalendlyModalIsOpen(true)}>
        Schedule Call
      </button>
    )
  }

  return (
    <div className="h-full w-full">

      {
        calendlyModalIsOpen && (
          <div className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 flex justify-center items-center md:p-[10%]">
            <div className="bg-white md:px-0 mx-auto rounded-lg" ref={calendlyModalRef}>
              <CalendlyWidget />
            </div>
          </div>
        )
      }

      <section className="bg-black w-full h-20 border-b border-b-gray-300 border-opacity-20 flex items-center justify-center">
        <a href="/">
          <img src={require('Assets/logo.png')} alt="Salesmrkt" height={30} width={160} />
        </a>
      </section>

      <section className="bg-black pt-10 sm:pt-20 px-10 w-full h-fit text-white flex flex-col items-center justify-center gap-5">
        <p className="capitalize text-3xl sm:text-[50px] font-semibold text-center text-balance leading-tight sm:leading-normal">
          Leads That Turn Into <p className="text-lime-500 inline">Revenue</p>
        </p>
        <p className="text-center text-balance w-full text-2xl sm:text-[25px]">
          Get up to 16-20 Sales Qualified Meetings a Month. <strong>Our Expert Team is Here to Help.</strong>
        </p>
        {renderScheduleCallBtn()}
        <img src={require('Assets/AdsLandingPage/box.png')} alt="" className="hidden sm:block relative right-7 sm:right-20 max-h-[450px] border-b border-b-white border-opacity-20" />
        <img src={require('Assets/AdsLandingPage/box-mobile.png')} alt="" className="block sm:hidden relative my-5 w-full border-b border-b-white border-opacity-20" />
      </section>

      <section className="flex flex-row w-full bg-[url('Assets/AdsLandingPage/phone-section-bg.png')] bg-cover px-[5%] sm:px-[10%] pt-[5%] gap-5 ">
        <div className="text-black pt-14 sm:w-[60%] flex flex-col gap-7 mb-10">
          <div className="text-3xl text-center sm:text-normal sm:text-[45px] font-bold leading-none sm:leading-tight text-wrap	">
            Ready to boost your revenue with a dedicated team?
          </div>
          <div className="text-lg sm:text-[24px] text-center sm:text-normal text-pretty">
            Salesmrkt’s Campaigns OnDemand™ is designed to be <strong>FULLY CUSTOMIZABLE</strong>. We build a sales strategy that aligns with your budget and business goals.
          </div>
          <div>
            <img src={require('Assets/AdsLandingPage/man-mobile.png')} alt="Man Mobile" width={"100%"} height={"100%"} className="sm:hidden block w-auto object-contain max-h-[800px] h-full" loading="lazy" />
          </div>
          <div className="flex flex-col sm:flex-row gap-10 my-5 w-auto">
            <div className="flex flex-col gap-4 sm:w-1/3 justify-center items-center text-center sm:justify-normal sm:items-start sm:text-left">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <circle cx="30" cy="30" r="30" fill="#212124" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4989 16C34.3118 16 38.2144 19.7637 38.2144 24.4072C38.2144 29.0505 34.3118 32.8144 29.4989 32.8144C24.6859 32.8144 20.7857 29.0507 20.7857 24.4072C20.7857 19.764 24.6883 16 29.4989 16ZM18.7062 38.81V42.3529H40.2897V38.81C40.2897 37.2151 38.9433 35.9139 37.2901 35.9139L21.7094 35.9151C20.0563 35.9151 18.7062 37.2164 18.7062 38.8112V38.81ZM17 43.1764V38.81C17 36.3072 19.1139 34.2677 21.7097 34.2677H37.2903C39.8835 34.2677 42 36.3083 42 38.81V43.1764C42 43.6316 41.6169 44 41.1451 44H17.8551C17.3846 44 17.0002 43.6316 17.0002 43.1764H17ZM34.4558 19.6276C31.7198 16.988 27.2813 16.988 24.5453 19.6276C21.8079 22.2671 21.8079 26.5478 24.5453 29.1886C27.2826 31.8282 31.7198 31.8282 34.4558 29.1886C37.1931 26.5478 37.1931 22.2684 34.4558 19.6276Z" fill="#45D3FF" />
              </svg>
              <div className="font-bold text-[18px]">Custom 3-12+ Person Sales Team</div>
              <div className="font-[18px]">
                Gain a full team of revenue growth experts who specialize in different sales strategies. From LinkedIn Outreach to Media Buying, we've got you covered.
              </div>
            </div>
            <div className="flex flex-col gap-4 sm:w-1/3 justify-center items-center text-center sm:justify-normal sm:items-start sm:text-left">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <circle cx="30" cy="30" r="30" fill="#212124" />
                <path d="M40.492 17.8972L36.4343 13.8046C35.9096 13.2799 35.21 13 34.4755 13H22.8272C22.2326 13 21.6728 13.2448 21.2531 13.6646C20.8334 14.0843 20.5885 14.6441 20.5885 15.2387V30.4549C20.5885 30.9447 20.9732 31.3294 21.463 31.3294C21.9527 31.3294 22.3375 30.9447 22.3375 30.4549V15.2387C22.3375 15.0988 22.4074 14.9589 22.4774 14.854C22.5824 14.749 22.7222 14.714 22.8622 14.714H33.9857V18.7367C33.9857 19.6462 34.7203 20.3458 35.5948 20.3458H39.6174V35.0723C39.6174 35.3521 39.3726 35.5969 39.0927 35.5969H32.0968C31.607 35.5969 31.2223 35.9817 31.2223 36.4714C31.2223 36.9612 31.607 37.3459 32.0968 37.3459H39.0927C40.317 37.3459 41.3315 36.3315 41.3315 35.1072V19.856C41.2965 19.0865 41.0166 18.4219 40.4919 17.8972L40.492 17.8972ZM35.6648 15.4836L38.8479 18.6668L35.6648 18.7367V15.4836Z" fill="#45D2FF" />
                <path d="M28.6338 41.8584C29.3684 40.8789 29.8232 39.6546 29.8232 38.3254C29.8232 35.0723 27.1647 32.4138 23.9116 32.4138C20.6585 32.4138 18 35.0723 18 38.3604C18 41.6135 20.6584 44.272 23.9116 44.272C25.2409 44.272 26.4301 43.8172 27.4445 43.0826L30.3479 45.986C30.5228 46.1609 30.7327 46.2308 30.9425 46.2308C31.1524 46.2308 31.3973 46.1609 31.5371 45.986C31.8869 45.6362 31.8869 45.1115 31.5371 44.7617L28.6338 41.8584ZM23.9116 42.558C21.6029 42.558 19.714 40.669 19.714 38.3604C19.714 36.0517 21.6029 34.1628 23.9116 34.1628C26.2202 34.1628 28.1091 36.0517 28.1091 38.3604C28.1091 40.669 26.2202 42.558 23.9116 42.558Z" fill="#45D2FF" />
              </svg>
              <div className="font-bold text-[18px]">Comprehensive Tools</div>
              <div className="font-[18px]">
                We include all the tools necessary for company growth. From Sales Navigator to Apollo, to your CRM, we've got you covered.
              </div>
            </div>
            <div className="flex flex-col gap-4 sm:w-1/3 justify-center items-center text-center sm:justify-normal sm:items-start sm:text-left">
              <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <circle cx="30" cy="30" r="30" fill="#212124" />
                <path d="M34.4443 19.0095C34.1708 18.9693 33.8924 19.059 33.6845 19.2415C33.4765 19.4247 33.3572 19.6883 33.3572 19.9657V23.5271C33.3572 24.0597 33.7889 24.4914 34.3215 24.4914C34.8541 24.4914 35.2858 24.0597 35.2858 23.5271V21.081C39.3585 21.7563 41.0715 22.973 41.0715 23.5271C41.0715 24.547 36.6781 26.42 29.5001 26.42C22.322 26.42 17.9286 24.547 17.9286 23.5271C17.9286 22.973 19.6415 21.7563 23.7143 21.081V23.5271C23.7143 24.0597 24.146 24.4914 24.6786 24.4914C25.2112 24.4914 25.6429 24.0597 25.6429 23.5271V19.9657C25.6429 19.6883 25.5236 19.4248 25.3157 19.2415C25.1071 19.059 24.8288 18.9693 24.5559 19.0095C21.9834 19.339 16 20.4398 16 23.5271C16 38.8491 16.0016 35.4808 16 37.0269C16 40.1924 22.7915 41.8483 29.5 41.8483C36.2085 41.8483 43 40.1923 43 37.0269V23.5271C43 20.4398 37.0166 19.3389 34.4444 19.0095H34.4443ZM41.0714 37.0269C41.0714 38.0467 36.678 39.9197 29.4999 39.9197C22.3219 39.9197 17.9285 38.0467 17.9285 37.0269V26.1294C20.419 27.5919 24.974 28.3485 29.4999 28.3485C34.0259 28.3485 38.5808 27.5919 41.0714 26.1294V37.0269Z" fill="#45D2FF" />
                <path d="M25.6428 31.5664V36.7025C25.6428 38.3074 27.3759 39.3177 28.773 38.5189L33.2676 35.9511C34.6723 35.1481 34.6742 33.1219 33.2673 32.3178L28.7729 29.75C27.3769 28.9509 25.6428 29.9608 25.6428 31.5664H25.6428ZM32.3106 34.2763L27.8162 36.8441C27.7062 36.9068 27.5713 36.8297 27.5713 36.7021V31.5664C27.5713 31.4381 27.7065 31.362 27.8162 31.4243L32.3098 33.9922C32.4215 34.0552 32.4209 34.2134 32.3104 34.2764L32.3106 34.2763Z" fill="#45D2FF" />
              </svg>
              <div className="font-bold text-[18px]">Data & Reporting</div>
              <div className="font-[18px]">
                Keeping track of where your marketing dollars are going is essential to success. That's why we build customized reports for our clients, where they stay on track of their business.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center sm:items-start sm:justify-normal">
            {renderScheduleCallBtn()}
          </div>
        </div>

        <div className="w-full hidden sm:flex  justify-center max-w-[40%]">
          <img src={require('Assets/AdsLandingPage/man-mobile.png')} alt="Man Mobile" width={"100%"} height={"100%"} className=" w-auto object-contain max-h-[800px] h-full" loading="lazy" />
        </div>
      </section>

      <section className="w-full flex flex-col md:flex-row">
        <img src={require('Assets/AdsLandingPage/solutions.png')} alt="Solutions" className="md:w-[40%] h-auto max-h-[40vh] object-cover md:max-h-none" loading="lazy" />
        <div className="md:px-[5%] py-[5%] w-full">
          <div className="text-[40px] font-semibold text-center md:text-left">Why Salesmrkt?</div>

          <div className="flex flex-col w-[90%] mx-auto sm:w-full rounded-md border border-gray-300 border-opacity-50 mt-5 max-w-[1300px] ">
            <div className="grid grid-cols-4 bg-[#C3F0FF] p-5">
              <div className="col-span-2"></div>
              <div className="font-bold text-xs md:text-[19px] text-center">Salesmrkt</div>
              <div className="font-bold text-xs md:text-[19px] text-center">Competition</div>
            </div>

            <div className="grid grid-cols-4 p-5 border-b border-b-gray-300 border-opacity-50">
              <div className="col-span-2 font-semibold sm:px-[5%] text-xs sm:text-[19px]">
                LinkedIn Outreach
              </div>
              <div className="font-bold text-sm sm:text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path d="M5.87123 12.166L0 6.39878L1.72423 4.71393L5.87119 8.79544L14.8658 0L16.59 1.68588L5.87123 12.166Z" fill="#007335" />
                </svg>
              </div>
              <div className="font-bold text-sm sm:text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                  <line x1="1.06066" y1="1.94965" x2="13.0815" y2="13.9705" stroke="#F0635B" stroke-width="3" />
                  <line y1="-1.5" x2="17" y2="-1.5" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0.489624 13.0208)" stroke="#F0635B" stroke-width="3" />
                </svg>
              </div>

            </div>
            <div className="grid grid-cols-4 p-5 border-b border-b-gray-300 border-opacity-50">
              <div className="col-span-2 font-semibold sm:px-[5%] text-xs sm:text-[19px]">
                Apollo Email Campaigns
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path d="M5.87123 12.166L0 6.39878L1.72423 4.71393L5.87119 8.79544L14.8658 0L16.59 1.68588L5.87123 12.166Z" fill="#007335" />
                </svg>
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                  <line x1="1.06066" y1="1.94965" x2="13.0815" y2="13.9705" stroke="#F0635B" stroke-width="3" />
                  <line y1="-1.5" x2="17" y2="-1.5" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0.489624 13.0208)" stroke="#F0635B" stroke-width="3" />
                </svg>
              </div>

            </div>
            <div className="grid grid-cols-4 p-5 border-b border-b-gray-300 border-opacity-50">
              <div className="col-span-2 font-semibold sm:px-[5%] text-xs sm:text-[19px]">
                Cold Calling Team
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path d="M5.87123 12.166L0 6.39878L1.72423 4.71393L5.87119 8.79544L14.8658 0L16.59 1.68588L5.87123 12.166Z" fill="#007335" />
                </svg>
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                  <line x1="1.06066" y1="1.94965" x2="13.0815" y2="13.9705" stroke="#F0635B" stroke-width="3" />
                  <line y1="-1.5" x2="17" y2="-1.5" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0.489624 13.0208)" stroke="#F0635B" stroke-width="3" />
                </svg>
              </div>

            </div>
            <div className="grid grid-cols-4 p-5 border-b border-b-gray-300 border-opacity-50">
              <div className="col-span-2 font-semibold sm:px-[5%] text-xs sm:text-[19px]">
                Dedicated Project Manager
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path d="M5.87123 12.166L0 6.39878L1.72423 4.71393L5.87119 8.79544L14.8658 0L16.59 1.68588L5.87123 12.166Z" fill="#007335" />
                </svg>
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                  <line x1="1.06066" y1="1.94965" x2="13.0815" y2="13.9705" stroke="#F0635B" stroke-width="3" />
                  <line y1="-1.5" x2="17" y2="-1.5" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0.489624 13.0208)" stroke="#F0635B" stroke-width="3" />
                </svg>
              </div>

            </div>
            <div className="grid grid-cols-4 p-5 border-b border-b-gray-300 border-opacity-50">
              <div className="col-span-2 font-semibold sm:px-[5%] text-xs sm:text-[19px]">
                Fractional Team on Demand
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13" fill="none">
                  <path d="M5.87123 12.166L0 6.39878L1.72423 4.71393L5.87119 8.79544L14.8658 0L16.59 1.68588L5.87123 12.166Z" fill="#007335" />
                </svg>
              </div>
              <div className="font-bold text-[19px] flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                  <line x1="1.06066" y1="1.94965" x2="13.0815" y2="13.9705" stroke="#F0635B" stroke-width="3" />
                  <line y1="-1.5" x2="17" y2="-1.5" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 0.489624 13.0208)" stroke="#F0635B" stroke-width="3" />
                </svg>
              </div>

            </div>
          </div>

        </div>
      </section>

      <section className="w-full bg-[#D8EFFF] flex flex-col items-center justify-center md:px-10 md:pt-20">
        <div className="flex flex-col  text-center gap-5 mb-20 px-7 sm:px-10 pt-20 md:px-0 md:pt-0">
          <div className="font-bold leading-none sm:leading-normal text-[50px]">Book a Call with Us Today!</div>
          <div className="font-bold text-[20px] max-w-[600px] mx-auto">Ready to see how Salesmrkt can transform your business?</div>
          <div className="text-[18px] max-w-[600px] mx-auto">Book a call with one of our sales experts today and build a customized sales strategy that aligns with your business goals.</div>
        </div>

        <div className="bg-white w-full max-w-[1000px] rounded-2xl flex flex-row md:p-10 md:pr-[20%] relative overflow-clip">
          <div className="border flex-col md:flex-row  flex rounded-2xl h-fit">
            <div className="flex  border-r md:w-[350px]  mr-2 flex-col items-center justify-start">
              <div className="flex w-fit items-center justify-center  mt-6 gap-3">
                <img src={logo} alt="Logo" loading="lazy" />
              </div>
              <div className="flex flex-col w-fit items-start p-6">
                <h5 className="text-start mb-3">
                  Revolutionize Your #SalesExperience
                </h5>
                <h3 className="text-xl max-w-[350px] text-black  font-bold">
                  All-in-One Marketplace Sales, Marketing & Tech
                </h3>
                <div className="flex gap-4 items-center mt-10 justify-center">
                  <AiOutlineClockCircle className="text-2xl" />
                  <h5>30 min</h5>
                </div>
                <div className="flex gap-4 mt-3  items-center justify-center">
                  <BsCameraVideo className="w-[20px]  h-[24px] " />
                  <div>
                    <h5>Web conferencing details</h5>
                    <h5>provided upon confirmation.</h5>
                  </div>
                </div>
                <div>
                  <h4 className="text-black font-normal  text-start mt-16">
                    Schedule an intro call to learn more about our complete
                    suite of Sales, Business Development, Marketing, and Smart
                    Tech solutions for
                    <b> B2B Companies</b>.
                  </h4>
                </div>
              </div>
            </div>
            <a
              href={calendlyLink}
              target="_blank"
              className="pl-1 pr-1 "
              rel="noopener noreferrer"
            >
              <div className="w-[400px]" id="calendly-widget">
                <InlineWidget
                  url={calendlyLink}
                  pageSettings={{
                    backgroundColor: "fff",
                    hideEventTypeDetails: true,
                    hideLandingPageDetails: true,
                    hideGdprBanner: true,
                    primaryColor: "70C242",
                    textColor: "#4d5055",
                  }}
                />
              </div>
            </a>
            <img src={require('Assets/AdsLandingPage/pria.png')} alt="Live Call Window" className="block md:hidden  relative w-full left-0 bottom-0 h-auto  object-contain" loading="lazy" />
          </div>


          <img src={require('Assets/AdsLandingPage/pria.png')} alt="Live Call Window" className="hidden md:block absolute bottom-0 -right-80 h-[600px]" loading="lazy" />
        </div>
      </section>

      <section className="flex flex-col py-20 px-[5%] bg-[#1E1E1E] ">
        <div className="flex flex-col md:flex-row gap-10 text-center md:text-left text-white items-center mb-16">
          <div className="font-bold text-3xl sm:text-[45px]">Inspired by Brands <br /> Changing the World</div>
          <div className="text-[18px]">
            Join the ranks of innovative companies growing their revenue with Salesmrkt.
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-5 ">

          <div className="flex flex-col sm:flex-row h-auto sm:h-[400px] md:h-[350px] max-w-[850px] w-full bg-white rounded-xl gap-10 overflow-hidden">
            <img src={require('Assets/AdsLandingPage/dom.png')} alt="Domenick Cucinotta" className="w-full sm:w-auto h-full object-cover sm:max-w-[40%]" loading="lazy" />
            <div className="flex flex-col py-5 sm:py-[5%] px-5 sm:pl-0 sm:pr-[5%]">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="29" viewBox="0 0 44 29" fill="none">
                <path d="M15.3999 28.9834H0.767902L13.1579 0.899418H22.5979L15.3999 28.9834ZM36.6399 28.9834H22.0079L34.3979 0.899418H43.8379L36.6399 28.9834Z" fill="url(#paint0_linear_708_143)" />
                <defs>
                  <linearGradient id="paint0_linear_708_143" x1="-2.1821" y1="103.404" x2="72.2482" y2="85.3467" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#70C242" />
                    <stop offset="1" stop-color="#B0D238" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="font-semibold text-[25px] mt-10">
                Secured my Biggest Client
                Nasdaq - through Salesmrkt
              </div>
              <div className="font-semibold text-[15px] mt-10 sm:mt-auto">
                Domenick Cucinotta
              </div>
              <div className="font-semibold text-[10px] mt-2 sm:mt-auto">
                Owner & Producer at Beneath The Surface Studios
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row h-auto sm:h-[400px] md:h-[350px] max-w-[850px] w-full bg-white rounded-xl gap-10 overflow-hidden">
            <img src={require('Assets/AdsLandingPage/kris.png')} alt="Domenick Cucinotta" className="w-full sm:w-auto h-full object-cover sm:max-w-[40%]" loading="lazy" />
            <div className="flex flex-col py-5 sm:py-[5%] px-5 sm:pl-0 sm:pr-[5%]">
              <svg xmlns="http://www.w3.org/2000/svg" width="44" height="29" viewBox="0 0 44 29" fill="none">
                <path d="M15.3999 28.9834H0.767902L13.1579 0.899418H22.5979L15.3999 28.9834ZM36.6399 28.9834H22.0079L34.3979 0.899418H43.8379L36.6399 28.9834Z" fill="url(#paint0_linear_708_143)" />
                <defs>
                  <linearGradient id="paint0_linear_708_143" x1="-2.1821" y1="103.404" x2="72.2482" y2="85.3467" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#70C242" />
                    <stop offset="1" stop-color="#B0D238" />
                  </linearGradient>
                </defs>
              </svg>
              <div className="font-semibold text-[25px] mt-10">
                25% Leads Conversion Rate 65+ Leads in 30 Days
              </div>
              <div className="font-semibold text-[15px] mt-10 sm:mt-auto">
                Kris Mendoza
              </div>
              <div className="font-semibold text-[10px] mt-2 sm:mt-auto">
                CEO & Founder at Maestro Filmworks
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="bg-[#1A1A1A] px-[5%] pt-10 md:py-0">
        <div className="flex flex-col md:flex-row gap-8 justify-center items-center text-white">
          <div className="flex flex-col gap-7">
            <div className="text-[18px] text-lime-500 uppercase tracking-wider text-center md:text-normal">
              Meet a revenue growth expert today. <br />
              <strong className="text-lime-400">Schedule a call now.</strong>
            </div>
            <div className="text-[28px] font-semibold text-center md:text-normal">
              Salesmrkt: Your One-Stop-Shop for OnDemand B2B Solutions
            </div>
          </div>
          <img src={require('Assets/AdsLandingPage/lobby-man.png')} alt="Expert Lobby Call" className="hidden md:block h-full w-auto -mt-7" loading="lazy" />
          <div className="flex flex-col gap-7">
            <div className="text-[18px] uppercase tracking-wider text-center md:text-normal">
              Let’s chat and find the best solution for your business.
            </div>
            <div className="flex justify-center md:justify-normal">
              {renderScheduleCallBtn()}
            </div>
            <img src={require('Assets/AdsLandingPage/lobby-man.png')} alt="Expert Lobby Call" className="md:hidden block h-full w-auto" loading="lazy" />

          </div>
        </div>
      </section>
    </div>
  );
};

export default AdsLandingPage;
