import React, { useState, useEffect, useRef } from "react";

const TextAnimation: React.FC = () => {
  const phrases = [
    "Grow your business.",
    "Build your brand.",
    "Leverage technology.",
  ];
  const [isVisible, setIsVisible] = useState(true);
  const currentIndexRef = useRef(0);

  useEffect(() => {
    const updateText = () => {
      setIsVisible(false);
      setTimeout(() => {
        currentIndexRef.current =
          (currentIndexRef.current + 1) % phrases.length;
        setIsVisible(true);
      }, 500); // Wait for fade-out animation to complete before updating text
    };

    const interval = setInterval(() => {
      updateText();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [phrases]);

  return (
    <div
      className={`text-xl mt-4 flex w-max text-lime-500 textshadowBigger md:text-2xl lg:text-3xl xl:text-[40px] font-semibold transition-opacity duration-500 ease-in ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      {phrases[currentIndexRef.current]}
    </div>
  );
};

export default TextAnimation;
