import React, { useState, useEffect, useRef, SyntheticEvent } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import emailjs from '@emailjs/browser';

interface ContactUsProps {
	isOpen: boolean;
	setOpen: React.Dispatch<React.SetStateAction<any>>;
}
const ContactUs: React.FC<ContactUsProps> = ({ isOpen, setOpen }) => {
	const [isSuccess, setSuccess] = useState(false);
	const [isFailure, setFailure] = useState(false);
	const [messageCount, setMessageCount] = useState(0);
	const [isSending, setIsSending] = useState(false);
	const [isWaiting, setIsWaiting] = useState(false);
	const [InvalidEmail, setInvalidEmail] = useState(false);
	const emailPattern =
		/^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
	const form = useRef<HTMLFormElement>(null);

	const openForm = () => {
		setOpen(true);
	};
	const closeFormIfClickedOutside = (e: MouseEvent) => {
		if (form.current && !form.current.contains(e.target as Node)) {
			setOpen(false);
		}
	};
	useEffect(() => {
		if (isOpen) {
			document.addEventListener('click', closeFormIfClickedOutside);
			return () => {
				document.removeEventListener(
					'click',
					closeFormIfClickedOutside
				);
			};
		}
	}, [isOpen]);
	const sendEmail = (e: SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (messageCount >= 2 || isSending) {
			// If the user has already sent 2 messages or is currently sending, return without sending a new message.
			return;
		}

		if (form.current) {
			const formData = new FormData(form.current);

			const selectedOptions = Array.from(formData.keys())
				.filter((key) => formData.get(key) === 'on')
				.map((key) => key);

			const emailData = {
				from_name: formData.get('from_name'),
				form_email: formData.get('form_email'),
				form_tel: formData.get('from_tel'),
				form_comp: formData.get('from_comp'),
				from_website: formData.get('from_website'),
				selectedOptions: selectedOptions.join(', '),
			};

			const email = emailData.form_email;
			const emailToSTR = email ? email.toString() : '';

			if (!emailPattern.test(emailToSTR)) {
				setInvalidEmail(true);
				setTimeout(() => {
					setInvalidEmail(false);
				}, 1800);
				return;
			}

			setIsSending(true);

			// Try the first service
			emailjs
				.send(
					'service_9sbma8h',
					'template_w2vs322',
					emailData,
					'YX6Ix4FNwXw3hRdaD'
				)
				.then(
					(result) => {
						setSuccess(true);
						setMessageCount((prevCount) => prevCount + 1);
						setTimeout(() => {
							setSuccess(false);
							setIsSending(false);
							setOpen(false);
						}, 1500);
					},
					(error) => {
						// If the first service fails, try the second one
						emailjs
							.send(
								'service_eqeh5lk',
								'template_w2vs322',
								emailData,
								'YX6Ix4FNwXw3hRdaD'
							)
							.then(
								(result) => {
									setSuccess(true);
									setMessageCount(
										(prevCount) => prevCount + 1
									);
									setTimeout(() => {
										setSuccess(false);
										setIsSending(false);
										setOpen(false);
									}, 1500);
								},
								(error) => {
									console.log(error.text);
									setFailure(true);
									setIsSending(false);
									setTimeout(() => {
										setFailure(false);
									}, 1500);
								}
							);
					}
				);
		}
	};

	useEffect(() => {
		if (messageCount >= 2) {
			setIsWaiting(true);
			const timer = setTimeout(() => {
				setIsWaiting(false);
				setMessageCount(0);
			}, 9000);
			return () => clearTimeout(timer);
		}
	}, [messageCount]);
	return (
		<div className='fixed  hidden text-black lg:flex bottom-[80px] z-[2700] right-[20px] '>
			{!isOpen && (
				<button
					onClick={(e) => {
						e.stopPropagation(); // Stop the event from propagating to the document click listener
						openForm();
					}}
					className='bg-lime-500 flex items-center text-base  justify-center gap-2 opacity-80 hover:opacity-100 ease-in transition-all p-2 rounded-2xl '>
					{/* <video
						src={AmyVideo}
						loop
						autoPlay
						muted
						playsInline
						disablePictureInPicture
						preload={'auto'}
						className='w-[80px] shadow-xl outline outline-[2px] outline-[#333]/20 rounded-[13px]'></video>
					<div className='w-[44px] rounded-t-2xl h-2 bottom-0 right-[15px] absolute bg-gradient-to-t from-lime-500/80 to-lime-500/80'></div> */}
					{/* <svg
						xmlns='http://www.w3.org/2000/svg'
						width='32'
						height='32'
						fill=''
						className='bi bi-chat-right fill-white '
						viewBox='0 0 16 16'>
						{' '}
						<path d='M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z' />{' '}
					</svg> */}
					<h4>Let's Talk</h4>
					{/* <GrContact /> */}
					{/* <svg
						xmlns='http://www.w3.org/2000/svg'
						width='32'
						height='32'
						fill=''
						className='bi bi-chat-right fill-white'
						viewBox='0 0 16 16'>
						{' '}
						<path d='M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z' />{' '}
					</svg> */}
				</button>
			)}
			{isOpen && (
				<div className=' h-full w-full '>
					<div className=' bg-white  flex flex-col justify-start items-start w-[450px]  overflow-y-scroll overflow-x-hidden    p-3  shadow-sm rounded-l-lg shadow-black min-h-[500px]   h-[calc(100vh-81px)] right-[0px] fixed bottom-[0px] z-30'>
						<form
							className='h-full flex max-h-[750px]  flex-col items-center justify-between '
							ref={form}
							onSubmit={sendEmail}>
							<div className='w-full relative  pb-3   flex items-center justify-center'>
								<AiOutlineCloseCircle
									onClick={() => setOpen(!isOpen)}
									className='text-3xl absolute hover:scale-105 hover:opacity-90 transition-all duration-150 ease-in cursor-pointer hover:text-black text-gray-800/80  right-[10px]'
								/>

								<h3 className='text-black font-normal mt-1  '>
									Let's Connect
								</h3>
								{/* <img
								src={CheckImg}
								alt=''
								className='w-8  rounded-full m-3'
							/> */}
							</div>

							<input
								type='text'
								name='from_name'
								placeholder='Name'
								id='nameId'
								className='outline outline-black/20 rounded-md outline-1 w-full p-1'
							/>
							<input
								type='email'
								name='form_email'
								id='emailId'
								placeholder='Email'
								required
								className={`outline  outline-black/20 rounded-md outline-1 w-full p-1`}
							/>

							<input
								type='number'
								name='from_tel'
								placeholder='Phone'
								inputMode='numeric'
								min='1'
								id='telID'
								className={`outline w-full outline-black/20 rounded-md outline-1  p-1`}
							/>

							<input
								type='text'
								name='from_comp'
								placeholder='Company'
								id='companyID'
								className={`outline  outline-black/20 rounded-md outline-1 w-full p-1`}
							/>
							<input
								type='text'
								name='from_website'
								placeholder='Website'
								id='websiteID'
								className={`outline outline-black/20 rounded-md outline-1 w-full p-1`}
							/>

							<div className='outline  rounded-md outline-1 outline-black/20  pl-4 pr-4 pt-2 pb-2 w-full'>
								<h4 className='mb-2'>
									What services are you researching ?
								</h4>
								<div className='   w-full  grid grid-cols-2 gap-3'>
									<label
										htmlFor='#Remember66'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Sales & Strategy'
											id='#Remember66'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Sales & Strategy
										</h4>
									</label>
									<label
										htmlFor='#Remember1'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Business Development'
											id='#Remember1'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Business Development
										</h4>
									</label>
									<label
										htmlFor='#Remember2'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Copy & Content'
											id='#Remember2'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Copy & Content
										</h4>
									</label>
									<label
										htmlFor='#Remember3'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Brand & Creative'
											id='#Remember3'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Brand & Creative
										</h4>
									</label>
									<label
										htmlFor='#Remember4'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Video Production'
											id='#Remember4'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Video Production
										</h4>
									</label>
									<label
										htmlFor='#Remember5'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Digital Marketing'
											id='#Remember5'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Digital Marketing
										</h4>
									</label>
									<label
										htmlFor='#Remember6'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Web & Tech'
											id='#Remember6'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Web & Tech
										</h4>
									</label>
									<label
										htmlFor='#Remember7'
										className='flex items-center cursor-pointer  gap-2 select-none'>
										<input
											className='w-5 h-5 cursor-pointer'
											type='checkbox'
											name='Build My Team'
											id='#Remember7'
										/>
										<h4 className='text-sm font-normal hover:text-lime-500 transition-all duration-75 ease-in'>
											Build My Team
										</h4>
									</label>
								</div>
							</div>
							<div className='flex flex-col gap-2 w-full'>
								<button
									type='submit'
									className={`${
										isSuccess
											? 'rounded  p-2 bg-green-500  py-2 px-4  w-full text-center font-semibold border-green-500 border overflow-hidden group relative hover:bg-gradient-to-r hover:from-green-500 hover:to-green-500 text-white hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300 '
											: isFailure
											? 'rounded  p-2 bg-red-500   py-2 px-4  w-full text-center font-semibold border-red-500 border overflow-hidden group relative hover:bg-gradient-to-r hover:from-red-500 hover:to-red-500 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300 '
											: ' rounded  p-2   py-2 px-4  w-full text-center font-semibold border-lime-500 border overflow-hidden group relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300 '
									} `}
									disabled={isSending || isWaiting}>
									{InvalidEmail
										? 'Wrong email format'
										: isSuccess
										? 'Message Sent'
										: isFailure
										? 'Failed to Send ...'
										: isWaiting
										? 'Too Many Requests, Try Again Later'
										: 'Send Info'}
								</button>

								<a
									href='https://calendly.com/salesbox-meet/salesmrkt?month=2023-08'
									target='_blank'
									rel='noopener noreferrer'
									className={`${'p-2  rounded w-full text-center  font-semibold border-lime-500 border   overflow-hidden group  relative hover:bg-gradient-to-r hover:from-lime-500 hover:to-lime-400 text-black hover:ring-2 hover:ring-offset-2 hover:ring-lime-400 transition-all ease-out duration-300'} `}>
									<span className='relative '>
										Schedule Meeting
									</span>
								</a>
							</div>
						</form>
					</div>
				</div>
			)}
		</div>
	);
};

export default ContactUs;
